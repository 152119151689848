import React, { useState, useCallback, useEffect, useRef } from 'react';
import Switch from 'react-switch';
import { ProfilePageEmailWrapper } from './styled';
import { useUpdateProfile } from '../../../hooks';

const Email = ({ profile, refetch, isMobile }) => {
  const [subscribed, setSubscribed] = useState(true);
  const { updateUserProfile } = useUpdateProfile();
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);


  const handleChange = useCallback(async () => {
    await updateUserProfile({ subscription: !subscribed });
    setSubscribed(!subscribed);
    refetch();
  }, [subscribed, setSubscribed, updateUserProfile, refetch]);

  useEffect(() => {
    const { subscription } = profile || {};

    setSubscribed(subscription);
  }, [profile]);

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3 profile-page__h3_hidden_mobile">
        Email Preferences
      </h3>
      <ProfilePageEmailWrapper>
        <div className="profile-page__email__text">
          Receive daily new deal recipes updates?
        </div>
        <div className="profile-page__email__switch-wrapper">
          <Switch checked={subscribed} onChange={handleChange} />
        </div>
      </ProfilePageEmailWrapper>
    </div>
  );
};

export default Email;
