import styled from 'styled-components';
import {
  color1,
  color11,
  color18,
  color58,
  color59,
} from '../../../shareComponent/styled';

export const LoginFormContainer = styled.div`
  .login-form__from {
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }
  .login-form__label {
    display: inline-block;
    line-height: 14px;
    font-size: 14px;
    color: ${color11};
    width: 100%;
    padding-top: 23px;
    position: relative;
  }
  .login-form__label_top {
    padding-top: 0px;
  }

  .login-form__label_bottom {
    margin-top: 20px;
    padding-top: 0px;
  }

  .login-form__input {
    display: inline-block;
    background: ${color1};
    border: 1px solid ${color58};
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    padding: 15px 16px 17px 16px;
    margin-top: 12px;
    ::placeholder {
      opacity: 0.4;
      font-size: 15px;
    }
  }

  .login-form__button .login-form__input_error {
    border-color: ${color18};
  }

  .login-form__button-wrapper {
    padding-top: 46px;
    padding-bottom: 31px;
    width: 100%;
  }

  .login-form__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 96.5%;
    padding: 18px 0 20px 0;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: #7fdcba;
    color: ${color1};
    display: inline-block;
    width: 250px;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    :active,
    :focus {
      outline: none;
    }
  }

  .login-form__forgot {
    margin-bottom: 13px;
    width: 100%;
  }

  .login-form__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${color59};
    outline: none;
    text-decoration: none;
    :visited {
      color: ${color59};
      outline: none;
    }
  }

  .login-form__server-error {
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: ${color18};
    margin-bottom: -27px;
    padding-top: 20px;
    z-index: 1050;
  }

  .login-form__input__text-error {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    color: ${color18};
    position: absolute;
    top: -2px;
    right: 0;
  }

  .login-form__input__text-error_bottom {
    top: initial;
    position: relative;
  }

  .login-form__message-box {
    line-height: 1.4;
  }

  @media only screen and (max-width: 1024px) {
    .login-form__create {
      width: 100%;
    }

    .login-form__button-wrapper,
    .login-form__forgot,
    .login-form__create {
      text-align: center;
    }
  }
`;
