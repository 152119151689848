import React from 'react';
import { useDeals } from '../../hooks';
import Deals from '../../components/deals';
import { Loader } from '../../components/loader';

export const DealsList = ({
  store,
  sort,
  perPage,
  isSmall,
  page,
  isOnlyOne,
  changeFilterObject,
}) => {
  const [{ deals, loading }, count] = useDeals({
    limit: page * perPage,
    store,
    sort,
    is_first_time_use_only: isOnlyOne,
  });

  const addLimit = () => {
    if (count >= page * perPage) {
      changeFilterObject('page', Number(page) + 1);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Deals
      deals={deals}
      addLimit={addLimit}
      perPage={perPage}
      count={count}
      isSmall={isSmall}
    />
  );
};
