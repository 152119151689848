import styled from 'styled-components';
import {
  color1,
  color56,
  color58,
  color63,
} from '../../../../../shareComponent/styled';

export const PaymentCardTypes = styled.div`
  display: flex;
  flex: 1;
  width: 350px;
  flex-wrap: wrap;
  .payment-card-types__title {
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    line-height: 1;
  }

  @media screen and (max-width: 320px) {
    width: 320px;
  }
`;

export const PaymentCardTypesItem = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background: ${color1};
  border: 1px solid ${color58};
  box-sizing: border-box;
  border-radius: 5px;
  text-decoration: none;
  outline: none;
  color: ${color63};
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  max-width: 320px;

  :hover {
    background-color: ${({ active }) => (active ? color1 : color56)};
  }

  :visited {
    color: ${color63};
  }

  align-items: center;
  .payment-card-types__item__icon {
    display: block;
    object-fit: contain;
    object-position: center;
    width: 39px;
    height: 26px;
    margin-left: 12px;
  }

  .payment-card-types__item__text {
    font-weight: 500;
    font-size: 15.5122px;
    line-height: 19px;
    padding: 0 20px;
  }

  .payment-card-types__item__delete-button {
    position: absolute;
    top: 12px;
    right: -40px;
  }

  @media screen and (max-width: 320px) {
    max-width: 282px;
  }

  @media screen and (max-width: 640px) {
    .payment-card-types__item__delete-button {
      right: 18px;
    }
  }
`;

export const PaymentCardTypesSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 350px;

  .payment-card-types-select__items-wrapper {
    width: calc(100% + 30px);
    height: 256px;
    overflow: auto;
    margin-bottom: 20px;
  }

  .payment-card-types__item__search {
    display: flex;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: inherit;
    background-color: transparent;
    color: inherit;
  }

  .payment-card-types-select__status-icon {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: 20px;
    top: 18px;
  }

  @media screen and (max-width: 320px) {
    width: 320px;

    .payment-card-types-select__status-icon {
      right: 6px;
    }
  }
`;
