import styled, { css } from 'styled-components/macro';

import { color1, color11, color34, color35 } from '../colors';

export const Menu = styled.div`
  width: ${({ main }) => (main ? '1088px' : '1088px')};
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  margin-right: 50px;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 18px);
    flex-wrap: wrap;
    display: ${({ show }) => (show ? 'block' : 'none')};
    overflow-x: hidden;
    position: fixed;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1056;
    background-color: ${color1};
    margin-left: 0;
    padding-bottom: 20px;
  }
`;

export const MenuItem = styled.span`
  display: inline-flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${({ active }) => (active ? color34 : color35)};
  cursor: pointer;
  align-items: center;
  height: 100%;
  border-bottom: 4px solid ${({ active }) => (active ? color34 : 'transparent')};

  :last-child {
    margin-right: 0;
  }

  .close__button {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    display: block;
    margin: 10px;
    position: relative;

    :last-child {
      margin-right: 10px;
    }

    .close__button {
      position: absolute;
      right: 10px;
      top: -5px;
      display: inline-block;
      :after {
        content: '+';
        transform: rotateZ(45deg);
        display: block;
        width: 30px;
        height: 30px;
        line-height: 1;
        font-size: 36px;
        color: ${color11};
      }
    }
  }
`;

export const NavMenu = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${color35};
  margin-top: ${({ recipe }) => (recipe ? '15px' : '15px')};

  @media screen and (max-width: 1024px) {
    display: none;
    width: 100%;
  }
`;

const navMenuItemStyle = css`
  display: inline-block;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${({ active }) => (active ? '#677283' : color35)};
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: #677283;
  }
`;

export const NavMenuItem = styled.a`
  ${navMenuItemStyle}
  :visited {
    ${navMenuItemStyle}
  }
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 1024px) {
    position: relative;
    right: auto;
    text-align: center;
  }
`;

export const MenuButtonContainer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
  }
`;

export const MenuButtonWrapper = styled.div`
  width: 54px;
  height: 42px;
  position: relative;
  padding: 16px 19px;
  margin-right: -20px;
  background-color: ${({ active }) =>
    active ? 'rgba(138, 149, 165, 0.3)' : 'transparent'};

  :focus {
    background-color: rgba(138, 149, 165, 0.3);
  }
`;

export const MenuButton = styled.div`
  position: relative;
  width: 17px;
  height: 11px;
  border-top: 1px solid ${color11};
  border-bottom: 1px solid ${color11};
  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    width: 17px;
    height: 1px;
    background-color: ${color11};
  }
`;
