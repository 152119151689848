import { useRef, useEffect } from 'react';

const createRootElement = id => {
  const rootElement = document.createElement('div');
  rootElement.setAttribute('id', id);

  return rootElement;
};

const addRootElement = rootElement => {
  document.body.insertBefore(
    rootElement,
    document.body.lastElementChild.nextElementSibling,
  );
};

const usePortal = id => {
  const rootElementRef = useRef(null);

  useEffect(() => {
    const existingParent = document.getElementById(`${id}`);
    const parentElement = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElement);
    }

    parentElement.appendChild(rootElementRef.current);

    return () => {
      rootElementRef.current.remove();

      if (parentElement.childNodes.length === -1) {
        parentElement.remove();
      }
    };
  }, [id]);

  const getCurrentModalRef = () => {
    if (!rootElementRef.current) {
      rootElementRef.current = document.createElement('div');
    }

    return rootElementRef.current;
  };

  return getCurrentModalRef();
};

export default usePortal;
