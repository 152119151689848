export const defaultSteps = [
  {
    label: 'Click the Get Started button',
    content: 'To start saving from stores you already use and trust.',
  },
  {
    label: 'Choose a store',
    content: 'We update our deal recipes daily!',
  },
  {
    label: 'Save Multiple Times',
    content:
      'Our Recipe stacks discounts on top of each other to save you more.',
  },
];

export const cardgizmoSteps = [
  {
    label: 'Click the Get Started button',
    content: 'This will take you to the chrome extension store',
  },
  {
    label: 'Install the FREE Shop Gizmo Card Gizmo extension',
    content: 'And pin it to the top of your browser',
  },
  {
    label: 'Surf and buy online as normal',
    content:
      'at checkout the extension will tell you which credit card to use!',
  },
];

export const extensionSteps = [
  {
    label: 'Download the Extension',
    content: `It will alert you when you're shopping on a store with an active deal recipe`,
  },
  {
    label: 'Click the notification icon',
    content:
      'Walk through the on-screen directions, showing you exactly what to do',
  },
  {
    label: 'Save Multiple Times',
    content:
      'Our recipe stacks discounts on top of each other to save you more',
  },
];

export const defaultTestimonialSlides = [
  {
    text: `Better than honey. More savings, free, and nothing to install`,
    sign: `Our CEO`,
  },
  {
    text: `Layering savings is so much fun, and the deals are better`,
    sign: `Our CEO's Mom`,
  },
  {
    text: `I share Shop Gizmo with all my friends and it's the only way we shop now`,
    sign: `Our CEO's buddy`,
  },
];

export const cardgizmoTestimonialSlides = [
  {
    text: `It's like picking up free money at checkout`,
    sign: `Our CEO's Mom`,
  },
  {
    text: `Better than honey. More savings, free`,
    sign: `Our CEO`,
  },
  {
    text: `I share Shop Gizmo with all my friends and it's the only way we shop now`,
    sign: `Our CEO's buddy`,
  },
];
