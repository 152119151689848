import React from 'react';

import { Footer as FooterContainer } from './styled';
import EmailForm from '../recipe/emailForm';
import {
  FacebookMobileMenuIcon,
  InstagramMobileMenuIcon,
} from '../../components/icons';

const Footer = ({ handleSend, page }) => {
  return (
    <FooterContainer>
      <div className="footer__container">
        <div className="footer__side footer__side_left">
          <EmailForm
            handleSend={handleSend}
            placeholder="Enter your email address"
            submitLabel="SUBSCRIBE NOW"
            subscribe
            source={page === 'cardgizmo' ? 'cardgizmo_footer' : 'footer'}
            name={
              page === 'cardgizmo'
                ? 'cadgizmo-subscribe-footer'
                : 'subscribe-footer'
            }
          />
          <div className="footer__side__text">
            <div className="footer__subscribe-form__info">
              Please leave your e-mail address to start following all your
              favorite brand sales with Shop GIZMO!
            </div>
            <div className="footer__copyright">
              Copyright 2020 Shop GIZMO{' '}
              <div className="footer__icon-link__wrapper">
                <a
                  rel="noopener noreferrer"
                  className="footer__icon-link"
                  target="_blank"
                  href="https://facebook.com/shopgizmo">
                  <FacebookMobileMenuIcon />
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://instagram.com/shopgizmo"
                  target="_blank"
                  className="footer__icon-link">
                  <InstagramMobileMenuIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__side footer__side_right">
          <div className="footer__link-wrapper">
            <a href="/deals" className="footer__link">
              Live Deals
            </a>
            <a href="/about" className="footer__link">
              About Us
            </a>
            <a href="/faq" className="footer__link">
              FAQ
            </a>
            {/*  */}
            <a href="mailto:hello@shopgizmo.co" className="footer__link">
              Contact Us
            </a>
            <a
              href="https://community.shopgizmo.co/"
              target="blank"
              className="footer__link">
              Blog
            </a>
            <a
              href="https://community.shopgizmo.co/forums/forum/all-things-saving-money/"
              target="blank"
              className="footer__link">
              Forum
            </a>
          </div>
          <div className="footer__link-wrapper footer__link-wrapper_bottom">
            <a
              target="blank"
              href="https://chrome.google.com/webstore/detail/shopgizmo/nbhkallcoijgigepceocecebdgekjjfi"
              className="footer__link footer__link_bottom">
              Shopgizmo Browser Extension
            </a>
            <a href="/privacy" className="footer__link footer__link_bottom">
              Privacy Policy
            </a>
            <a href="/terms" className="footer__link footer__link_bottom">
              Terms of use
            </a>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
