import styled from 'styled-components';

import { color1, color12 } from '../colors';

const sticky = `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  opacity: 1;
  height: 90px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  height: 100%;
  position: relative;

  .header__container__content {
    width: 1248px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  .mobile-menu__wrapper {
    position: absolute;
    left: 0;
    right: 0px;
    width: 100%;
    box-sizing: border-box;
    top: 66px;
  }

  @media screen and (max-width: 1280px) {
    .header__container__content {
      width: 100%;
      padding-left: 120px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 20px;
    flex-wrap: wrap;
    position: initial;
  }
`;

export const Header = styled.header`
  display: ${({ main }) => (main ? 'flex' : 'flex')};
  opacity: ${({ main }) => (main ? 0 : 1)};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
  background-color: ${color1};
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.07);

  ${({ isSticky }) => (isSticky ? sticky : '')}
  z-index: ${({ activeMobileMenu }) => (activeMobileMenu ? '1054' : '1052')};
  transition: opacity, height 300ms ease-in;
  .header__profile-link {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  ${HeaderContainer} {
    height: ${({ small, main }) => (main ? '0' : small ? '60px' : '98px')};
  }

  @media screen and (max-width: 1024px) {
    background-color: ${({ main }) => (main ? color12 : color1)};
    box-shadow: none;
    position: relative;
    opacity: 1;
    ${HeaderContainer} {
      height: 90px;
    }
  }
`;
