import styled from 'styled-components/macro';

import {
  color1,
  color4,
  color5,
  color6,
  color11,
  color13,
  color24,
  color26,
  color28,
  color29,
  color30,
  color32,
  color33,
  color34,
  color35,
  color37,
  color39,
  color44,
  color46,
  color48,
  color53,
  color54,
  color55,
  color56,
} from '../../styled';
import { SharePanelContainer } from '../sharePanel/styled';
import { ShareButton } from '../shareButton/styled';
import {
  EmailWrapper,
  EmailContainer,
  EmailButton,
  EmailInput,
} from '../emailForm/styled';

export const H1 = styled.div`
  padding: 0;
  margin-top: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 65px;
  color: ${color6};
  width: 100%;
`;

export const StepTitle = styled.div`
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: ${color34};
`;

const showCongratulationsStyle = `
  & + div.browse-other-deals__wrapper {
    display: none;
  }
  padding-bottom: 54px;
`;

export const RecipeWrapper = styled.div`
  padding-top: ${({ nologo }) => (nologo ? '46px' : '5px')};
  position: relative;
  background-color: transparent;
  width: 1088px;
  box-sizing: border-box;
  font-family: Montserrat;
  ${({ showCongratulations }) =>
    showCongratulations ? showCongratulationsStyle : ''}

  * {
    box-sizing: border-box;
  }

  figure {
    margin: auto;
    max-width: 100%;
  }

  figure img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 0px;
  }
`;

export const RecipeLogo = styled.img`
  display: block;
  max-width: 240px;
  max-height: 60px;
  object-fit: cover;
  object-position: center;
  margin: auto;
  height: auto;
  width: auto;
  min-height: 36px;
  @media screen and (max-width: 1024px) {
    display: none;
    height: 0;
  }
`;

export const MainSide = styled.div`
  display: flex;
  width: 818px;
  flex-wrap: wrap;
  justify-content: left;
  overflow: hidden;

  .main-side__container {
    padding: 38px 19px 0px 42px;
  }

  .main-side__steps {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 0px;
  }
`;

export const StepsSide = styled.div`
  width: 170px;
`;

export const SavingsSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  /* border-left: 13px dashed ${color1}; */
  background-color: ${color48};
  /* background-image: url(/img/total_savings_bg.png); */
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 13px;
    bottom: 0;
    left: -10px;
    width: 20px;
    background-image: url(/img/recipe_left_side_border.svg);
    background-repeat: no-repeat;
  }
`;

export const MainSideContent = styled.div`
  padding: 21px 32px 0px 0px;
  padding-top: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
  p {
    margin: 0;
    padding: 0;
    line-height: inherit;
  }
`;

export const MainSideContentWrapper = styled.div`
  overflow: hidden;
  width: 566px;
  background: ${color1};
  box-sizing: border-box;
  border-radius: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${color13};
  position: relative;

  .main-side__content-wrapper__title {
    line-height: 44px;
    ${StepTitle} {
      display: inline-block;
      line-height: inherit;
      text-transform: uppercase;
    }
  }

  .main-side__content-wrapper__content {
    width: 100%;
    height: 373px;
    margin-top: -5px;
    position: relative;
  }

  .scrollbar-container {
    width: 100%;
    height: 100%;
    .ps__thumb-y {
      background-color: ${color39};
    }
  }

  .scroll-down__arrow-wrapper {
    position: absolute;
    bottom: 19px;
    right: 9px;
    width: 31px;
    height: 31px;
    background: #ebeff5;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    .scroll-down__arrow-wrapper {
      bottom: 14px;
      right: 6px;
    }
  }
`;

export const StepsSideContent = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  max-height: 273px;

  /* :before {
    content: '';
    position: absolute;
    top: 0;
    left: 2px;
    width: 1px;
    height: 100%;
    background: ${color4};
    border-radius: 5px;
  } */
`;

export const StepsSideHeader = styled.div`
  padding-left: 19px;
`;

export const MainSideHeader = styled.div`
  padding-bottom: 15.75px;
  border-bottom: 1px dashed ${color39};
`;

export const Step = styled.div`
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${({ active }) => (active ? '#1F2133' : '#1f2133')};
  padding: 15px 0;
  padding-left: 43px;
  display: flex;
  cursor: pointer;
  .step__number {
    display: inline-block;
    width: 2em;
    margin-right: 1em;
  }
  .step__text {
    display: inline-block;
  }

  /* :before {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 3px;
    height: 100%;
    background: ${color4};
    border-radius: 5px;
    display: ${({ active }) => (active ? 'block' : 'none')};
  } */
`;

export const ExclusionsLink = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${color5};
  margin-top: 27px;
  :first-child {
    margin-top: 27px;
  }
`;

export const ExclusionsContent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: ${color28};
  width: 404px;
  transition: height, opacity linear 100ms;
  height: ${({ active }) => (active ? 'auto' : '0px')};
  opacity: ${({ active }) => (active ? '1' : '0')};
`;

export const Hr = styled.p`
  padding: 0;
  margin: 27px auto auto 15px;
  height: 1px;
  background-color: ${color26};
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 16px 25px 15px 25px;
  display: inline-block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  box-sizing: border-box;
  text-align: center;
  border-radius: 30px;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const BackButton = styled(Button)`
  display: flex;
  width: 180px;
  height: 46px;
  border: 1px solid ${color55};
  color: ${color54};
  background-color: ${color1};
  opacity: ${({ inactive }) => (inactive ? '0.5' : '1')};
  border-radius: 20.5px;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 22px;
  :last-child {
    margin-right: 0;
  }
`;

export const NextButton = styled(BackButton)`
  background: ${color53};
  color: ${color1};
`;

export const ButtonWrapper = styled.div`
  padding-top: 34px;
  padding-right: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TotalSaving = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 55px;
  /* identical to box height, or 109% */
  letter-spacing: -0.01em;
  color: ${color11};
  padding-top: 24px;
  width: calc(100% - 8px);
  justify-content: center;
  align-content: flex-start;

  .total-savings__content {
    width: 100%;
    height: 195px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-savings__label {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: ${color1};
    text-transform: uppercase;
    text-align: center;
    margin-top: 6px;
  }

  .total-savings__additional-wrapper {
    border-top: 1px dashed ${color1};
    border-bottom: 1px dashed ${color1};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 0 28.75px 0 35.5px;
    min-height: 251px;
  }

  .total-savings__additional {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    color: ${color1};
    text-align: center;
    :first-child {
      padding-top: 19px;
    }
    .total-savings__additional__label {
      padding-top: 5px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      width: 100%;
      text-align: center;
    }
  }
  .total-savings__additional:first-of-type {
    font-weight: 600;
  }
`;

export const TotalSavingPercent = styled.div`
  width: 100%;
  color: ${color1};
  font-weight: 800;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  position: relative;
  top: -2px;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 26px;
`;

export const FooterSide = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 50%;
  position: relative;
`;

export const FooterRight = styled(FooterSide)`
  padding-left: 54px;
`;

export const ShareSwitch = styled.span`
  display: inline-block;
  margin-left: 26px;
  min-width: 38px;
  svg {
    fill: ${({ active }) => (active ? color29 : color30)};
  }
  :hover svg {
    fill: ${color29};
  }
`;

export const SharePanelWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Steps = styled.div`
  transform: translateY(${({ state = 0 }) => state}px);
  transition: transform 400ms ease-in;
`;

export const FreeShipping = styled.div`
  padding-top: 20px;

  .free-shipping__title {
    font-size: 14px;
    line-height: 22px;
    position: relative;
    padding-left: 8px;

    :before {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      left: 0px;
      top: 9px;
      background-color: ${color28};
    }
  }

  .free-shipping__text {
    padding-left: 8px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const SendButton = styled(Button)`
  display: flex;
  width: 179px;
  height: 46px;
  border: 3px solid ${color1};
  color: ${color1};
  background-color: transparent;
  border-radius: 30px;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-top: 7px;
`;

export const RecipeContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  background: ${color1};
  box-shadow: 0px 20px 40px rgba(110, 116, 121, 0.2);
  border-radius: 13px;
  width: 1088px;
  height: 643px;
  padding: 0;
  font-family: Montserrat;
  margin-top: 20px;
  overflow: hidden;
  ${H1} {
    font-weight: 300;
    font-size: 30px;
    line-height: 37px;
    color: ${color44};
  }

  @media screen and (max-width: 1198px) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
    padding: 0;
    margin: 0 auto;
    border-radius: 0px;
    box-shadow: none;

    ${H1} {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: center;
    }

    ${StepTitle} {
      text-align: center;
      width: 100%;
    }

    ${MainSide} {
      width: 100%;
      padding: 0 16px;
      padding-top: 15px;
      box-shadow: 7px 4px 40px rgba(0, 0, 0, 0.07);
      background-color: ${color1};
      .main-side__container {
        width: 100%;
        padding: 0;
      }
      .main-side__steps {
        padding: 12px 0;
      }
    }

    ${MainSideHeader} {
      padding-left: 0;
      margin: auto;
      padding-bottom: 15px;
    }

    ${MainSideContentWrapper} {
      height: auto;
      width: 100%;

      .main-side__content-wrapper__title {
        line-height: 22px;
        padding-bottom: 10px;
      }
      .scrollbar-container {
        height: 348px;
      }
    }

    ${MainSideContent} {
      height: auto;
      padding: 21px 14px 0 0;
    }

    ${EmailWrapper} {
      width: 100%;
    }

    ${EmailContainer} {
      width: 100%;
      height: 40px;
    }

    ${EmailButton} {
      flex: 0 0 120px;
    }

    ${ButtonWrapper} {
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 0px;
      max-width: 420px;
      margin: auto;
    }

    ${BackButton}, ${NextButton} {
      width: 47%;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding: 8px;
      transition: background-color 300ms ease-in;
    }

    ${ShareButton} {
      margin: 0;
    }

    ${Footer} {
      padding: 0 16px;
      padding-bottom: 20px;
      background-color: ${color1};
    }

    ${SavingsSide} {
      border: 0;
      /* border-top: 13px dashed #ffffff; */
      background-color: ${color48};
      background-image: none;
      flex-wrap: wrap;
      padding-bottom: 20px;
      :after {
        top: -16px;
        height: 32px;
        left: 0;
        width: 100%;
        z-index: 10;
        background-position: center;
        right: 0;
        background-image: url(/img/mobile_total_saving_top_border.svg);
      }
    }

    ${StepsSide} {
      width: 100%;
    }

    ${TotalSaving} {
      width: 100%;
      padding-top: 0;

      .total-savings__label {
        width: auto;
        padding-right: 10px;
        font-size: 22px;
        line-height: 22px;
      }

      ${TotalSavingPercent} {
        /* font-size: 36px;
        line-height: 38px; */
        margin-top: -8px;
        width: auto;
      }
      .total-savings__additional-wrapper {
        min-height: 96px;
        align-items: center;
        padding: 20px 0;
        margin: 0 10px 0 10px;
      }
      .total-savings__additional {
        padding-top: 0;
        margin-right: 12px;
        svg {
          min-height: 42px;
        }
        :last-child {
          margin-right: 0;
        }
      }

      .total-savings__additional.total-savings__additional_first {
        width: 113px;
        order: 1;
      }

      .total-savings__additional.total-savings__additional_second {
        width: 78px;
        order: 2;
      }

      .total-savings__additional.total-savings__additional_last {
        width: 118px;
        order: 3;
      }

      .total-savings__additional__label {
        font-size: 14px;
      }

      .total-savings__content {
        height: 96px;
        padding-top: 18px;
      }
    }

    ${SharePanelWrapper} {
      padding-top: 18px;
      margin-left: 0;
      width: 205px;
    }

    ${SendButton} {
      margin-left: auto;
      margin-right: auto;
      width: 205px;
      height: 53px;
    }

    ${ExclusionsContent} {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      color: ${color32};
    }

    ${ExclusionsLink} {
      color: ${color33};
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }

    ${SharePanelContainer} {
      justify-content: space-between;
      svg {
        width: 55px;
        height: 55px;
      }
    }
  }
`;

export const MobileStepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

export const MobileStep = styled.span`
  display: inline-block;
  width: 25px;
  height: 5px;
  background-color: ${({ active }) => (active ? color4 : color24)};
  border-radius: 5px;
  margin: 0 5px;
`;

export const EndScreenContainer = styled.div`
  width: 100%;

  ${EmailWrapper}, ${EmailContainer} {
    width: 533px;
    border-radius: 30px;
  }

  ${EmailWrapper} {
    height: auto;
  }

  ${EmailInput} {
    border-radius: 30px 0 0 30px;
    border-right: none;
    padding-left: 41px;
  }

  ${EmailButton} {
    /* border-radius: 30px;
    margin-left: -23px; */
    flex: 0 0 137px;
  }

  ${SharePanelContainer} {
    height: auto;
    justify-content: flex-end;
  }

  ${ShareButton} {
    margin: 0 4px;
    :first {
      margin-left: 0;
    }

    svg {
      width: 45px;
      height: 45px;
    }
  }

  .end-screen__h1 {
    font-weight: 800;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    text-transform: uppercase;
    color: ${color44};
    width: 100%;
    margin-top: 17px;
    margin-bottom: 32px;
  }

  .end-screen__image-wrapper {
    width: 402px;
    margin: 0 auto;
    padding-top: 33px;
  }

  .end-screen__image {
    display: block;
    width: 100%;
    height: auto;
  }

  .end-screen__buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    ${BackButton}, ${NextButton} {
      height: 50px;
      border-radius: 30px;
      border: 1.19444px solid ${color56};
      color: ${color46};
      font-size: 16px;
    }

    ${BackButton} {
      width: 137px;
      margin-right: 33px;
    }

    ${NextButton} {
      width: 250px;
      background-color: ${color37};
      color: ${color1};
      border-color: ${color37};
      margin-right: 0;
    }
  }

  .end-screen__bottom-wrapper {
    width: calc(100% - 94px);
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 0 6px;
    border-top: 1px dashed ${color39};
  }

  .end-screen__text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: ${color35};
    text-align: left;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 100%;
  }

  .end-screen__email-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 34px;
    width: 100%;
    max-width: 701px;
  }

  .end-screen__email-form {
    display: flex;
    flex: 1;
  }

  .end-screen__shared-wrapper {
    display: flex;
    width: 168px;
    justify-content: flex-start;
  }

  .end-screen__back-wrapper {
    width: 100%;
    padding-top: 49px;
    text-align: center;
  }

  .end-screen__back {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #cacdd0;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    ${EmailWrapper}, ${EmailContainer} {
      height: 50px;
    }

    padding-bottom: 40px;
  }

  @media screen and (max-width: 840px) {
    .end-screen__text {
      text-align: center;
    }

    .end-screen__h1 {
      font-size: 40px;
    }

    .end-screen__shared-wrapper {
      padding-top: 20px;
      width: 160px;
    }
  }

  @media screen and (max-width: 640px) {
    .end-screen__buttons-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 340px;

      ${BackButton}, ${NextButton} {
        height: 42px;
        border-radius: 25px;
        border: 1.19444px solid ${color56};
        color: ${color46};
        font-size: 14px;
        line-height: 17px;
      }

      ${BackButton} {
        width: 115px;
        margin-right: 14px;
      }

      ${NextButton} {
        width: 210px;
        background-color: ${color37};
        color: ${color1};
        border-color: ${color37};
        margin-right: 0;
      }
    }

    .end-screen__image-wrapper {
      width: 100%;
    }

    .end-screen__h1 {
      font-size: 29.5735px;
      line-height: 32px;
      margin-top: 37px;
    }

    .end-screen__bottom-wrapper {
      width: 340px;
      padding-left: 0;
    }

    .end-screen__email-form {
      max-width: 340px;
      flex: auto;
    }

    ${EmailWrapper}, ${EmailContainer} {
      width: 100%;
      height: 41px;
    }

    ${EmailButton} {
      max-width: 112px;
    }

    ${EmailInput} {
      padding-left: 18px;
    }
  }
`;

export const TileFirstScreenContainer = styled.div`
  width: 100%;
  padding: 65px 61.5px 22px 61.5px;
  max-height: 100%;
  position: relative;
  .tile-first-screen-container__close,
  .tile-first-screen-container__close:visited {
    line-height: 1.3;
    position: absolute;
    right: 5px;
    top: -7px;
    font-size: 33px;
    text-decoration: none !important;
    color: ${color6};
    z-index: 1;
    transform: rotateZ(45deg);
  }

  .tile-first-screen-container__content-wrapper {
    height: calc(100% - 95px);
    overflow: auto;
    p {
      margin: 0;
    }
    .text-tiny {
      font-size: 0.7em;
    }
    .text-small {
      font-size: 0.85em;
    }
    .text-big {
      font-size: 1.4em;
    }
    .text-huge {
      font-size: 1.8em;
    }
    .image {
      margin-top: 14px;
      margin-bottom: 14px;
      clear: both;
    }
    .image-style-side,
    .image-style-align-right {
      float: right;
      margin-left: 0;
    }
    .image-style-align-left {
      float: left;
      margin-right: 0;
    }
  }

  .tile-first-screen-container__buttons-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 27px;
  }

  @media screen and (max-width: 1180px) {
    max-height: auto;
    padding: 40px 20px 20px 20px;
    .tile-first-screen-container__content-wrapper {
      height: initial;
    }

    .tile-first-screen-container__buttons-wrapper {
      ${NextButton} {
        width: 210px;
      }
    }
  }
`;
