import React from 'react';
import { useLink } from '../../hooks';
import RecipePageContainer from '../../shareComponent/recipePage';
import Screen404 from '../../components/404';

const Page404 = () => {
  const linkTo = useLink();

  return (
    <RecipePageContainer linkTo={linkTo} nobutton>
      <Screen404 />
    </RecipePageContainer>
  );
};

export default Page404;
