import React, { useState } from 'react';
import { useLogin } from '../../hooks';
import { LoginFormContainer } from './styled';

const LoginForm = ({ name }) => {
  const { isLogged, loggingTo } = useLogin();
  const [authData, setAuthData] = useState({ email: '', password: '' });

  const handleSubmit = e => {
    e.preventDefault();

    loggingTo(authData.email, authData.password);
  };

  const handleChange = ({ target: { value, name } }) => {
    setAuthData({ ...authData, [name]: value });
  };

  return (
    <LoginFormContainer>
      <form
        name={name || 'login-form'}
        onSubmit={handleSubmit}
        className="login-form__from">
        <label
          htmlFor="login"
          className="login-form__label login-form__label_top">
          Login
          <input
            name="email"
            type="text"
            className="login-form__input"
            value={authData.email}
            onChange={handleChange}
          />
        </label>
        <label htmlFor="login" className="login-form__label">
          Password
          <input
            name="password"
            type="password"
            value={authData.password}
            className="login-form__input"
            onChange={handleChange}
          />
        </label>
        {isLogged && isLogged.error && (
          <div className="login-form__server-error">{isLogged.error}</div>
        )}
        <div className="login-form__button-wrapper">
          <button className="login-form__button" type="submit">
            LOG IN
          </button>
        </div>
        <div className="login-form__forgot">
          <a href="/forgot-password" className="login-form__link">
            Forgot Password
          </a>
        </div>
        <div className="login-form__create">
          <a href="/user/create" className="login-form__link">
            Don't have account? Create one now.
          </a>
        </div>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
