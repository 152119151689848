export const color1 = '#ffffff';
export const color2 = '#f6f6f6';
export const color3 = '#B4B3B3';
export const color4 = '#FF6772';
export const color5 = '#1F2133';
export const color6 = '#696969';
export const color7 = '#FF9785';
export const color8 = '#A7A7A7';
export const color9 = '#98a1af';
export const color10 = '#f5f5f5';
export const color11 = '#000000';
export const color12 = '#E7FFFF';
export const color13 = '#828282';
export const color14 = '#EFF8F9';
export const color15 = '#F4F4F4';
export const color16 = '#8A8A8A';
export const color17 = '#bababa';
export const color18 = '#ED424F';
export const color19 = '#f8fbff';
export const color20 = '#8A95A5';
export const color21 = '#9DA9BB';
export const color22 = '#FEFEFE';
export const color23 = '#F7F7F7';
export const color24 = '#BEC1C5';
export const color25 = '#FF6F56';
export const color26 = '#e7e7e7';
export const color27 = '#dde0e5';
export const color28 = '#767785';
export const color29 = '#9da8b9';
export const color30 = '#E0E4E6';
export const color31 = '#677283';
export const color32 = '#898686';
export const color33 = '#2a6ed3';
export const color34 = '#EF7176';
export const color35 = '#828282';
export const color36 = '#386692';
export const color37 = '#D2D2D2';
export const color38 = '#F18287';
export const color39 = '#ffb9b3';
export const color40 = '#434343';
export const color41 = '#2B2B2B';
export const color42 = '#646464';
export const color43 = '#1F2133';
export const color44 = '#4f4f4f';
export const color45 = '#F9676E';
export const color46 = '#ababab';
export const color47 = '#e0b4b4';
export const color48 = '#7FDCBA';
export const color49 = '#fafafa';
export const color50 = '#8b8b8b';
export const color51 = '#EC7D82';
export const color52 = '#cccccc';
export const color53 = '#F86A71';
export const color54 = '#687486';
export const color55 = '#DDE0E6';
export const color56 = '#dedede';
export const color57 = '#FEDC90';
export const color58 = '#d3d7da';
export const color59 = '#3a6791';
export const color60 = '#fbfbfb';
export const color61 = '#F8F8F8';
export const color62 = '#9ea6b2';
export const color63 = '#424242';
export const color64 = '#949494';
export const color65 = '#F9F9F9';
