import React from 'react';

import { useMediaQuery } from '../../shareComponent/hooks';
import { RecipeWrapper } from '../../shareComponent/recipe/styled';
import { Screen404Wrapper } from './styled';
import { ExpiredButton } from '../styled';

const Screen404 = () => {
  const isSmall = useMediaQuery('small');

  return (
    <RecipeWrapper nologo>
      <Screen404Wrapper>
        <div className="screen-404__container">
          {isSmall ? (
            <>
              <img
                src="/img/404_mobile_bg.svg"
                alt=""
                className="screen-404__bg-image"
              />
              <img
                src="/img/404_ballon_big.svg"
                alt=""
                className="screen-404__bg-image-big-ballon"
              />
              <img
                src="/img/404_ballon_small.svg"
                alt=""
                className="screen-404__bg-image-small-ballon"
              />
            </>
          ) : (
            <img src="/img/404.svg" alt="" className="screen-404__bg-image" />
          )}
          <div className="screen-404__content">
            <div className="screen-404__content-title">Ooops... </div>
            <div className="screen-404__content-title_big">404</div>
            <div>Something went wrong</div>
          </div>
          <div className="screen-404__button-wrapper">
            <ExpiredButton
              onClick={() => {
                window.location.pathname = '/deals';
              }}
              error>
              Return to Deals
            </ExpiredButton>
          </div>
        </div>
      </Screen404Wrapper>
    </RecipeWrapper>
  );
};

export default Screen404;
