import { useState } from 'react';

export const useExpand = sections => {
  const [state, setState] = useState(sections || {});

  const setExpand = section => {
    setState({
      ...state,
      [section]: !state[section],
    });
  };

  return [state, setExpand];
};
