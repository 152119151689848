import styled from 'styled-components';

import { color1, color34, color42, color43 } from '../../styled';

import {
  EmailWrapper,
  EmailContainer,
  EmailInput,
  EmailButton,
} from '../../recipe/emailForm/styled';

export const Footer = styled.footer`
  background-color: ${color43};
  color: ${color1};

  a {
    text-decoration: none !important;
  }

  * {
    box-sizing: border-box;
  }
  .footer__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 296px;
    padding-top: 75px;
    max-width: 1180px;
    margin: auto;
  }
  .footer__side {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    position: relative;
  }
  .footer__side_left {
    width: 45%;
    justify-content: flex-end;
  }
  .footer__side_right {
    width: 55%;
  }

  ${EmailWrapper} {
    width: 463px;
    padding-right: 76px;
    border-right: 2px solid ${color42};
    height: auto;
  }

  ${EmailContainer} {
    width: 385px;
    border-radius: 8px;
    overflow: hidden;
    border: none;
  }

  ${EmailInput} {
    align-items: center;
    justify-content: start;
    padding: 0;
    padding-left: 16px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    border: none;
  }

  ${EmailButton} {
    flex: 0 0 125px;
    background-color: ${color34};
    padding: 0;
    padding-top: 0px;
    height: 100%;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: ${color1};
    border: none;
  }

  .footer__copyright {
    position: relative;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 36px;
    display: flex;
    justify-content: flex-start;
    max-width: 385px;
  }

  .footer__side__text {
    width: 463px;
    margin-top: -40px;
  }

  .footer__subscribe-form__info {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    max-width: 385px;
  }

  .footer__link-wrapper {
    display: flex;
    padding-left: 30px;
    height: 50px;
    align-items: center;
  }

  .footer__link-wrapper_bottom {
    position: absolute;
    bottom: 48px;
    text-align: right;
    justify-content: flex-end;
    min-width: 533px;
  }

  .footer__link {
    display: inline-block;
    text-decoration: none;
    color: ${color1};
    margin: 0 10px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    :hover {
      color: ${color1};
    }
    :visited {
      color: ${color1};
    }
    :active {
      outline: none;
    }
  }

  .footer__link.footer__link_bottom {
    line-height: 14px;
    font-size: 12px;
    font-weight: 400;
  }

  .footer__icon-link__wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: -8px;
  }

  .footer__icon-link {
    margin-right: 6px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${color1};
    border-radius: 50%;
    :last-child {
      margin-right: 0;
      border: none;
    }
  }

  @media screen and (min-width: 1440px) {
    .footer__link {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 1280px) {
    .footer__side__text {
      width: 100%;
    }

    .footer__link {
      font-size: 15px;
      margin: 0 8px;
    }

    .footer__link-wrapper_bottom {
      position: absolute;
      bottom: 48px;
      text-align: right;
      justify-content: flex-end;
      min-width: 485px;
    }

    ${EmailWrapper} {
      width: calc(100% - 20px);
    }

    ${EmailContainer} {
      width: 100%;
    }

    .footer__subscribe-form__info,
    .footer__copyright {
      width: 420px;
      margin: auto;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    .footer__side_right {
      display: none;
    }
    .footer__side_left {
      width: 100%;
      justify-content: center;
    }

    .footer__side__text {
      width: 100%;
    }

    .footer__link-wrapper_bottom {
      min-width: inherit;
    }

    ${EmailWrapper} {
      width: calc(100% - 20px);
      min-width: 320px;
      max-width: 480px;
      padding-right: 0;
      margin: 0 auto;
      border-right: 0;
    }

    ${EmailContainer} {
      width: 100%;
    }

    .footer__subscribe-form__info,
    .footer__copyright {
      text-align: center;
      justify-content: center;
      max-width: 420px;
      margin: auto;
      width: auto;
    }

    .footer__icon-link__wrapper {
      display: none;
    }
  }
`;
