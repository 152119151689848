import styled from 'styled-components';
import {
  color1,
  color5,
  color11,
  color57,
} from '../../../shareComponent/styled';
import {
  EmailContainer,
  EmailWrapper,
  EmailButton,
  EmailInput,
} from '../../../shareComponent/recipe/emailForm/styled';

export const TimeoutEmailPopupWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px; /* calc((100vw - 1088px) / 2 - 50px); */
  display: flex;
  flex-wrap: wrap;
  z-index: 1060;

  .timeout-email-popup__container {
    width: 300px;
    height: 200px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    background-color: ${color57};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6.54428px;
  }

  .timeout-email-popup__close-button {
    color: ${color5};
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    display: inline-block;
    width: 18px
    height: 18px;
  }

  .timeout-email-popup__title {
    font-style: normal;
    font-weight: normal;
    font-size: 23.5594px;
    line-height: 29px;
    color: ${color11};
    text-align: center;
    width: 100%;
  }

  .timeout-email-popup__title_small {
    padding-top: 4px;
    line-height: 13.74px;
    font-size: 10.47px;
  }

  .timeout-email-popup__email-form-wrapper {
    width: 251px;
    height: 32px;
    margin: auto;
    margin-top: 20px;
    ${EmailContainer}, ${EmailWrapper} {
      width: 100%;
      height: 100%;
    }
    ${EmailButton} {
      flex: 0 0 65px;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      color: ${color1};
      justify-content: center;
    }
    ${EmailInput} {
      font-weight: normal;
      font-size: 12px;
      line-height: 10px;
      border: none;
    }
  }
  @media screen and (max-width: 1024px) {
    right: 0;
    left: 0;
    margin: auto;
    justify-content: center;
    bottom: 40px;
  }
`;
