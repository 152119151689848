import styled from 'styled-components';

import { color37 } from '../../../shareComponent/styled';

export const DealsView = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  padding-left: 60px;
  border-left: 2px solid ${color37};
  padding-bottom: 50px;
  @media screen and (max-width: 1024px) {
    padding-left: 0;
    border-left: none;
    padding-bottom: 20px;
  }
`;

export const BrowseDealsButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;
