import styled from 'styled-components';
import { color19 } from '../../../../../shareComponent/styled';

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: ${color19};
  }
  :after {
    /*TODO remove  in future fix video border on quicktime video */
    display: block;
    width: 100%;
    position: absolute;
    content: '';
    ${({ type }) =>
      type === 'cardgizmo'
        ? ` 
        bottom: 2px;
        height: 12px;
        background-color: #F8FBFF;
      `
        : ` 
        bottom: 2px;
        height: 4px;
        background-color: #f9faff;
      `}
  }

  @media screen and (max-width: 1399px) {
    :after {
      bottom: ${({ type }) => (type === 'cardgizmo' ? `1px` : `0px;`)};
    }
  }
`;
