import styled from 'styled-components/macro';

const transitionFromState = state => {
  switch (state) {
    case 'entering':
      return { opacity: 1, translate: '0px' };
    case 'entered':
      return { opacity: 1, translate: '0px' };
    case 'exiting':
      return { opacity: 1, translate: '-330px' };
    case 'exited':
      return { opacity: 0, translate: '-330px' };
    default:
      return { opacity: 0, translate: '-330px' };
  }
};

export const SharePanelContainer = styled.div`
  opacity: ${({ state }) => transitionFromState(state).opacity};
  transform: translateX(${({ state }) => transitionFromState(state).translate});
  transition: opacity, transform 200ms ease-in;
  display: flex;
  overflow: hidden;
  height: 75px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
