import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #545454;
    opacity: 0.9;
    z-index: 1099;
  }
  .modal__container {
    z-index: 1100;
    position: relative;
    min-width: 463px;
    min-height: 306px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 50px;
  }
  .modal__close-button {
    font-style: normal;
    font-size: 57.4px;
    line-height: 1;
    color: #ffffff;
    position: absolute;
    right: -40px;
    top: -40px;
    transform: rotateZ(45deg);
    display: inline-block;
    width: 35px;
    height: 44px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    z-index: 1300;
    align-items: flex-start;
    .modal__container {
      margin-top: 50px;
      margin-bottom: 0;
      min-width: auto;
      width: calc(100% - 10px);
    }
    .modal__close-button {
      color: #cccccc;
      right: -6px;
      top: -10px;
    }
  }
`;
