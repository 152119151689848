import styled from 'styled-components';

export const VideoPanel = styled.div`
  display: flex;
  width: 100%;
  padding-top: 145px;
  min-height: 453px;
  padding-bottom: 172px;
  max-width: 1440px;
  margin: auto;

  @media screen and (max-width: 1024px) {
    display: none;
    width: 0;
    overflow: hidden;
  }
`;

export const VideoPanelLeft = styled.div`
  width: 630px;
`;

export const VideoPanelRight = styled.div`
  flex: 1;
  padding-left: 200px;
`;