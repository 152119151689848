import styled from 'styled-components';
import {
  color1,
  color2,
  color8,
  color17,
  color20,
  color21,
  color35,
  color38,
} from '../../../shareComponent/styled';
import { CheckboxContainer, CheckboxWrapper } from '../../checkbox/styled';

export const FiltersLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${color35};
  padding-bottom: 30px;
`;

const activeStroke = `
  stroke: ${color38};
`;

export const FilterItem = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${({ smaller }) => (smaller ? '10px' : '11px')};
  line-height: 13px;
  letter-spacing: -0.01em;
  color: ${({ active }) => (active ? color38 : color8)};
  padding-bottom: 16px;

  @media screen and (max-width: 1024px) {
    font-weight: ${({ active }) => (active ? '600' : '500')};
    svg > path {
      ${({ active }) => (active ? activeStroke : '')}
    }
  }

  .filter-item__text {
    display: inline-block;
    padding-left: 5px;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  .horizontal-divider {
    width: 120px;
    height: 2px;
    border-radius: 2px;
    background-color: ${color17};
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1024px) {
    background-color: ${color20};
    box-sizing: border-box;
    padding: 6px 16px;

    .horizontal-divider {
      width: 100%;
      margin: 0;
      margin-top: -2px;
      z-index: 1;
      background-color: ${color2};
    }

    ${FilterItem} {
      width: 100%;
      display: flex;
      padding: 12px 21px;
      color: ${color1};
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      min-height: 50px;
      letter-spacing: -0.01em;
      cursor: pointer;
      border-bottom: 1px solid ${color21};
      outline: none;
      :focus {
        outline: none;
      }

      :last-child {
        border-bottom: none;
      }
    }
    ${FiltersLabel} {
      display: none;
    }
    ${CheckboxContainer} {
      border: 1px solid ${color1};
    }

    ${CheckboxWrapper} {
      width: 100%;
    }
  }
`;
