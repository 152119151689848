import React from 'react';
import { useMediaQuery } from '../../shareComponent/hooks';
import { RecipeWrapper } from '../../shareComponent/recipe/styled';
import { ExpiredButton } from '../styled';
import { ExpiredRecipeWrapper } from './styled';

const ExpiredScreen = () => {
  const isSmall = useMediaQuery('small');

  return (
    <RecipeWrapper nologo>
      <ExpiredRecipeWrapper>
        <div className="expired-recipe__content">
          <div className="expired-recipe__info-wrapper">
            <div>
              This Deal Recipe has expired, but check out the current deals here
            </div>
            <div className="expired-recipe__button-wrapper">
              <ExpiredButton
                onClick={() => {
                  window.location.pathname = '/deals';
                }}>
                Go to current deals
              </ExpiredButton>
            </div>
          </div>
          {isSmall ? (
            <img
              src="/img/expired/expired_recipe_mobile.svg"
              alt=""
              className="expired-recipe__bg-image"
            />
          ) : (
            <>
              <img
                src="/img/expired/cloud_expired_screen.svg"
                alt=""
                className="expired-recipe__bg-image"
              />
              <img
                src="/img/expired/airball_big.svg"
                alt=""
                className="expired-recipe__bg-image-ballon"
              />
              <img
                src="/img/expired/airball_small.svg"
                alt=""
                className="expired-recipe__bg-image-ballon__small"
              />
            </>
          )}
        </div>
      </ExpiredRecipeWrapper>
    </RecipeWrapper>
  );
};

export default ExpiredScreen;
