import styled from 'styled-components';

export const Screen404Wrapper = styled.div`
  width: 100%;
  height: 638px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 7px 0px 25px rgba(0, 0, 0, 0.07);

  .screen-404__container {
    width: 651px;
    height: 593px;
    position: relative;
    margin: auto;
  }
  
  .screen-404__bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
  }

  .screen-404__bg-image-big-ballon {
    position: absolute;
    left: initial;
    right: 0;
    bottom: 0px;
    margin: auto;
    display: block;
  }
  .screen-404__bg-image-small-ballon {
    position: absolute;
    top: 0;
    left: 10%;
    margin: auto;
    display: block;
  }

  .screen-404__content {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 19.0156px;
    line-height: 1;
    /* or 125% */
    letter-spacing: -0.01em;
    color: #f08ea4;
    width: 240px;
    height: 180px;
    text-align: center;
    margin: auto;
    left: 70px;
    right: 0;
    top: 116px;
  }

  .screen-404__content-title {
    font-style: normal;
    font-weight: 500;
    font-size: 43.9149px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #eb7791;
  }

  .screen-404__content-title_big {
    font-style: normal;
    font-weight: bold;
    font-size: 113.708px;
    line-height: 1;
    /* or 125% */
    letter-spacing: -0.01em;
    color: #ffd2dc;
  }
  .screen-404__button-wrapper {
    position: absolute;
    width: 200px;
    text-align: center;
    bottom: 150px;
    left: 0;
    right: 30px;
    margin: auto;
  }

  @media screen and (max-width: 1024px) {
    box-shadow: none;
    min-height: 553px;
    height: auto;

    .screen-404__container {
      height: 486px;
      width: 100%;
      margin-top: 24px;
    }

    .screen-404__content {
      font-size: 15.582px;
      left: 15px;
      right: 0;
      top: 89px;
    }

    .screen-404__content-title {
      font-size: 33px;
      line-height: 1;
    }

    .screen-404__content-title_big {
      font-size: 93px;
    }

    .screen-404__button-wrapper {
      right: initial;
      left: 10%;
      bottom: 178px;
    }
  }
`;
