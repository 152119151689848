import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { color35, color52 } from '../../../shareComponent/styled';

const normalStyle = `
  color: ${color35};
  top: 49%;
  transform: translateY(-50%);
  right: 40px;
`;

const mainStyle = `
  right: 40px;
  color: ${color52};
  margin-top: -2px;
`;

export const ProfileLinkWrapper = styled(Link)`
  flex: 0 0 15%;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  ${({ main }) => (main ? mainStyle : normalStyle)}
  font-weight: 600;
  text-decoration: none;
  :hover {
    opacity: 0.8;
  }
  :visited {
    color: ${({ small }) => (small ? color52 : color35)};
  }
`;
