import styled from 'styled-components';

import { color18, color5 } from '../../../../shareComponent/styled';
import { H1 } from '../../styled';

export const HiwMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  ${H1} {
    text-align: center;
    padding-top: 54px;
    padding-bottom: 50px;
  }

  padding-bottom: 72px;
`;

export const StepsMobile = styled.div`
  width: 300px;
  overflow: hidden;
  margin: auto;
`;

export const StepMobile = styled.div`
  width: 100%;

  .step__title {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding-left: 42px;
    position: relative;

    :after {
      content: '${({ step }) => step || '1'}';
      color: ${color18};
      border: 1px solid ${color18};
      padding: 0px 8px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: ${({ type, step }) =>
        type === 'cardgizmo' && step === 2 ? '30%' : 0};
      font-size: 14px;
      line-height: 21px;
    }
  }

  .step__text {
    width: calc(100% -11px);
    margin-left: 11px;
    min-height: 54px;
    border-left: 1px solid ${color18};
    margin-top: 11px;
    padding-left: 32px;
    margin-bottom: 9px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: ${color5};
  }

  .step__text__text {
    margin-top: -5px;
  }

  :last-child {
    .step__text {
      border-color: transparent;
    }
  }
`;
