import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
};

const Video = ({ start, end }) => {
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;

    const func = () => {
      el.currentTime = start;
      el.play();
    };

    const onTimeUpdate = () => {
      if (el.currentTime >= end) {
        el.currentTime = start;
      }
    };

    el.addEventListener('loadedmetadata', func);
    el.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      el.removeEventListener('loadedmetadata', func);
      el.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [start, end, ref]);

  return (
    <div className="faq-cardgizmo__video__wrapper">
      <video
        ref={ref}
        muted
        type="video/mp4"
        loop
        className="faq-cardgizmo__video"
        poster="/img/video-poster.png">
        <source src="/video/CardGizmo_ext_2.mp4" />
      </video>
    </div>
  );
};

Video.propTypes = propTypes;

export default Video;
