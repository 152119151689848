import React, { useState, useCallback } from 'react';
import { LoginFormContainer } from '../loginForm/styled';
import { forgotPassword } from '../../api/auth';
import { useValidation } from '../../hooks/validation';

const ForgotPasswordForm = ({ setTitle }) => {
  const { validate, validateAt, errors: validationErrors } = useValidation(
    'forgotPassword',
  );
  const [state, setState] = useState({ email: '', error: false, message: '' });

  const handleSubmit = async e => {
    e.preventDefault();

    if (await validate(state)) {
      const { email } = state;
      const res = await forgotPassword(email);

      setTitle('CHECK YOUR EMAIL');

      setState({
        ...state,
        message: !!res,
        error: !res,
      });
    }
  };

  const handleChange = e => {
    const { target } = e;

    setState({ ...state, email: target.value });
  };

  const handleBlur = useCallback(
    e => {
      const {
        target: { name },
      } = e;

      validateAt(name, state);
    },
    [validateAt, state],
  );

  const { message, error, email } = state;

  return (
    <LoginFormContainer>
      {message ? (
        <div className="login-form__message-box">
          Please go to your <b>{email}</b> email and click the password reset link
          we've sent for your ShopGizmo account.
          <br />
          It could take a few minutes to appear, and be sure to check any spam
          and promotional folders — just in case!
        </div>
      ) : (
        <form
          name="reset-password-form"
          onSubmit={handleSubmit}
          className="login-form__from">
          <label
            htmlFor="login"
            className="login-form__label login-form__label_top">
            Which ShopGizmo account are you having problems accessing?
            <input
              name="email"
              type="text"
              className={`login-form__input ${
                validationErrors.email ? 'login-form__input_error' : ''
              }`}
              placeholder="Email"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!validationErrors.email && (
              <span className="login-form__input__text-error login-form__input__text-error_bottom">
                {validationErrors.email}
              </span>
            )}
          </label>
          {error && (
            <div className="login-form__server-error">Unhandled error</div>
          )}
          <div className="login-form__button-wrapper">
            <button className="login-form__button" type="submit">
              SEND MY RESET LINK
            </button>
          </div>
        </form>
      )}
    </LoginFormContainer>
  );
};

export default ForgotPasswordForm;
