import styled from 'styled-components';
import { color1, color58 } from '../../../shareComponent/styled';

export const LoginButtonWrapper = styled.div`
  .login__button {
    border: 1px solid ${color58};
    background-color: ${color1};
    width: 284px;
    height: 50px;
    outline: none;
    padding-top: 0;
    border-radius: 5px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: #f5f5f5;
    }
  }

  .login__button-icon {
    display: inline-block;
    margin-right: 12px;
  }
`;
