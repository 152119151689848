import React, { useState, useEffect } from 'react';

import { RecipeContextWrapper } from '../hooks';
import { NavMenu, NavMenuItem, PageTitleContainer } from '../styled';

import Header from '../header';

import {
  RecipePageContainer,
  Container,
  BrowseDealsButton,
  Content,
} from './styled';

const RecipePage = props => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const [title, setTitle] = useState('STEP1');

  return (
    <RecipeContextWrapper value={{ title, setTitle }}>
      <RecipePageContainer>
        <Header hideMd activeMenu="recipe" />
        <PageTitleContainer>DEAL RECIPE</PageTitleContainer>
        <Container nobutton={props.nobutton}>
          <NavMenu recipe>
            <NavMenuItem
              href="/"
              onClick={e => {
                e.preventDefault();

                if (props.linkTo) {
                  props.linkTo('/');
                }
              }}>
              Home
            </NavMenuItem>{' '}
            /{' '}
            <NavMenuItem
              onClick={e => {
                e.preventDefault();

                if (props.linkTo) {
                  props.linkTo('/deals');
                }
              }}
              href="/deals">
              Deals
            </NavMenuItem>{' '}
            / <NavMenuItem active>Recipe</NavMenuItem>
          </NavMenu>
          <Content nobutton={props.nobutton}>
            {props.children}
            {!props.nobutton && (
              <div className="browse-other-deals__wrapper">
                <BrowseDealsButton
                  onClick={() => {
                    if (props.linkTo) {
                      props.linkTo('/deals');
                    } else if (typeof window === 'object') {
                      window.location.href = '/deals';
                    }
                  }}>
                  BROWSE MORE DEALS
                </BrowseDealsButton>
              </div>
            )}
          </Content>
        </Container>
      </RecipePageContainer>
    </RecipeContextWrapper>
  );
};

export default RecipePage;
