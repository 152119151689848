import { useState } from 'react';
import * as yup from 'yup';

import { existsUser, existsUpdateUser, checkOldPassword } from '../../api';

const matchEmail = email =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    email,
  );

const schemas = {
  profile: yup.object().shape({
    firstName: yup.string().label('First Name').min(1).required(),
    lastName: yup.string().label('Last Name').min(1).required(),
    email: yup
      .string()
      .email()
      .required()
      .test(
        'existsUser',
        'This email address has already used',
        async function testFunc(value) {
          const { path, createError } = this;

          if (!matchEmail(value)) {
            return createError({
              path,
              message: 'Email address must be a valid',
            });
          }

          return !(await existsUser(value));
        },
      ),
    sex: yup.string().oneOf(['f', 'm', null]),
    password: yup.string().min(6).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  }),
  profileUpdate: yup.object().shape({
    firstName: yup.string().label('First Name').min(1).required(),
    lastName: yup.string().label('Last Name').min(1).required(),
    email: yup
      .string()
      .email()
      .test(
        'existsUser',
        'This email address has already used',
        async function testFunc(value) {
          const { path, createError } = this;

          if (!matchEmail(value)) {
            return createError({
              path,
              message: 'Email address must be a valid',
            });
          }

          return !(await existsUpdateUser(value));
        },
      ),
    sex: yup.string().oneOf(['f', 'm', null]),
    // region: yup.string(),
  }),
  passwordChange: yup.object().shape({
    oldPassword: yup
      .string()
      .test(
        'matchPassword',
        "Your password doesn't match",
        async function testFunc(value) {
          const res = await checkOldPassword(value);

          return res;
        },
      ),
    newPassword: yup.string().label('New password').min(6).required(),
    confirmPassword: yup
      .string()
      .label('Confirm password')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required(),
  }),
  forgotPassword: yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .test('existsEmail', 'Account not found', async function testFunc(value) {
        const { path, createError } = this;

        if (!matchEmail(value)) {
          return createError({
            path,
            message: 'Email address must be a valid',
          });
        }

        return !!(await existsUser(value));
      }),
  }),
  resetPassword: yup.object().shape({
    password: yup.string().label('Password').min(6).required(),
    confirmPassword: yup
      .string()
      .label('Confirm password')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(),
  }),
};

const useValidation = (name, defValue = false) => {
  const [errors, setErrors] = useState(
    Object.keys(schemas[name].fields).reduce(
      (acc, curr) => ({ ...acc, [curr]: false }),
      {},
    ),
  );
  const [isValid, setIsValid] = useState(defValue);

  const resetError = name => {
    const filteredErrors = Object.entries(errors).reduce(
      (acc, curr) => (curr[0] !== name ? { ...acc, [curr[0]]: curr[1] } : acc),
      {},
    );

    if (Object.keys(filteredErrors).length === 0) {
      setIsValid(true);
    }

    setErrors(filteredErrors);
  };

  const validateAt = async (propName, val) => {
    try {
      await schemas[name].validateAt(propName, val, { abortEarly: true });

      resetError(propName);
    } catch (error) {
      setErrors({ ...errors, [propName]: error.message });
    }
  };

  const validate = async obj => {
    try {
      const res = await schemas[name].validate(obj, { abortEarly: false });

      if (res) {
        setIsValid(true);
        setErrors({});
        return true;
      }
    } catch (err) {
      setIsValid(false);
      setErrors(
        err.inner.reduce(
          (acc, { path, message }) => ({
            ...acc,
            [path]: message,
          }),
          {},
        ),
      );
      return false;
    }
  };

  return {
    validate,
    validateAt,
    resetError,
    errors,
    isValid,
  };
};
export { useValidation };
