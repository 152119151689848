import styled from 'styled-components';
import {
  color1,
  color58,
  color62,
  color64,
} from '../../../../shareComponent/styled';

export const ProfilePageEditForm = styled.form`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  padding-top: 26px;
  position: relative;

  .profile-page__edit-form__col {
    flex: 1 0;
  }

  .profile-page__edit-form__input {
    max-width: 350px;
  }

  .react-datepicker-wrapper {
    width: 100%;
    input {
      margin-top: 8.62px;
      display: inline-block;
      width: 100%;
      background: ${color1};
      border: 1px solid ${color58};
      box-sizing: border-box;
      border-radius: 5px;
      padding: 16px 15px;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      color: ${color62};
      ::placeholder {
        color: ${color62};
      }
    }
  }

  .profile-page__edit-form__text {
    padding-top: 29px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    color: ${color64};
    max-width: 283px;
  }

  .profile-page__edit-form__button-wrapper {
    padding-top: 22px;
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 16px;

    .profile-page__edit-form__col {
      flex: auto;
      width: 100%;
    }
    .profile-page__edit-form__text {
      padding-top: 19px;
    }
    .profile-page__edit-form__button-wrapper {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
`;
