import styled from 'styled-components';

const ballon = `
  position: absolute;
  display: block;
  z-index: 0;
`;

export const ExpiredRecipeWrapper = styled.div`
  width: 100%;
  height: 638px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 7px 0px 25px rgba(0, 0, 0, 0.07);

  .expired-recipe__content {
    width: 617px;
    height: 549px;
    margin: auto;
    position: relative;
  }

  .expired-recipe__bg-image {
    ${ballon}
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .expired-recipe__bg-image-ballon {
    ${ballon}
    bottom: 42px;
    right: -25px;
  }

  .expired-recipe__bg-image-ballon__small {
    ${ballon}
    top:-25px;
    left: 185px;
  }

  .expired-recipe__info-wrapper {
    width: 348px;
    height: 190px;
    display: block;
    margin: auto;
    font-weight: 500;
    font-size: 25.8462px;
    line-height: 32px;
    /* or 125% */
    text-align: center;
    letter-spacing: -0.01em;
    color: #82b7bc;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .expired-recipe__button-wrapper {
    margin-top: 36px;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    min-height: 553px;
    height: auto;
    box-shadow: none;
    .expired-recipe__content {
      width: 100%;
      height: 486px;
      margin-top: 24px;
    }

    .expired-recipe__info-wrapper {
      font-size: 21px;
      width: 286px;
      height: 222px;
    }

    .expired-recipe__button-wrapper {
      text-align: left;
      margin-top: 38px;
      margin-left: -12px;
    }
  }
`;
