import styled from 'styled-components/macro';

import { color1, color2, color37 } from '../../styled';

export const Container = styled.div`
  width: 1248px;
  overflow: hidden;
  overflow-y: auto;
  margin: auto;
  @media screen and (max-width: 1280px) {
    width: calc(100% - 30px);
  }
  @media screen and (max-width: 1024px) {
    ${({ nobutton }) => (nobutton ? 'min-height: calc(100vh - 90px);' : '')}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1088px;
  margin: auto;
  margin-bottom: ${({ nobutton }) => (nobutton ? '83px' : '0')};

  .browse-other-deals__wrapper {
    margin-top: 67px;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    ${({ nobutton }) => (nobutton ? 'margin-bottom: 0' : '')}
    .browse-other-deals__wrapper {
      margin-top: 27px;
      margin-bottom: 20px;
    }
  }
`;

export const BrowseDealsButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 36px;
  background: ${color37};
  border-radius: 17.5px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.01em;
  color: ${color1};
  margin: auto;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 0px 6px rgba(95, 95, 95, 0.332851);
`;

export const RecipePageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color2};
  font-family: Montserrat;
  padding-bottom: 70px;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  .recipe-page__loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    background-color: ${color1};
    padding-bottom: 0;
    min-height: auto;

    ${Container}, ${Content} {
      width: 100%;
      background-color: ${color1};
    }
    & > ${Container} {
      position: relative;
      box-shadow: 7px 0px 25px rgba(0, 0, 0, 0.07);
      z-index: 1052;
    }
  }
`;
