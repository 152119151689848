import React from 'react';
import IRCarousel from 'infinite-react-carousel';
import { ItemsCarouselWrapper, ExamplesWrapper } from './styled';
import { useMediaQuery } from '../../../hooks';

const items = [
  {
    image: '/img/example/watch.png',
    text: 'test text 1',
    link: 'http://',
    icon: '/img/example/bloomingdales.svg',
    old_price: '$870',
    sale_price: '$629',
  },
  {
    image: '/img/example/esspresso_machine.png',
    text: 'test text 2',
    link: 'http://',
    icon: '/img/example/macys.png',
    old_price: '$288',
    sale_price: '$121',
    sticker_price: '$112.50',
  },
  {
    image: '/img/example/bag_white.webp',
    link: 'http://',
    icon: '/img/example/nordstrom.svg',
    old_price: '$495',
    sale_price: '$353',
  },
];

const cardgizmoItems = [
  {
    image: '/img/example/bag_white.webp',
    link: 'http://',
    icon: '/img/example/nordstrom.svg',
    old_price: '$495',
    sale_price: '$25',
  },
  {
    image: '/img/example/watch.png',
    text: 'test text 1',
    link: 'http://',
    icon: '/img/example/bloomingdales.svg',
    old_price: '$870',
    sale_price: '$43',
    sticker_price: '$43',
  },
  {
    image: '/img/example/esspresso_machine.png',
    text: 'test text 2',
    link: 'http://',
    icon: '/img/example/macys.png',
    old_price: '$288',
    sale_price: '$15',
  },
];

const Examples = ({ cardgizmo }) => {
  const isSmall = useMediaQuery('small');
  const currentItems = cardgizmo ? cardgizmoItems : items;

  return (
    <ExamplesWrapper>
      <div className="example__header">
        {cardgizmo ? 'Save more by using the right card' : 'Real Examples'}
      </div>
      <ItemsCarouselWrapper>
        {isSmall ? (
          <IRCarousel
            centerMode
            rows={1}
            slidesToShow={1}
            centerPadding="0"
            initialSlide={1}
            dots
            arrows={false}>
            {currentItems.map((item, key) => (
              <div key={key} className="items-carousel__slide-wrapper">
                <div className="items-carousel__slide">
                  {item.sticker_price && (
                    <div className="item-carousel__slide__sticker">
                      <span>SAVE</span>
                      <div className="item-carousel__slide__sticker__price">
                        {item.sticker_price}
                      </div>
                    </div>
                  )}
                  <div className="item-carousel__slide__header">
                    <img
                      src={item.icon}
                      alt=""
                      className="item-carousel__slide__image"
                    />
                  </div>
                  <div className="item-carousel__slide__image-wrapper">
                    <img
                      src={item.image}
                      alt=""
                      className="item-carousel__slide__image"
                    />
                  </div>
                  <div className="item-carousel__slide__price-wrapper">
                    <div className="item-carousel__slide__sale-price">
                      {cardgizmo ? 'Save: ' : 'ShopGizmo Price: '}
                      <span className="item-carousel__slide__sale-price__value">
                        {item.sale_price}
                      </span>
                    </div>
                    <div className="item-carousel__slide__price">
                      Price: {item.old_price}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </IRCarousel>
        ) : (
          <div className="items-carousel-wrapper__static">
            {currentItems.map((item, key) => (
              <div key={key} className="items-carousel__slide-wrapper">
                <div
                  className={`items-carousel__slide ${
                    key === 1 ? 'items-carousel__slide_active' : ''
                  }`}>
                  {item.sticker_price && (
                    <div className="item-carousel__slide__sticker">
                      <span>SAVE</span>
                      <div className="item-carousel__slide__sticker__price">
                        {item.sticker_price}
                      </div>
                    </div>
                  )}
                  <div className="item-carousel__slide__header">
                    <img
                      src={item.icon}
                      alt=""
                      className="item-carousel__slide__image"
                    />
                  </div>
                  <div className="item-carousel__slide__image-wrapper">
                    <img
                      src={item.image}
                      alt=""
                      className="item-carousel__slide__image"
                    />
                  </div>
                  <div className="item-carousel__slide__price-wrapper">
                    <div className="item-carousel__slide__sale-price">
                      {cardgizmo ? 'Save: ' : 'ShopGizmo Price: '}
                      <span className="item-carousel__slide__sale-price__value">
                        {item.sale_price}
                      </span>
                    </div>
                    <div className="item-carousel__slide__price">
                      Price: {item.old_price}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ItemsCarouselWrapper>
    </ExamplesWrapper>
  );
};

export default Examples;
