import parse, { convertNodeToElement } from 'react-html-parser';
import bbCodeParser from '../bbcodeParser';

const transform = (node, index) => {
  if (node.type === 'tag') {
    if (node.name === 'script' || node.name === 'SCRIPT') {
      return null;
    }

    if (node.name === 'a' || node.name === 'A') {
      node.attribs.target = '_blank';

      return convertNodeToElement(node, index, transform);
    }
  }
};

export default val =>
  val
    ? parse(bbCodeParser.parse(val), {
        transform,
      })
    : '';
