import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getRecipe,
  getDeals,
  getStore,
  getProfile,
  updateUser,
  changeUserPassword,
  getTileFirstScreen,
} from '../api';
import { localLogin, register } from '../api/auth';
import { parseUrlQuery, objectToUrlQuery } from '../utils';
import { small } from './mediaQuery';
import usePortal from './portal';
import { useValidation } from './validation';

export const useRecipe = id => {
  const [recipe, setRecipe] = useState({ loading: true });

  useEffect(() => {
    if (id) {
      setRecipe({ loading: true, error: false });
      getRecipe(id).then(res => {
        setRecipe({ ...res, loading: false });
      });
    }
  }, [id]);

  return recipe;
};

export const useDeals = params => {
  const { limit, store, sort, is_first_time_use_only } = params || {};
  const [deals, setDeals] = useState({ deals: [], loading: true });
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (Object.keys(sort).length > 0 || store.length > 0) {
      getDeals({ limit, store, sort, is_first_time_use_only }).then(data => {
        if (data && Array.isArray(data.items)) {
          setDeals({
            deals: data.items.map(item => ({
              ...item,
              logo_url: item.Store && item.Store.publicUrl,
            })),
            loading: false,
          });
          setCount(data.total);
        }
      });
    }
  }, [limit, store, sort, is_first_time_use_only]);

  return [deals, count];
};

export const useStore = (dev = []) => {
  const [store, setStore] = useState([]);

  useEffect(() => {
    getStore().then(data => {
      if (data) {
        setStore(data);
      }
    });
  }, []);

  return store;
};

export const useMediaQuery = mediaQuery => {
  let md = '(max-width: 1024px)';

  if (mediaQuery === 'small') {
    md = small;
  }

  const [isVerified, setIsVerified] = useState(
    typeof window === 'object' && window.matchMedia(md).matches,
  );

  useEffect(() => {
    if (typeof window === 'object') {
      const mediaQueryList = window.matchMedia(md);

      const documentChangeHandler = () => {
        setIsVerified(!!mediaQueryList.matches);
      };

      mediaQueryList.addListener(documentChangeHandler);

      return () => {
        mediaQueryList.removeListener(documentChangeHandler);
      };
    }
  }, [md]);

  return isVerified;
};

export const useFilterObject = params => {
  let localParams = {};

  if (typeof window === 'object') {
    localParams = window.location.search
      ? parseUrlQuery(window.location.search)
      : {};
  }

  const { page, perPage, store, sort } =
    params && Object.keys(params).length <= 1
      ? { ...localParams, ...params }
      : params;
  const { push, location } = useHistory();

  const [filterObject, setFilterObject] = useState({
    page: page || 1,
    perPage: perPage || 8,
    store: store || [],
    sort: sort || { EXPIRE: 'ASC' },
  });

  const changeFilterObject = useCallback(
    (name, val) => {
      setFilterObject({
        ...filterObject,
        [name]: val,
      });

      const search = objectToUrlQuery({
        ...filterObject,
        [name]: val,
      });

      if (name !== 'perPage') {
        push({ path: location.pathname, search });
      }
    },
    [filterObject, location, push],
  );

  useEffect(() => {
    if (
      (localParams.store &&
        localParams.store.length > 0 &&
        filterObject.store.length === 0) ||
      (localParams.sort &&
        JSON.stringify(localParams.sort) !==
          JSON.stringify(filterObject.sort)) ||
      JSON.stringify(localParams.is_first_time_use_only) !==
        JSON.stringify(filterObject.is_first_time_use_only)
    ) {
      setFilterObject({ ...filterObject, ...localParams });
    }
  }, [localParams, filterObject, setFilterObject]);

  useEffect(() => {
    if (perPage !== filterObject.perPage) {
      setFilterObject({ ...filterObject, perPage });
    }
  }, [perPage, filterObject]);

  return [filterObject, changeFilterObject];
};

export const useProfile = () => {
  const [profile, setProfile] = useState({
    loading: true,
    error: null,
    profile: null,
  });

  const refetch = useCallback(() => {
    getProfile()
      .then(res => {
        setProfile({ profile: res, loading: false });
      })
      .catch(err => setProfile({ error: err, loading: false }));
  }, [setProfile]);

  useEffect(() => {
    getProfile()
      .then(res => {
        setProfile({ profile: res, loading: false });
      })
      .catch(err => setProfile({ error: err, loading: false }));
  }, []);

  return { ...profile, refetch };
};

export const useLink = () => {
  const { location, push } = useHistory();
  const { state, pathname: currentPathname } = location || {};

  const linkTo = useCallback(
    pathname => {
      if (pathname !== currentPathname) {
        push({
          pathname,
          state,
          search:
            state && state.store && objectToUrlQuery({ store: state.store }),
        });
      }
    },
    [push, currentPathname, state],
  );

  return linkTo;
};

export const useTimeoutEmailPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  return [showPopup, setShowPopup];
};

export { usePortal };

export const isChrome = () => /chrome/i.test(navigator.userAgent);

export const useLogin = () => {
  const [isLogged, setIsLogged] = useState(false);

  const loggingTo = async (email, password) => {
    try {
      const res = await localLogin(email, password);

      setIsLogged(res);
    } catch (error) {
      console.log(error);
      setIsLogged({ error });
    }
  };

  return { isLogged, loggingTo };
};

export const useRegister = () => {
  const [isRegistered, setRegister] = useState({});

  const doRegister = async user => {
    try {
      const res = await register(user);

      setRegister(res);
    } catch (error) {
      setRegister({ error });
    }
  };

  return { isRegistered, doRegister };
};

export const useUpdateProfile = () => {
  const [isUpdated, setUpdated] = useState({});

  const reset = () => {
    setUpdated({});
  };

  const updateUserProfile = async user => {
    try {
      const res = await updateUser(user);

      setUpdated({ id: res });
    } catch (error) {
      setUpdated({ error });
    }
  };

  return { isUpdated, updateUserProfile, reset };
};

export const useUpdateUserPassword = () => {
  const [isUpdated, setUpdated] = useState({});

  const reset = () => {
    setUpdated({});
  };

  const updateUserPassword = async user => {
    try {
      const res = await changeUserPassword(user);

      setUpdated({ id: res });
    } catch (error) {
      setUpdated({ error });
    }
  };

  return { isUpdated, updateUserPassword, reset };
};

export const useTileFirstScreen = () => {
  const [state, setState] = useState({});

  useEffect(() => {
    getTileFirstScreen().then(res => {
      setState(res);
    });
  }, [setState]);

  return { tileFirstScreen: state, setTileFirstScreen: setState };
};

export { useValidation };
