import styled from 'styled-components';

export const TermsWrapper = styled.div`
  .terms__content {
    max-width: 1080px;
    margin: auto;
    padding-top: 70px;
  }

  .terms__page-title {
    font-size: 32px;
    display: none;
    margin: 0;
    padding-bottom: 10px;
  }

  .terms__title {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 30px;
  }

  .terms__text {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .terms__item {
    padding-bottom: 30px;

    .terms__text:last-child {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 30px 15px 10px 15px;

    .terms__content {
      padding-top: 32px;
    }

    .terms__page-title {
      display: block;
    }
  }
`;
