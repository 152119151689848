import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useStore, useMediaQuery, useFilterObject } from '../../hooks';
import { parseUrlQuery } from '../../utils';
import { DealsList } from './dealsList';

import { DealsMobile } from './mobile';
import { DealsDesktop } from './desktop';
import Footer from '../../components/footer';

const getLabel = sort => {
  const val = Object.entries(sort);
  if (val && val.length > 0) {
    const [entry] = val;

    switch (entry[0]) {
      case 'EXPIRE':
        return 'Expiration Date';
      case 'NEWEST':
        return 'Newest';
      case 'OFF':
        return 'OFF%';
      default:
        return 'Popular';
    }
  }
};

const DealsPage = ({ inFrame }) => {
  const isSmall = useMediaQuery('small');
  const defPerPage = isSmall ? 8 : 12;

  const { location, push } = useHistory();
  const { search, state, pathname } = location || {};
  const [
    { store, sort, perPage, page, is_first_time_use_only },
    changeFilterObject,
  ] = useFilterObject({
    perPage: defPerPage,
  });

  const stores = useStore();

  const changeStore = val => {
    changeFilterObject('store', val);
  };

  const changeOneTimeUseOnly = val => {
    changeFilterObject('is_first_time_use_only', val);
  };

  useEffect(() => {
    const store = search && parseUrlQuery(search).store;
    const is_first_time_use_only =
      search && parseUrlQuery(search).is_first_time_use_only;

    if (!(state && state.store) && store) {
      push({
        search,
        pathname,
        state: { ...state, store },
      });
    }

    if (!(state && state.is_first_time_use_only) && is_first_time_use_only) {
      push({
        search,
        pathname,
        state: { ...state, is_first_time_use_only },
      });
    }
  }, [pathname, search, push, state]);

  return isSmall ? (
    <>
      <DealsMobile
        store={store}
        stores={stores}
        changeStore={changeStore}
        changeFilterObject={changeFilterObject}
        label={getLabel(sort)}
        sort={sort}
        isOnlyOne={is_first_time_use_only}
        changeOneTimeUseOnly={changeOneTimeUseOnly}
        inFrame={inFrame}>
        <DealsList
          page={page}
          sort={sort}
          perPage={perPage}
          store={store}
          isSmall={isSmall}
          isOnlyOne={is_first_time_use_only}
          changeFilterObject={changeFilterObject}
        />
      </DealsMobile>
      <Footer />
    </>
  ) : (
    <>
      <DealsDesktop
        stores={stores}
        store={store}
        changeStore={changeStore}
        sort={sort}
        isOnlyOne={is_first_time_use_only}
        changeFilterObject={changeFilterObject}
        changeOneTimeUseOnly={changeOneTimeUseOnly}>
        <DealsList
          page={page}
          sort={sort}
          perPage={perPage}
          store={store}
          isSmall={isSmall}
          isOnlyOne={is_first_time_use_only}
          changeFilterObject={changeFilterObject}
        />
      </DealsDesktop>
      <Footer />
    </>
  );
};

export default withRouter(DealsPage);
