import React from 'react';
import { Transition } from 'react-transition-group';
import {
  TopSection,
  TopSectionLeft,
  TopSectionRight,
  IllustrationMainWrapper,
  IllustrationMain,
  H1,
  TopSectionButtonPanel,
  TopSectionStarPanel,
  TopSectionStarTextPanel,
  StarWrapper,
  Recipe,
  RecipePanel,
  MainPageContainer,
  TopSectionLogoWrapper,
  Subscribe,
} from '../main/styled';
import { Button, LogoImage } from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import EmailForm from '../../shareComponent/recipe/emailForm';
import Footer from '../../components/footer';
import { StarIcon } from '../../components/icons';
import VideoSection from '../main/videoPanel';
import Trusted from '../main/trusted';
import Slider from '../main/slider';
import Examples from '../main/examples';
import End from '../main/end';
import HiwMobile from '../main/hiwMobile';
import { useMediaQuery } from '../../hooks';
import { triggerAnalyticsEvent } from '../../shareComponent/hooks';
import { sendEmail } from '../../api';

const Extension = () => {
  const isSmall = useMediaQuery('small');

  const getStarted = () => {
    if (typeof window === 'object') {
      triggerAnalyticsEvent('Extension');
      window.location.href =
        'https://chrome.google.com/webstore/detail/shopgizmo/nbhkallcoijgigepceocecebdgekjjfi';
    }
  };

  return (
    <MainPageContainer isSticky>
      <div>
        <Transition in timeout={300}>
          <Header logoLink="/extension" isSticky main getStarted={getStarted} />
        </Transition>
        <TopSection>
          <TopSectionLogoWrapper>
            <LogoImage src="/img/ShopGizmo_Logo_new.svg" />
          </TopSectionLogoWrapper>
          <TopSectionLeft>
            <div className="top-section__left-wrapper">
              <IllustrationMainWrapper>
                <IllustrationMain src="/img/illustration_main.svg" />
              </IllustrationMainWrapper>
            </div>
          </TopSectionLeft>
          <TopSectionRight>
            <div className="top-section__right-content">
              <H1>Go beyond coupons to save more at the stores you trust</H1>
              <div className="top-section__text">
                We find multiple discounts you can stack on top of each other to
                save more money
              </div>
              <TopSectionButtonPanel>
                <Button onClick={getStarted}>Get Started</Button>
              </TopSectionButtonPanel>
              <TopSectionButtonPanel>
                <TopSectionStarPanel>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                </TopSectionStarPanel>
                <TopSectionStarTextPanel>
                  From 5 of our friends and 3 of our moms
                </TopSectionStarTextPanel>
              </TopSectionButtonPanel>
            </div>
          </TopSectionRight>
        </TopSection>
        {isSmall ? (
          <HiwMobile id="hiw" />
        ) : (
          <VideoSection id="hiw" type="extension" />
        )}
        <Subscribe>
          <div className="subscribe__wrapper">
            <div className="h1">Hey visitor!</div>
            <div className="h2">Sign up and save more!</div>
            <EmailForm
              placeholder="Enter your email address."
              handleSend={sendEmail}
              subscribe
              source="main_section"
              name="subscribe-main"
            />
            <div className="subscribe__text">
              Please leave your e-mail adress to start following all your
              favorite brand sales with Shop GIZMO!
            </div>
          </div>
        </Subscribe>
        <Trusted id="retailers" />
        <RecipePanel id="recipe">
          <Recipe src="/img/recipe_new.svg" />
          <Recipe className="recipe_mobile" src="/img/RecipeMobile.svg" />
        </RecipePanel>
        <Slider />
        <Examples id="example" />
        <End handleGetStarted={getStarted} />
        <Footer />
      </div>
    </MainPageContainer>
  );
};

export default Extension;
