import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { ChevronUp, ChevronDown } from '../icons';

const arrowStyle = {
  display: 'inline-block',
  position: 'absolute',
  top: '15px',
  right: '9px',
};

const WrappedDropdown = props => (
  <Dropdown
    {...props}
    arrowOpen={<ChevronUp style={arrowStyle} />}
    arrowClosed={<ChevronDown style={arrowStyle} />}
  />
);

export default WrappedDropdown;
