import Cookies from 'universal-cookie';
import axios from './axios';

const cookies = new Cookies();

const dealsLink = () => {
  let link = `${window.location.protocol}//${window.location.hostname}`;
  link += window.location.port ? `:${window.location.port}/deals` : `/deals`;

  return link;
};

export const setClientSubscribe = () => {
  cookies.set('visited', true, { path: '/' });
};

export const getRecipe = async id => {
  try {
    const { data } = await axios.get(`/tile/${id}`);

    return data || { error: 'no such recipe' };
  } catch (error) {
    return { error };
  }
};

export const updateUser = async user => {
  try {
    return await axios.post('/profile/update', user);
  } catch (error) {
    return { error };
  }
};
export const getDeals = async opt => {
  const { offset, store, sort, limit, is_first_time_use_only } = opt || {};

  try {
    const params = {
      offset,
      store,
      limit: limit || 4,
      is_first_time_use_only,
    };

    if (sort && Object.keys(sort).length > 0) {
      const [sorts] = Object.entries(sort);
      const [key, val] = sorts;

      params.sort = key;
      params.order = val;
    }

    const { data } = await axios.get(`/list`, { params });

    return data;
  } catch (error) {
    return { error };
  }
};

export const getStore = async () => {
  try {
    const { data = [] } = await axios.get(`/store/list?has_tiles=1`);

    return data;
  } catch (error) {
    return { error };
  }
};

export const getProfile = async () => {
  const { data } = await axios.get(`/profile`);

  return data;
};

export const getCardTypes = async () => {
  const { data } = await axios.get(`/card/list`);

  return data;
};

export const sendEmail = async ({
  to,
  storeName,
  totalSavings,
  subscribe,
  source,
}) => {
  try {
    await axios.post(`/email/send`, {
      to,
      storeName,
      totalSavings,
      link: subscribe ? dealsLink() : window.location.href,
      subscribe,
      sourceLink: window.location.href,
      source,
    });

    if (subscribe) {
      setClientSubscribe();
    }

    return 'Sent';
  } catch (e) {
    return e;
  }
};

export const unsubscribe = async email => {
  try {
    await axios.get(`/email/unsubscribe/${email}`);

    return 'Sent';
  } catch (e) {
    return e;
  }
};

export const existsUser = async email => {
  try {
    const { data } = await axios.post(`/profile/exists`, { email });

    return data;
  } catch (error) {
    return { error };
  }
};

export const existsUpdateUser = async email => {
  try {
    const { data } = await axios.post(`/auth/update/exists`, { email });

    return data ? data.data : [];
  } catch (error) {
    return { error };
  }
};

export const checkOldPassword = async oldPassword => {
  try {
    const { data } = await axios.post('/profile/check/password', {
      oldPassword,
    });

    return data;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const changeUserPassword = async ({ oldPassword, newPassword }) => {
  try {
    const { data } = await axios.post('/profile/change/password', {
      oldPassword,
      newPassword,
    });

    return data;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const isSocial = async () => {
  try {
    const { data } = await axios.get('/profile/check/social');

    return data;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const getTileFirstScreen = async () => {
  try {
    const { data } = await axios.get('/config/tfs');

    return data;
  } catch (error) {
    console.log(error);

    return false;
  }
};
