import React from 'react';

import { useLink } from '../../hooks';
import {
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';
import {
  DealsContainer,
  DealsContentWrapper,
  DealsTopPanel,
  DealsTopPanelLeft,
  Sidebar,
} from './styled';
import Filters from '../../components/filters';
import SortPanel from '../../components/sortPanel';

export const DealsDesktop = ({
  stores,
  store,
  changeStore,
  changeOneTimeUseOnly,
  isOnlyOne,
  children,
  sort,
  changeFilterObject,
}) => {
  const linkTo = useLink();

  const onChange = item => {
    if (item.name === 'store') {
      changeStore(item.value);
    } else if (item.name === 'is_first_time_use_only') {
      changeOneTimeUseOnly(item.value);
    }
  };

  return (
    <RecipePageContainer>
      <Header activeMenu="deals" />
      <PageTitleContainer>LIVE DEAL RECIPES</PageTitleContainer>
      <Container>
        <NavMenu>
          <NavMenuItem
            onClick={e => {
              e.preventDefault();

              linkTo('/');
            }}
            href="/">
            Home
          </NavMenuItem>{' '}
          / <NavMenuItem active>Deals</NavMenuItem>
        </NavMenu>
        <DealsContentWrapper>
          <Sidebar>
            <Filters
              isOnlyOne={isOnlyOne}
              items={stores}
              currItems={store}
              onChange={onChange}
            />
          </Sidebar>
          <DealsContainer>
            <DealsTopPanel>
              <DealsTopPanelLeft>
                <SortPanel
                  sort={sort}
                  onChange={val => changeFilterObject('sort', val)}
                />
              </DealsTopPanelLeft>
            </DealsTopPanel>
            {children}
          </DealsContainer>
        </DealsContentWrapper>
      </Container>
    </RecipePageContainer>
  );
};
