import jsCookie from 'js-cookie';

export const setToken = token => {
  try {
    //localStorage.setItem('token', token);
    //jsCookie.set('shopgizmo_token', token, { expires: 2 });
  } catch (e) {
    // no localstorage access
  }
};

export const getToken = () => {
  try {
    //return localStorage.getItem('token');
    return jsCookie.get('shopgizmo_token');
  } catch (e) {
    // no localstorage access
  }
};

export const removeToken = () => {
  jsCookie.remove('shopgizmo_token');
};
