import React, { useState, useEffect } from 'react';
import { RecipeProvider, RecipeContext } from './context';

const small = '(max-width: 1024px)';
const desktop = '(max-width: 1440px)';

export const useMediaQuery = mediaQuery => {
  let md = desktop;

  if (mediaQuery === 'small') {
    md = small;
  }

  const [isVerified, setIsVerified] = useState(
    typeof window === 'object' && window.matchMedia(md).matches,
  );

  useEffect(() => {
    if (typeof window === 'object') {
      const mediaQueryList = window.matchMedia(md);

      const documentChangeHandler = () => {
        setIsVerified(!!mediaQueryList.matches);
      };

      mediaQueryList.addListener(documentChangeHandler);

      return () => {
        mediaQueryList.removeListener(documentChangeHandler);
      };
    }
  }, [md]);

  return isVerified;
};

export const RecipeContextWrapper = ({ children, value }) => (
  <RecipeProvider value={value}>{children}</RecipeProvider>
);

export { RecipeContext };

export const triggerAnalyticsEvent = name => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', name);
  }

  if (typeof window.dataLayer === 'object') {
    window.dataLayer.push({ event: name });
  }
};
