import styled from 'styled-components';

import { color5, color4, color3 } from '../../../../../shareComponent/styled';

export const StepPreviewPanel = styled.div`
  width: 400px;
`;

export const Steps = styled.div`
  width: 100%;
  margin-top: 72px;
`;

export const Step = styled.div`
  padding-top: 4px;
  padding-bottom: 8px;
  width: 100%;
  padding-left: 32px;
  border-left: 1px solid ${color4};
  position: relative;
  color: ${({ active }) => (active ? color5 : color3)};
  cursor: pointer;

  :before {
    display: block;
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    width: 3px;
    background-color: ${({ active }) => (active ? color4 : 'transparent')};
    height: 100%;
  }
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
`;

export const StepText = styled.div`
  padding-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
`;
