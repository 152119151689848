import styled from 'styled-components';

import { color36 } from '../colors';

export const PageTitleContainer = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 61px;
  /* identical to box height */
  color: #386692;
  color: ${color36};
  background-image: url(/img/page-title-background.svg);

  @media screen and (max-width: 1024px) {
    display: none;
    &.page__title__container_display_mobile {
      display: flex;
      height: 61px;
      font-weight: 700;
      font-size: 22.5px;
      line-height: 27px;
      background-size: cover;
    }
  }
`;
