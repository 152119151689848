import styled from 'styled-components';
import { color1, color36, color65 } from '../../../shareComponent/styled';
import { RecipePageContainer } from '../../../shareComponent/recipePage/styled';

export const AboutContainer = styled(RecipePageContainer)`
  background-color: ${color1};
  padding-bottom: 202px;

  @media screen and (max-width: 711px) {
    padding-bottom: 0px;
  }
`;

export const AboutWrapper = styled.div`
  padding-top: 86px;
  width: 1180px;
  margin: auto;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;

  .about__bold {
    font-weight: 600;
  }

  .about__content-wrapper {
    width: 713px;
    margin: auto;
  }

  .about__ps-wrapper {
    background-color: ${color65};
    padding: 41px 0;
  }

  .about__image-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    padding-bottom: 77px;
  }

  .about__page-title {
    display: none;
    margin: 0;
    padding-bottom: 10px;
    font-size: 32px;
  }

  .about__paragraph {
    margin-top: 0;
    margin-bottom: 38px;
  }

  .about__sign {
    margin: 0px;
    padding-top: 2em;
    padding-bottom: 84px;
  }

  .about__link {
    display: inline-block;
    text-decoration: none !important;
    color: ${color36} !important;
  }

  @media screen and (max-width: 711px) {
    width: 100%;
    padding: 30px 0 0 0px;
    font-size: 14x;
    line-height: 28px;

    .about__content-wrapper {
      width: 100%;
      padding: 0 15px;
    }

    .about__image-wrapper {
      padding-bottom: 25px;
      svg {
        width: 100%;
        height: auto;
      }
    }

    .about__page-title {
      display: block;
      padding: 0px 15px 25px 15px;
    }

    .about__paragraph {
      margin-bottom: 28px;
    }

    .about__sign {
      padding-top: 0px;
      padding-bottom: 2em;
    }
  }
`;
