import styled from 'styled-components';
import {
  color1,
  color4,
  color8,
  color21,
} from '../../../shareComponent/styled';

const sortFilterText = `
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
`;

export const SortFilterLabel = styled.span`
  display: inline-block;
  color: ${color8};
  padding-right: 11px;
  ${sortFilterText};
`;

export const SortFilter = styled.span`
  display: inline-block;
  color: ${({ active }) => (active ? color4 : color8)};
  font-weight: ${({ active }) => (active ? '600' : '500')};
  padding-right: 11px;
  cursor: pointer;

  ${sortFilterText};
  :last-child {
    padding-right: 0;
  }
  @media screen and (max-width: 1024px) {
    color: ${color1};
    font-weight: ${({ active }) => (active ? 'bold' : '500')};
  }
`;

export const SortPanelContainer = styled.div`
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;

    ${SortFilterLabel} {
      display: none;
    }

    ${SortFilter} {
      width: 100%;
      padding: 8px 21px;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.01em;
      cursor: pointer;
      border-bottom: 1px solid ${color21};
      outline: none;
      :focus {
        outline: none;
      }
      :last-child {
        border-bottom: none;
      }
    }
  }
`;
