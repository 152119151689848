import styled from 'styled-components';

export const EndTitle = styled.div`
  width: 674px;
  text-align: center;
  padding-bottom: 86px;
`;

export const EndButtonPanel = styled.div`
  width: 100%;
  text-align: center;
`;

export const EndContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 150px;
  height: 576px;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    height: 335px;
    padding: 0;

    ${EndTitle} {
      padding-top: 48px;
      padding-bottom: 0px;
      width: 100%;
    }
  }
`;
