import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import LoaderSpinner from 'react-loader-spinner';
import { LoaderContainer } from './styled';

export const Loader = () => (
  <LoaderContainer>
    <LoaderSpinner
      type="Hearts"
      color="rgb(248, 106, 113"
      height={100}
      width={100}
    />
  </LoaderContainer>
);
