import styled from 'styled-components';

export const ExpiredButton = styled.button`
  width: 180px;
  height: 50px;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  /* or 201% */
  letter-spacing: -0.01em;
  color: ${({ error }) => (error ? '#EB7791' : '#5d9bab')};
  padding-top: 9px;
  padding-bottom: 10px;
  outline: none;
  background: #ffffff;
  border: 2px solid ${({ error }) => (error ? '#F9AEBF' : '#bee6d5')};
  box-sizing: border-box;
  border-radius: 50px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    background: ${({ error }) => (error ? '#FFE3E9' : '#bee5d4')};
  }
  :active {
    background: ${({ error }) => (error ? '#F99EB2' : '#7ec1a4')};
    color: #ffffff;
  }
`;
