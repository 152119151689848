import React from 'react';

import {
  PrivacyPageWrapper,
  PrivacyPageContentWrapper,
  PrivacyPageContainer,
  PrivacyLinksSide,
  PrivacyMainSide,
  Link,
} from './styled';
import Header from '../../shareComponent/header';
import Policy from './policy';
import Analytics from './analytics';
import ScrollUpButton from '../../components/scrollButton';

const PrivacyPage = () => {
  const links = [
    {
      text: `Our Policy`,
      id: 'policy',
    },
    {
      text: `Third-party analytics and interest-based advertising`,
      id: 'analytics',
    },
    {
      text: `California Residents`,
      id: `california_residents`,
    },
    {
      text: `Choices and Access to Information`,
      id: `choices_access`,
    },
    {
      text: `Data Retention and Deletion`,
      id: `data_retention`,
    },
    {
      text: `Information for users from outside the United States`,
      id: `information_outside`,
    },
    {
      text: `Links to Other Websites or Services`,
      id: `links`,
    },
  ];

  const scrollTo = id => {
    const el = document.getElementById(id);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PrivacyPageWrapper>
      <Header />
      <PrivacyPageContentWrapper>
        <PrivacyPageContainer>
          <PrivacyLinksSide>
            {links.map((item, index) => (
              <Link
                key={`${item.text}_${index}`}
                onClick={e => {
                  scrollTo(item.id);
                }}>
                {item.text}
              </Link>
            ))}
          </PrivacyLinksSide>
          <PrivacyMainSide>
            <Policy handleScrollTo={scrollTo} />
            <Analytics handleScrollTo={scrollTo} />
            <div
              id="california_residents"
              className="privacy-main-side__section">
              <h1 className="privacy-main-side__h1">California Residents</h1>
              <p className="privacy-main-side__section-content">
                If you are a California resident, you have a right to request
                certain information with respect to the types of personal
                information we have shared with third parties for their direct
                marketing purposes, and the identities of those third parties,
                within the immediately preceding calendar year, subject to
                certain exceptions. All requests for such information must be
                sent to 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>.
              </p>
              <p className="privacy-main-side__section-content">
                This same California law permits us to provide you, in response
                to your written request, with a cost-free means to choose not to
                have your information shared rather than providing the above
                described information. To that end, you may request that we do
                not disclose your personal information to unaffiliated third
                parties for their own direct marketing purposes by contacting us
                by email at 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>.
              </p>
            </div>
            <div id="choices_access" className="privacy-main-side__section">
              <h1 className="privacy-main-side__h1">
                Choices and Access to Information
              </h1>
              <p className="privacy-main-side__section-content">
                If you no longer wish to receive marketing communications from
                us, please follow the “unsubscribe” instructions that are
                included at the bottom of each message or email us at 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>. 
                We may still send you certain communications relating to the
                Site, such as service announcements and administrative messages.
              </p>
              <p className="privacy-main-side__section-content">
                You have the right and ability to edit certain account
                information on our Site at any time by accessing your account
                settings or contacting us by email at 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>. 
                Users may also have additional rights to access, review,
                correct, delete, or inquire about information that we hold about
                them. To make such a request, please send us an email at 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>. 
                Please note that we may need to verify your identity or request
                additional information from you before we are able to comply
                with your request.
              </p>
            </div>
            <div id="data_retention" className="privacy-main-side__section">
              <h1 className="privacy-main-side__h1">
                Data Retention and Deletion
              </h1>
              <p className="privacy-main-side__section-content">
                We retain information for as long as reasonably necessary to
                deliver our services to you, to fulfill the purposes described
                in this Policy, or as required by law.  To request the deletion
                of your personal information, please send us an email at 
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>. 
                We will make our best effort to delete all personal information
                about you.  However, please note that certain information,
                including other information, may be retained in backup
                databases.
              </p>
            </div>
            <div
              id="information_outside"
              className="privacy-main-side__section">
              <h1 className="privacy-main-side__h1">
                Information for users from outside the United States
              </h1>
              <p className="privacy-main-side__section-content">
                The personal and other information that we collect through or in
                connection with the Site is transferred to and processed in the
                United States for the purposes described above.  We also may
                subcontract the processing of your data to, or otherwise share
                your data with, affiliates or third parties in the United States
                or countries other than your country of residence.  The
                data-protection laws in these countries may be different from,
                and less stringent than, those in your country of residence.  By
                using the Site or by providing any personal or other information
                to us, you expressly consent to such transfer and processing.
              </p>
              <h3 className="privacy-main-side__h3">Children’s Privacy</h3>
              <p className="privacy-main-side__section-content">
                The Site is not directed to children under the age of 18, and
                ShopGizmo Inc. does not knowingly collect personal or other
                information from anyone who is under the age of 18.
              </p>
            </div>
            <div id="links" className="privacy-main-side__section">
              <h1 className="privacy-main-side__h1">
                Links to Other Websites or Services
              </h1>
              <p className="privacy-main-side__section-content">
                The Site may contain links to and from the websites or services
                of other third parties.  If you follow a link to any of these
                websites or services, please note that these websites, and any
                services that may be accessible through them, have their own
                privacy policies. A link to any third-party site does not imply
                that we endorse or accept any responsibility for the content or
                use of such site.  We encourage our users to be aware when they
                leave the Site and to read the privacy policies applicable to
                such third-party websites and apps.  This Privacy Policy applies
                only to information collected in connection with the Site.
              </p>
              <h3 className="privacy-main-side__h3">Changes to this Policy </h3>
              <p className="privacy-main-side__section-content">
                Our Site and the services made available through it may change
                from time to time.  As a result, we may need to change this
                Privacy Policy.  We reserve the right to update or modify this
                Privacy Policy at any time and without prior notice.  We will
                post any revised Privacy Policy on our website with an
                “effective date” indicating when the changes will take effect.
                We encourage you to review this Privacy Policy periodically.
              </p>
              <h3 className="privacy-main-side__h3">Questions or comments</h3>
              <p className="privacy-main-side__section-content">
                If you have any questions or comments regarding our Policy,
                please send us an email at {' '}
                <a href="mailto:hello@shopgizmo.co">hello@shopgizmo.co</a>.
              </p>
              <p className="privacy-main-side__section-content">
                <span className="privacy-main-side__label">
                  Effective date: 
                </span>
                6/1/20
              </p>
              <p className="privacy-main-side__section-content">
                Updated last: 7/10/20
              </p>
            </div>
          </PrivacyMainSide>
        </PrivacyPageContainer>
      </PrivacyPageContentWrapper>
      <ScrollUpButton />
    </PrivacyPageWrapper>
  );
};

export default PrivacyPage;
