import React from 'react';
import PropTypes from 'prop-types';

import { FiltersContainer, FiltersLabel, FilterItem } from './styled';
import Checkbox from '../checkbox';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  currItems: PropTypes.arrayOf(PropTypes.string),
  isOnlyOne: PropTypes.bool,
  onChange: PropTypes.func,
};

const Filters = ({ items, currItems, onChange, isOnlyOne }) => {
  const changeFilter = item => {
    const val = Object.keys(item);

    if (val[0] && currItems.find(item => item === val[0])) {
      onChange({
        name: 'store',
        value: [...currItems.filter(item => item !== val[0])],
      });
    } else if (val[0]) {
      onChange({ name: 'store', value: [...currItems, val[0]] });
    }
  };

  const changeOnlyOne = item => {
    const { is_first_time_use_only } = item;
    onChange({ name: 'is_first_time_use_only', value: is_first_time_use_only });
  };

  return (
    <FiltersContainer>
      <FiltersLabel>STORES</FiltersLabel>
      {items &&
        items.length > 0 &&
        items.map(({ id, name }) => (
          <FilterItem
            key={id}
            onClick={() => {}}
            active={!!currItems.find(item => item === id)}>
            <Checkbox
              name={id}
              defaultValue={!!currItems.find(item => item === id)}
              onChange={changeFilter}
              label={name}
            />
          </FilterItem>
        ))}
      <div className="horizontal-divider" />
      <FilterItem active={!!isOnlyOne} smaller>
        <Checkbox
          name="is_first_time_use_only"
          label="Exclude Sign Up Deals"
          onChange={changeOnlyOne}
          defaultValue={!!isOnlyOne}
        />
      </FilterItem>
    </FiltersContainer>
  );
};

Filters.propTypes = propTypes;

export default Filters;
