import styled from 'styled-components';
import { color5, color36 } from '../../../shareComponent/styled';

export const FAQWrapper = styled.div`
  padding-top: 30px;
  width: 1080px;
  margin: auto;
  color: ${color5};

  .FAQ__page-title {
    font-size: 32px;
    display: none;
    margin: 0;
    padding-bottom: 10px;
  }

  .FAQ__title {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 30px 0 20px 0;
  }

  .FAQ__link {
    display: inline-block;
    text-decoration: none !important;
    color: ${color36};
  }

  .FAQ__paragraph {
    :before {
      content: '';
      width: 2em;
      display: inline-block;
    }
  }

  .faq-cardgizmo__video__wrapper {
    max-width: 860px;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%;
    margin-top: 20px;
    /* margin: 20px auto 0 auto; */
  }

  .faq-cardgizmo__video {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    z-index: 10000;
  }

  .faq-cardgizmo__screenshot-wrapper {
    padding: 20px 0;
    max-width: 860px;
  }

  .faq-cardgizmo__screenshot {
    width: 100%;
    display: block;
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 30px 15px 10px 15px;

    .FAQ__page-title {
      display: block;
    }
  }
`;
