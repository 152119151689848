import styled from 'styled-components';

import { color44 } from '../../../shareComponent/styled';

export const InstallWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .install__image-wrapper {
    width: 402px;
    margin: 0 auto;
    padding-top: 33px;
  }

  .install__image {
    display: block;
    width: 100%;
    height: auto;
  }

  .install__h1 {
    padding: 50px 5px 0px 5px;
    font-weight: 800;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
    color: ${color44};
    width: 100%;
    margin-top: 17px;
    margin-bottom: 32px;
  }

  @media only screen and (max-width: 1024px) {
    min-height: calc(100vh - 360px);

    .install__h1 {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
  }
`;
