import React from 'react';
import PropTypes from 'prop-types';

import { SortPanelContainer, SortFilter, SortFilterLabel } from './styled';

const propTypes = {
  onChange: PropTypes.func,
  sort: PropTypes.oneOfType([
    PropTypes.shape({ EXPIRE: PropTypes.oneOf(['ASC', 'DESC']) }),
    PropTypes.shape({ NEWEST: PropTypes.oneOf(['ASC', 'DESC']) }),
    PropTypes.shape({ OFF: PropTypes.oneOf(['ASC', 'DESC']) }),
  ]),
};

const SortPanel = ({ onChange, sort }) => {
  /* const def = {
    EXPIRE: false,
    NEWEST: false,
    OFF: false,
  }; */

  const handleChange = name => {
    onChange({ [name]: sort[name] === 'ASC' ? 'DESC' : 'ASC' });
  };

  return (
    <SortPanelContainer>
      <SortFilterLabel>Sort By: </SortFilterLabel>
      <SortFilter
        active={!!sort.EXPIRE}
        onClick={() => {
          handleChange('EXPIRE');
        }}>
        Expiration Date
      </SortFilter>
      <SortFilter
        active={!!sort.NEWEST}
        onClick={() => {
          handleChange('NEWEST');
        }}>
        Newest
      </SortFilter>
      <SortFilter
        active={!!sort.OFF}
        onClick={() => {
          handleChange('OFF');
        }}>
        %OFF
      </SortFilter>
    </SortPanelContainer>
  );
};

SortPanel.propTypes = propTypes;

export default SortPanel;
