import React from 'react';
import {
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';
import Footer from '../../components/footer';
import { TermsWrapper } from './styled';
import { useLink } from '../../hooks';

const Terms = () => {
  const { linkTo } = useLink();

  return (
    <>
      <RecipePageContainer>
        <Header activeMenu="terms" />
        <PageTitleContainer>Terms of Use</PageTitleContainer>
        <Container>
          <NavMenu>
            <NavMenuItem
              onClick={e => {
                e.preventDefault();
                linkTo('/');
              }}
              href="/">
              Home
            </NavMenuItem>{' '}
            / <NavMenuItem active>Terms of Use</NavMenuItem>
          </NavMenu>
          <TermsWrapper>
            <h1 className="terms__page-title">Terms of Use</h1>
            <div className="terms__content">
              <div className="terms__item">
                <div className="terms__title">
                  Terms and Conditions of Your Use of This Website
                </div>
                <div className="terms__text">
                  YOUR USE OF THIS WEBSITE CONSTITUTES YOUR AGREEMENT TO THE
                  FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE
                  TERMS, YOU SHOULD IMMEDIATELY CEASE USE OF THIS WEBSITE.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">
                  This Website and Your ShopGizmo.co Account
                </div>
                <div className="terms__text">
                  This Website is intended only for use by adults aged 18 and
                  older. If you create a ShopGizmo.co account, you are
                  responsible for maintaining the confidentiality of your
                  account and password. You must qualify in your local
                  jurisdiction to participate in any of our promotions or other
                  offers on this Website or otherwise.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Links</div>
                <div className="terms__text">
                  Although this Website may be linked to other Websites,
                  ShopGizmo does not endorse, approve, certify or sponsor the
                  linked Website unless specifically stated therein. ShopGizmo
                  is not the owner of, does not control and is not responsible
                  for any content of any Website linked to this Website. Your
                  linking to other Websites is at your own risk.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Governing Law</div>
                <div className="terms__text">
                  This Website has been designed to comply with United States
                  law. ShopGizmo does not represent that the content of this
                  Website is appropriate for locations outside the United
                  States. If you access this Website from locations outside the
                  United States, you are responsible for complying with all
                  applicable local laws.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Privacy Statement</div>
                <div className="terms__text">
                  We are committed to protecting your privacy. Please review the
                  Terms and Conditions of the ShopGizmo 
                  <a href="/privacy">Privacy Policy</a>.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Trademarks and Copyright</div>
                <div className="terms__text">
                  The copyrights to all contents of this Website are proprietary
                  to ShopGizmo or its third party licensors, and you may not
                  reproduce, post on any other Website, modify, distribute or
                  transmit any portion of this Website without the express
                  written consent of ShopGizmo. Any name, logo, trademark or
                  service mark contained on this Website is owned or licensed by
                  ShopGizmo and may not be used by you without the prior written
                  consent of ShopGizmo. You may access this Website for your
                  individual non-commercial use only.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Material You Submit</div>
                <div className="terms__text">
                  You acknowledge that you are responsible for any submission
                  you make, including the legality, reliability,
                  appropriateness, originality and copyright of any such
                  material. You may not post or transmit to this Website any
                  content that is libelous, defamatory, obscene, threatening,
                  invasive of privacy or publicity rights. ShopGizmo assumes no
                  responsibility or liability which may arise from such content,
                  including but not limited to claims for defamation, libel,
                  slander, obscenity, profanity or misrepresentation.
                </div>
                <div className="terms__text">
                  Any material or communication transmitted by you to this
                  Website will not be treated as confidential. Any ideas,
                  concepts, or other materials transmitted by you to ShopGizmo
                  may be used by ShopGizmo in any manner, including
                  reproduction, transmission, publication or broadcast without
                  compensation.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Accuracy of Content</div>
                <div className="terms__text">
                  Although we make all reasonable efforts to ensure that the
                  content of the Website is updated and corrected, ShopGizmo
                  makes no warranties as to its accuracy.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Disclaimers</div>
                <div className="terms__text">
                  YOUR USE OF THIS WEBSITE IS AT YOUR SOLE RISK. THE WEBSITE IS
                  PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE RESERVE
                  THE RIGHT TO RESTRICT OR TERMINATE YOUR ACCESS TO THE WEBSITE
                  OR ANY FEATURE OR PART THEREOF AT ANY TIME. SHOPGIZMO
                  EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANYKIND, WHETHER EXPRESS
                  OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND ANY
                  WARRANTIES THAT MATERIAL ON THE WEBSITE IS NONINFRINGING; THAT
                  ACCESS TO THE WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE;
                  THAT THE WEBSITE WILL BE SECURE; OR THAT INFORMATION ON THE
                  WEBSITE WILL BE COMPLETE, ACCURATE OR TIMELY. IF YOU DOWNLOAD
                  ANY MATERIAL FROM THIS WEBSITE, YOU DO SO AT YOUR OWN
                  DISCRETION AND RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                  DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
                  FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Limitation of Liability</div>
                <div className="terms__text">
                  IN NO EVENT SHALL SHOPGIZMO BE LIABLE FOR ANY DIRECT,
                  INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
                  DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF
                  THIS WEBSITE OR ANY INFORMATION PROVIDED ON THIS WEBSITE.
                  BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE
                  EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                  INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Modification of These Terms</div>
                <div className="terms__text">
                  We reserve the right, at our complete discretion, to change
                  these Terms and Conditions at any time for any reason, without
                  notice or obligation. By entering this Website, you will be
                  bound by any such revisions we make to the Terms and
                  Conditions. Your continued use of this Website following the
                  posting of changes to the terms or other policies means that
                  you acknowledge and accept the changes.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Discount Descriptions</div>
                <div className="terms__text">
                  We attempt to be as accurate as possible when posting content.
                  We do not warrant that product descriptions or other content
                  is accurate, complete, reliable, current or error-free.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Return Policy</div>
                <div className="terms__text">
                  All sales are final. If you believe you’ve received a
                  defective unit please email our customer service department at{' '}
                  <a href="mailto:hello@shopgizmo.co">hello@ShopGizmo.co</a>
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">Severability</div>
                <div className="terms__text">
                  If any provision of these Terms and Conditions shall be deemed
                  unlawful, void or, for any reason, unenforceable, that
                  provision shall be deemed severable from these Terms and
                  Conditions, and shall not affect the validity and
                  enforceability of any remaining provisions.
                </div>
              </div>
              <div className="terms__item">
                <div className="terms__title">How to Contact Us</div>
                <div className="terms__text">
                  If you have any questions or comments about the Terms and
                  Conditions of this Website, please email us at{' '}
                  <a href="mailto:hello@ShopGizmo.co">hello@ShopGizmo.co</a>
                </div>
              </div>
            </div>
          </TermsWrapper>
        </Container>
      </RecipePageContainer>
      <Footer />
    </>
  );
};

export default Terms;
