import styled from 'styled-components';
import {
  color1,
  color5,
  color16,
  color34,
  color49,
  color50,
  color51,
} from '../../../../shareComponent/styled';

export const ExamplesWrapper = styled.div`
  padding-top: 68px;
  padding-bottom: 26px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${color49};
  .example__header {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: ${color5};
    width: 100%;
    padding-bottom: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const ItemsCarouselWrapper = styled.div`
  width: 974px;
  filter: drop-shadow(2px 2px 17px rgba(0, 0, 0, 0.0995684));
  * :focus {
    outline: none;
  }
  .items-carousel-wrapper__static {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    .items-carousel__slide.items-carousel__slide_active {
      z-index: 5;
      transform: scale(1.17);
      filter: drop-shadow(2px 2px 17px rgba(0, 0, 0, 0.0995684));
    }
  }

  .carousel-item.active {
    z-index: 10 !important;
    .items-carousel__slide {
      transform: scale(1.17);
      filter: drop-shadow(2px 2px 17px rgba(0, 0, 0, 0.0995684));
    }
  }
  .items-carousel__slide {
    height: 353px;
    width: 336px;
    margin: 60px 0;
    background-color: ${color1};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.5s ease;
    position: relative;
  }

  .item-carousel__slide__header {
    height: 35px;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .item-carousel__slide__image {
    display: block;
    outline: none;
  }

  .item-carousel__slide__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 178px;
    overflow: hidden;
    position: relative;
    img {
      max-height: 100%;
      object-fit: cover;
    }
  }

  .item-carousel__slide__sale-price {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${color34};
  }

  .item-carousel__slide__sale-price__value {
    font-weight: 700;
  }

  .item-carousel__slide__price {
    display: inline-block;
    height: 24px;
    position: relative;
    color: ${color16};
    font-weight: normal;
    font-size: 12.8px;
    line-height: 16px;
    text-align: center;
    color: ${color50};

    :after {
      content: '';
      display: block;
      width: calc(100% + 14px);
      position: absolute;
      top: 8px;
      left: -7px;
      height: 1px;
      background-color: ${color16};
    }
  }
  .item-carousel__slide__price-wrapper {
    text-align: center;
  }
  .item-carousel__slide__sticker {
    display: none;
    width: 82px;
    height: 82px;
    background-color: ${color51};
    filter: drop-shadow(0px 0px 7px rgba(164, 164, 164, 0.5));
    transform: rotate(13deg);
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: ${color1};
    position: absolute;
    z-index: 10;
  }

  .carousel-item.active .item-carousel__slide__sticker,
  .items-carousel__slide_active .item-carousel__slide__sticker {
    display: flex;
    flex-wrap: wrap;
    right: 20px;
    top: 56px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-content: center;
  }

  .item-carousel__slide__sticker__price {
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    .items-carousel__slide {
      width: 310px;
      height: 333px;
      margin: 60px auto;
    }
  }
`;
