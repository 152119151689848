import React from 'react';
import PropTypes from 'prop-types';

import {
  ItemContainer,
  ItemHeader,
  ItemLogo,
  ItemLogoWrapper,
  ItemMainContent,
  H1,
  H2,
  H3,
  ItemFooter,
  ItemWrapper,
} from './styled';

import { TileButton } from '../styled';
import renderFromBBCode from '../utils/renderFromBBCode';
import { FirstTimeIcon } from '../recipe/icons';

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    h3: PropTypes.string,
    logo_url: PropTypes.string,
    preview: PropTypes.bool,
    className: PropTypes.string,
    expiredAt: PropTypes.string,
    has_first_time_use_only_deals: PropTypes.bool,
  }),
  isSmall: PropTypes.any,
  handleClick: PropTypes.func,
};

const addZero = num => ('0' + num).slice(-2);

const formatExpirationDate = val => {
  if (val) {
    const date = new Date(val);

    return `${addZero(date.getMonth() + 1)}/${addZero(
      date.getDate(),
    )}/${addZero(
      date
        .getFullYear()
        .toString()
        .substr(2),
    )}`;
  }

  return '';
};

const Item = ({ item, preview, className, isSmall, handleClick }) => {
  const { logo_url, h1, h2, h3, expiredAt, has_first_time_use_only_deals } =
    item || {};

  return (
    <ItemWrapper
      onClick={() => {
        /* if (typeof window === 'object' && !preview) {
              window.location = `/recipe/${item.id}`;
            } */
        handleClick(`/recipe/${item.id}`);
      }}
      className={className}>
      <ItemContainer>
        {has_first_time_use_only_deals && (
          <div className="item__first-time-wrapper">
            <FirstTimeIcon />
          </div>
        )}
        <ItemHeader>
          <ItemLogoWrapper>
            {logo_url && <ItemLogo src={logo_url} alt="logo" />}
          </ItemLogoWrapper>
        </ItemHeader>
        <ItemMainContent>
          <div>
            <H1>{renderFromBBCode(h1)}</H1>
            <H2>{renderFromBBCode(h2)}</H2>
            <H3>{renderFromBBCode(h3)}</H3>
          </div>
        </ItemMainContent>
        <ItemFooter>
          <div className="item-footer__expire-date">
            <p className="item-footer__expire-date__label">EXPIRATION DATE</p>
            <p className="item-footer__expire-date__date">
              {formatExpirationDate(expiredAt)}
            </p>
          </div>
          <TileButton
            onClick={
              !isSmall
                ? () => {
                    /* if (typeof window === 'object' && !preview) {
                      window.location = `/recipe/${item.id}`;
                    } */
                    handleClick(`/recipe/${item.id}`);
                  }
                : () => {}
            }>
            SHOP GIZMO
          </TileButton>
        </ItemFooter>
      </ItemContainer>
    </ItemWrapper>
  );
};

Item.propTypes = propTypes;

export default Item;
