import styled from 'styled-components/macro';

import {
  color1,
  color2,
  color12,
  color5,
} from '../../../shareComponent/styled';

import {
  EmailWrapper,
  EmailContainer,
  EmailButton,
  EmailInput,
} from '../../../shareComponent/recipe/emailForm/styled';

export const H1 = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  /* or 141% */
  color: ${color5};

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const MainPageContainer = styled.div`
  width: 100%;
  padding-top: ${({ isSticky }) => (isSticky ? '30px' : '0px')};
  margin: auto;

  @media screen and (max-width: 1024px) {
    overflow: hidden;
    padding-top: 0;
    * {
      box-sizing: border-box;
    }
  }
`;

export const TopSectionLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  height: 60px;
  img {
    margin: 0;
  }
`;

export const TopSection = styled.div`
  width: 100%;
  background-color: ${color1};
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    height: auto;
    flex-flow: column-reverse;
    padding: 0;

    ${TopSectionLogoWrapper} {
      display: none;
    }
  }
`;

export const TopSectionLeft = styled.div`
  width: 43%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .top-section__left-wrapper {
    max-width: 613px;
    width: 100%;
    position: relative;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 283px;

    .top-section__left-wrapper {
      padding-top: 0;
      height: 100%;
      max-width: 100%;
    }
  }
`;

export const TopSectionRight = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  padding: 60px 0 0 66px;
  justify-content: left;
  color: ${color5};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  .top-section__right-content {
    max-width: 592px;
  }

  .top-section__text {
    margin-top: 34px;
    margin-bottom: 28px;
    max-width: 454px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 10px 22px;
    padding-top: 0px;
    background-color: ${color12};
    overflow: initial;
    position: relative;
    flex: 1 1 300px;

    .top-section__right-content {
      max-width: 375px;
      margin: 0 auto;
    }

    .top-section__text {
      max-width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 18px auto;
    }
  }
`;

export const IllustrationMain = styled.img`
  display: block;
  outline: none;
  width: 100%;
  @media screen and (max-width: 1024px) {
    max-height: 300px;
    object-fit: cover;
  }
`;

export const IllustrationMainWrapper = styled.div`
  width: 100%;
`;

export const TopSectionStarPanel = styled.div`
  flex: 1;
  padding-top: 42px;
  width: 100%;
`;

export const TopSectionStarTextPanel = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  padding-top: 43px;
  flex: 3;
`;

export const StarWrapper = styled.i`
  display: inline-block;
  width: 14px;
  margin-right: 4px;

  :last-child {
    margin-right: 0;
  }
`;

export const TopSectionButtonPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 404px;

  @media screen and (max-width: 1024px) {
    margin: auto;
    justify-content: center;
    width: 100%;
    :last-child {
      bottom: 10px;
    }

    ${TopSectionStarPanel} {
      padding-top: 16px;
      text-align: center;
    }

    ${TopSectionStarTextPanel} {
      padding-top: 16px;
      font-size: 10px;
      flex: 2;
    }
  }
`;

export const Subscribe = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 369px;
  background-color: #ffdd8b;
  margin-bottom: 144px;
  background-image: url(/img/Coupons.svg);
  background-repeat: no-repeat;
  background-position: center;

  .h1 {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    width: 100%;
  }

  .h2 {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    width: 100%;
    padding-bottom: 27px;
    padding-top: 4px;
  }

  .subscribe__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 900px;
  }

  ${EmailWrapper} {
    height: auto;
    width: 690px;
  }

  ${EmailContainer} {
    width: 100%;
  }

  ${EmailInput} {
    border: none;
  }

  .subscribe__text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding-top: 35px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 0;

    .h1 {
      font-size: 32px;
      line-height: 38px;
    }

    .h2 {
      font-size: 24px;
      line-height: 30px;
    }

    .subscribe__text {
      padding-top: 20px;
      font-size: 14px;
      line-height: 18px;
    }

    ${EmailWrapper} {
      width: calc(100% - 20px);
      max-width: 360px;
    }
    ${EmailButton} {
      flex: 0 0 120px;
    }
  }
`;

export const Recipe = styled.img`
  display: block;
  width: auto;
  height: auto;
  outline: none;
  margin-top: 134px;

  &.recipe_mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    display: none;

    &.recipe_mobile {
      margin-top: 100px;
      display: block;
      margin-bottom: 90px;
      height: 517px;
      width: 343px;
    }
  }
`;

export const RecipePanel = styled.div`
  width: 100%;
  height: 716px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color2};
  overflow: hidden;
  flex-wrap: wrap;

  @media screen and (max-width: 1024px) {
    height: 724px;
  }
`;

const smallFooterLinkCss = `
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  color: #FAFAFA;
`;

export const Footer = styled.footer`
  height: 274px;
  background-color: #1f2133;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 61px;
  color: ${color1};
  box-sizing: border-box;

  .footer__content {
    width: 803px;
    margin: 0 auto;
  }

  .footer__link-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .footer__bottom-content-wrapper {
    padding-top: 127px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .footer__bottom-content {
      width: 50%;
      display: flex;
      ${smallFooterLinkCss}
    }

    .footer__tou-content {
      display: flex;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-left: 160px;
    }

    .footer__icons {
      display: flex;
      margin-right: -10px;
    }

    .footer__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      border: 1px solid ${color1};
      margin-right: 20px;
      text-decoration: none;
      :last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FooterSide = styled.div`
  width: 25%;
`;

export const FooterLink = styled.a`
  display: inline-block;
  outline: none;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  ${({ small }) => (small ? smallFooterLinkCss : '')}
`;
