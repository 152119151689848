import React from 'react';
import PropTypes from 'prop-types';
import { TrustedContainer, Title, RetailerIcon } from './styled';
import { H1 } from '../styled';

const retailers = [
  { name: 'amazon', src: '/img/retailers/amazon.svg' },
  { name: 's', src: '/img/retailers/s.png' },
  { name: 'sephora', src: '/img/retailers/sephora.png' },
  { name: 'target', src: '/img/retailers/target.svg' },
  { name: 'macys', src: '/img/retailers/macys.svg' },
  { name: 'kmart', src: '/img/retailers/kmart.svg' },
  { name: 'dom', src: '/img/retailers/dom.png' },
  { name: 'sfa', src: '/img/retailers/sfa.png' },
  { name: 'jcp', src: '/img/retailers/jcp.svg' },
  { name: 'bloomingdales', src: '/img/retailers/bloomingdales.png' },
];

const defaultContent = {
  title: 'Trusted Retailers Only',
  text: 'Save multiple times over from the stores you already know and trust',
};

const propTypes = {
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      src: PropTypes.string,
    }),
  ),
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
};

const Trusted = ({ id, items, content }) => (
  <TrustedContainer id={id}>
    <div className="trusted__wrapper">
      {items.map((item, index) => (
        <RetailerIcon name={item.name} key={index}>
          <img src={item.src} alt="retailer icon" className="icon" />
        </RetailerIcon>
      ))}
      <Title>
        <H1>{content.title}</H1>
      </Title>
      <div className="trusted__text">{content.text}</div>
    </div>
  </TrustedContainer>
);

Trusted.propTypes = propTypes;
Trusted.defaultProps = {
  content: defaultContent,
  items: retailers,
};

export default Trusted;
