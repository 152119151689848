import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePortal } from '../../hooks';

import { ModalWrapper } from './styled';

const Modal = ({ handleClose, children, modalId }) => {
  const wrapper = useRef(null);
  const el = usePortal(modalId || 'sg-modal');

  return (
    el &&
    ReactDOM.createPortal(
      <ModalWrapper
        onClick={e => {
          if (wrapper && !wrapper.current.contains(e.target)) {
            handleClose();
          }
        }}>
        <div className="modal__container" ref={wrapper}>
          <i className="modal__close-button" onClick={handleClose}>
            +
          </i>
          {children}
        </div>
      </ModalWrapper>,
      el,
    )
  );
};

export default Modal;
