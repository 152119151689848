import styled from 'styled-components/macro';
import {
  color11,
  color20,
  color23,
  color37,
} from '../../../shareComponent/styled';

export const Sidebar = styled.div`
  display: block;
  width: 186px;
`;

export const DealsContainer = styled.div`
  width: 992px;
`;

export const DealsTopPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-left: 2px solid ${color37};
`;

export const DealsTopPanelLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 3;
`;

export const DealsTopPanelRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 2;
`;

export const DealsContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  width: 100%;

  @media screen and (max-width: 1280px) {
    ${DealsContainer} {
      width: 768px;
      margin: auto;
    }
    ${DealsTopPanelLeft} {
      padding-right: 31px;
    }
  }

  @media screen and (max-width: 1024px) {
    box-sizing: border-box;
    width: 100%;
    padding: 9px 16px;
    box-shadow: 7px 4px 40px rgba(0, 0, 0, 0.07);
    z-index: 1053;

    ${DealsContainer} {
      width: 100%;
      max-width: 343px;
      min-height: calc(100vh - 477px);
      margin: auto;
    }
  }
`;

export const MobileTopPanel = styled.div`
  background-color: ${color23};
  width: 100%;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  height: 53px;
  margin: 10px auto;
  color: ${color11};
  position: relative;

  .mobile-top-panel__left {
    flex: 1;
    text-align: left;
    padding-left: 25px;
  }
  .mobile-top-panel__right {
    flex: 1;
    text-align: right;
    padding-right: 25px;
  }
  .mobile-top-panel__filter,
  .mobile-top-panel__sort {
    display: inline-block;
    cursor: pointer;
    padding: 20px 0;
  }
`;

const mobileTopPanelButton = `
  display: inline-block;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const activeMobileFilterButton = `
  padding: 18px;
  margin-right: -25px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: rgba(138, 149, 165, 0.3);
`;

const activeMobileSortButton = `
  padding: 18px;
  margin-left: -25px;
  padding-right: 25px;
  padding-left: 25px;
  background-color: rgba(138, 149, 165, 0.3);
`;

const activeMobileFilters = `
  height: auto;
  opacity: 1;
`;

export const MobileTopPanelFilter = styled.span`
  ${mobileTopPanelButton}
  ${({ active }) => (active ? activeMobileFilterButton : '')};
`;

export const MobileTopPanelSort = styled.span`
  ${mobileTopPanelButton}
  ${({ active }) => (active ? activeMobileSortButton : '')};
`;

export const SortIcon = styled.span`
  display: inline-block;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  :first-child {
    margin-left: 6px;
  }
`;

export const MobileTopPanelFiltersWrapper = styled.div`
  opacity: 0;
  height: 0;
  ${({ show }) => (show ? activeMobileFilters : '')};
  transition: opacity, height 300ms ease-in;
  position: absolute;
  top: 99%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  overflow: hidden;
`;

export const MobileSorts = styled.div`
  background-color: ${color20};
  box-sizing: border-box;
  padding: 6px 16px;
`;

export const DealsMobileLayout = styled.div`
  * {
    box-sizing: border-box;
  }

  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
