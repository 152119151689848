import styled from 'styled-components';
import {
  EmailContainer,
  EmailButton,
  EmailWrapper,
} from '../../../shareComponent/recipe/emailForm/styled';
import { RecipePageContainer } from '../../../shareComponent/recipePage/styled';

export const UnsubscribePageWrapper = styled.div`
  ${RecipePageContainer} {
    min-height: auto;
  }
`;

export const UnsubscribeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  min-height: 300px;
  margin: auto;
  padding-top: 10px;
  .unsubscribe__text {
    width: calc(100% - 10px);
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    padding-bottom: 30px;
  }
  .unsubscribe__form {
    width: 100%;
    max-width: 440px;
    ${EmailContainer}, ${EmailWrapper} {
      width: 100%;
    }
    ${EmailButton} {
      max-width: 120px;
    }
  }

  @media only screen and (max-width: 480px) {
    min-height: 300px;
    .unsubscribe__form {
      width: calc(100% - 10px);
      max-width: 440px;
      ${EmailContainer}, ${EmailWrapper} {
        width: 100%;
      }
      ${EmailButton} {
        max-width: 120px;
      }
      padding-bottom: 30px;
    }
  }
`;
