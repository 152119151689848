import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  PaymentCardTypes as StyledPaymentCardTypes,
  PaymentCardTypesItem as StyledPaymentCardTypesItem,
} from './styled';
import PaymentCardTypeSelect from './paymentCardTypesSelect';
import { getCardTypes, updateUser } from '../../../../api';
import { DeleteItemIcon } from '../../../../components/icons';

const propTypes = {
  paymentCardTypes: PropTypes.arrayOf(PropTypes.string),
};

const PaymentCardTypes = ({ paymentCardTypes }) => {
  const [options, setOptions] = useState([]);
  const [cards, setCards] = useState(paymentCardTypes && []);

  const handleChange = id => {
    if (cards.length === 0 || !cards.find(item => item === id)) {
      updateUser({ paymentCardTypes: [...cards, id] });
      setCards([...cards, id]);
    }
  };

  const deleteUserCardType = id => {
    if (cards.length !== 0 || cards.length > 0) {
      updateUser({ paymentCardTypes: cards.filter(item => item !== id) });
      setCards([...cards.filter(item => item !== id)]);
    }
  };

  useEffect(() => {
    getCardTypes().then(data => setOptions(data));
  }, [setOptions]);

  useEffect(() => {
    setCards(paymentCardTypes);
  }, [paymentCardTypes]);

  return (
    <StyledPaymentCardTypes>
      <h4 className="payment-card-types__title">Payment Card Types</h4>
      {cards &&
        cards.length > 0 &&
        options
          .filter(item => cards.includes(item.id))
          .map(item => (
            <StyledPaymentCardTypesItem key={item.id}>
              <div className="payment-card-types__item__icon-wrapper">
                <img
                  src={item.publicUrl}
                  alt="card icon"
                  className="payment-card-types__item__icon"
                />
              </div>
              <div className="payment-card-types__item__text">{item.name}</div>
              <a
                href="/#"
                className="payment-card-types__item__delete-button"
                onClick={e => {
                  e.preventDefault();

                  deleteUserCardType(item.id);
                }}>
                <DeleteItemIcon />
              </a>
            </StyledPaymentCardTypesItem>
          ))}
      <PaymentCardTypeSelect
        options={options.map(item => ({
          value: item.id,
          text: item.name,
          image: item.publicUrl,
        }))}
        value={cards && cards.length > 0 ? cards : []}
        onChange={handleChange}
      />
    </StyledPaymentCardTypes>
  );
};

PaymentCardTypes.propTypes = propTypes;

export default PaymentCardTypes;
