import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const toast_in = keyframes`
  from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
`;

const ToastContainer = styled.span`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: ${({ fixed, position }) =>
    fixed
      ? position && position.top
        ? position.top + 'px'
        : '38px'
      : '-38px'};
  left: ${({ fixed, position }) =>
    fixed ? (position && position.left ? position.left + 'px' : '10%') : '0'};
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 18px;
  border-radius: 20px;
  background-color: ${({ error }) => (error ? '#ca5e57' : '#73b572')};
  animation: ${toast_in} 300ms;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  z-index: 1052;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const getPosition = curr => {
  const { x, y, height } = curr.getBoundingClientRect();

  return { top: y - height || 0, left: x - 50 || 0 };
};

const ToastWrapper = ({
  show,
  containerRef,
  children,
  error,
  fixed,
  hideToast,
}) => {
  const el = document.createElement('div');
  const position =
    containerRef && containerRef.current
      ? getPosition(containerRef.current)
      : { top: 38, left: 38 };

  useEffect(() => {
    const container = document.body;

    container.appendChild(el);

    const timeout = setTimeout(() => {
      hideToast();
    }, 3000);

    return () => {
      clearTimeout(timeout);
      container.removeChild(el);
    };
  }, [el, containerRef, show, hideToast]);

  return (
    show &&
    (fixed ? (
      ReactDOM.createPortal(
        <ToastContainer
          error={error}
          fixed={fixed}
          position={position}
          onClick={() => {
            hideToast();
          }}>
          {children}
        </ToastContainer>,
        el,
      )
    ) : (
      <ToastContainer
        error={error}
        onClick={() => {
          hideToast();
        }}>
        {children}
      </ToastContainer>
    ))
  );
};

export default ToastWrapper;
