import styled from 'styled-components';

import {
  retailersSizePosition,
  retailersSizePositionMobile,
} from './sizePositions';
import { color1, color14 } from '../../../../shareComponent/styled';
import { H1 } from '../../styled';

export const Title = styled.div`
  padding-top: 123px;
  padding-bottom: 6px;
`;

export const TrustedContainer = styled.div`
  height: 407px;
  width: 100%;
  position: relative;
  background-color: ${color14};
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  .trusted__wrapper {
    max-width: 1440px;
    margin: auto;
    position: relative;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    height: 251px;

    .trusted__text {
      font-size: 14px;
      line-height: 20px;
      max-width: 285px;
      margin: auto;
      text-align: center;
    }

    ${H1} {
      font-size: 30px;
      line-height: 36px;
      padding-bottom: 6px;
      vertical-align: middle;
    }

    ${Title} {
      padding-top: 69px;
    }
  }
`;

export const RetailerIcon = styled.a`
  position: absolute;
  background: ${color1};
  box-shadow: 6.34146px 6.34146px 25.3659px rgba(0, 0, 0, 0.07);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ name }) => retailersSizePosition(name)}
  outline: none;
  text-decoration: none;
  overflow: hidden;

  .icon {
    display: block;
    max-width: calc(100% - 4px);
    max-height: calc(100% - 4px);
    width: 'auto';
    height: 'auto';
  }

  @media screen and (max-width: 1024px) {
    ${({ name }) => retailersSizePositionMobile(name)}
    .icon {
      display: block;
      width: calc(100% - ${({ name }) => (name === 'amazon' ? '16px' : '4px')});
      height: calc(
        100% - ${({ name }) => (name === 'amazon' ? '16px' : '4px')}
      );
    }
  }
`;
