import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LoginFormContainer } from '../loginForm/styled';
import { resetPassword } from '../../api/auth';
import { useValidation } from '../../hooks/validation';

const propTypes = {
  token: PropTypes.string,
};

const ResetPasswordForm = ({ token }) => {
  const {
    validate,
    validateAt,
    errors: validationErrors,
    isValid,
  } = useValidation('resetPassword');

  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    error: false,
    message: '',
  });

  const handleChange = useCallback(
    e => {
      const {
        target: { name, value },
      } = e;

      setState({ ...state, [name]: value });
    },
    [state, setState],
  );

  const handleBlur = useCallback(
    e => {
      const {
        target: { name },
      } = e;

      validateAt(name, state);
    },
    [validateAt, state],
  );

  const handleSubmit = async e => {
    e.preventDefault();

    if (await validate(state)) {
      const { password } = state;
      const res = await resetPassword(token, password);

      setState({
        ...state,
        message: res ? 'Password has been changed!' : false,
        error: !res,
      });
    }
  };

  const { message, error, password, confirmPassword } = state;

  return (
    <LoginFormContainer>
      {message ? (
        <div className="login-form__message-box">
          {message} Go to <a href="/login">login page</a>
        </div>
      ) : (
        <form
          name="reset-password-form"
          onSubmit={handleSubmit}
          className="login-form__from">
          <label
            htmlFor="login"
            className="login-form__label login-form__label_top">
            New password
            <input
              name="password"
              type="password"
              className={`login-form__input ${
                validationErrors.password ? 'login-form__input_error' : ''
              }`}
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!validationErrors.password && (
              <span className="login-form__input__text-error">
                {validationErrors.password}
              </span>
            )}
          </label>
          <label
            htmlFor="login"
            className="login-form__label login-form__label_bottom">
            Confirm password
            <input
              name="confirmPassword"
              type="password"
              className={`login-form__input ${
                validationErrors.confirmPassword
                  ? 'login-form__input_error'
                  : ''
              }`}
              value={confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {!!validationErrors.confirmPassword && (
              <span className="login-form__input__text-error">
                {validationErrors.confirmPassword}
              </span>
            )}
          </label>

          {error && (
            <div className="login-form__server-error">
              Something went wrong. Please visit{' '}
              <a href="/forgot-password">this page</a> to reset your password.
            </div>
          )}
          {isValid && (
            <div className="login-form__button-wrapper">
              <button className="login-form__button" type="submit">
                RESET PASSWORD
              </button>
            </div>
          )}
        </form>
      )}
    </LoginFormContainer>
  );
};

ResetPasswordForm.propTypes = propTypes;

export default ResetPasswordForm;
