import styled from 'styled-components';
import {
  color1,
  color4,
  color6,
  color10,
  color34,
  color39,
  color40,
  color41,
  TileButton,
} from '../../styled';

export const ItemHeader = styled.div`
  width: 100%;
  height: 65px;
  padding: 2px 0;
  border-bottom: 1px solid ${color10};
  height: 95px;
  padding: 2px 0;
  border-bottom: 1px dashed ${color39};
`;

export const ItemLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const ItemLogo = styled.img`
  max-width: 160px;
  max-height: 60px;
  object-fit: contain;
  object-position: center;
  display: inline-block;
`;

export const ItemMainContent = styled.div`
  height: 116px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px dashed ${color39};
  overflow: hidden;
  p {
    margin: 0;
    line-height: inherit !important;
  }
  h1 > h2 {
    color: inherit;
  }
`;

const resetH = `
  margin: 0;
  width: 100%;
  text-align: center;
`;

export const H1 = styled.h1`
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  padding-bottom: 6px;
  color: ${color40};
  ${resetH}
`;

export const H2 = styled.h2`
  font-weight: 600;
  font-size: 13.775px;
  line-height: 17px;
  color: ${color6};
  padding-bottom: 0px;
  ${resetH}
`;

export const H3 = styled.h3`
  font-weight: 500;
  font-size: 9.5px;
  line-height: 12px;
  color: ${color6};
  ${resetH}
`;

export const ItemFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .item-footer__expire-date {
    padding-top: 8px;
    text-align: center;
    width: 100%;
    color: ${color41};
    font-size: 10.45px;
    line-height: 13px;
    p {
      padding: 0;
      margin: 0;
    }
    .item-footer__expire-date__date {
      margin-top: 6px;
    }
  }

  .item-footer__expire-date__label {
    font-weight: 300;
  }

  .item-footer__expire-date__date {
    font-weight: 500;
  }
`;

export const ItemContainer = styled.div`
  width: 209px;
  height: 298px;
  position: relative;
  background-color: ${color1};
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(
    180deg,
    #ececec 1.53%,
    #ffffff 36.46%,
    #ffffff 64.83%,
    #ffffff 98.25%
  );
  box-shadow: 0px 19px 38px rgba(110, 116, 121, 0.2);
  border-radius: 11.4px;
  /* overflow: hidden; */
  :hover {
    background: #ffffff;
    border: 2px solid rgba(239, 113, 118, 0.502814);
    box-shadow: 0px 20px 40px rgba(110, 116, 121, 0.2);
    border-radius: 12px;
  }

  ${TileButton} {
    width: 100%;
    border-radius: 0px 0px 11.4px 11.4px;
    background: ${color39};
    border: none;
    position: absolute;
    height: 39.9px;
    bottom: 0;
    color: ${color1};
    z-index: 1;
  }

  .item__first-time-wrapper {
    position: absolute;
    top: -24px;
    left: -20px;
    z-index: 1;
    display: inline-block;
  }

  @media screen and (max-width: 1024px) {
    width: 166px;
    height: 217px;

    .item__first-time-wrapper {
      left: -17px;
      top: -18px;

      svg {
        width: 62px !important;
        height: auto;
      }
    }

    ${ItemHeader} {
      height: 58px;
    }

    ${ItemLogo} {
      max-height: 50px;
      max-width: 120px;
    }
    ${H1} {
      font-size: 29px !important;
      line-height: 35px;
      padding-bottom: 4px;
    }
    ${H2} {
      font-size: 11px;
      line-height: 13px;
      padding-bottom: 6px;
    }
    ${H3} {
      font-size: 9px;
      line-height: 11px;
    }

    ${ItemMainContent} {
      height: 90px;
    }

    ${ItemFooter} {
      height: 62px;
      .item-footer__expire-date {
        padding: 0px;
        font-size: 9.45px;
        line-height: 11px;
        .item-footer__expire-date__date {
          margin-top: 1px;
        }
      }
    }

    ${TileButton} {
      height: 29.2px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 7.12842px;
      line-height: 9px;
      color: ${color1};
      background-color: ${color4};
      :hover {
        background-color: ${color1};
        color: ${color4};
      }
      :focus {
        outline: none;
      }
    }
    ${ItemFooter} {
      height: 55px;
      padding-bottom: 18px;

      .item-footer__expire-date {
        padding-top: 10px;
      }

      :hover ${TileButton} {
        background-color: ${color1};
        color: ${color4};
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  margin: 61px 31px 0 0;
  cursor: pointer;
  :hover {
    ${ItemContainer} {
      transform: scale(1.08);
    }
    ${TileButton} {
      background-color: ${color34};
      bottom: -1px;
      width: 101%;
    }
  }
  :nth-child(4n) {
    margin-right: 0;
    /* :hover {
      margin-right: -5px;
      margin-left: -10px;
    } */
  }

  &.preview__tile {
    margin: 0 10px;
    margin-top: 25px;
    width: 250px;
  }

  @media screen and (max-width: 1280px) {
    :nth-child(4n) {
      margin: 61px 31px 0 0;
    }
    :nth-child(3n) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 18px 9px 0 0;
    :nth-child(4n) {
      margin: 18px 9px 0 0;
    }

    :nth-child(3n) {
      margin: 18px 9px 0 0;
    }

    :nth-child(2n) {
      margin-right: 0;
    }
  }
`;
