import React from 'react';

const FacebookIcon = ({ color }) => {
  const currentColor = color || '#ffffff';
  // "#EF7176"

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4999 1.5C34.0985 1.5 43.5 10.9027 43.5 22.5001C43.5 34.0987 34.0986 43.5 22.4999 43.5C10.9013 43.5 1.5 34.0986 1.5 22.5001C1.5 10.9027 10.9015 1.5 22.4999 1.5Z"
        stroke={currentColor}
        strokeWidth="3"
      />
      <path
        d="M26.4014 14.3305H30V9H25.7697V9.01922C20.644 9.20127 19.5935 12.0905 19.5009 15.125H19.4903V17.7868H16V23.007H19.4903V37H24.7504V23.007H29.0593L29.8917 17.7868H24.7522V16.1786C24.7522 15.1531 25.4327 14.3305 26.4014 14.3305Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default FacebookIcon;
