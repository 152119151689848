import React from 'react';

import { useExpand } from '../hooks';
import LearnMore from '../learnMore';

const Analytics = ({ handleScrollTo }) => {
  const [expand, setExpand] = useExpand({
    section1: false,
  });

  const { section1 } = expand;

  return (
    <div id="analytics" className="privacy-main-side__section">
      <h1 className="privacy-main-side__h1">
        Third-party analytics and interest-based advertising
      </h1>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">Analytics.</span>
          We partner with certain third parties to collect information for the
        purpose of engaging in analysis, auditing, research, and reporting on
        the Site and to understand the effectiveness of our advertising and
        email marketing.  These third parties may use web logs or web beacons,
        and they may set and access cookies or use similar tracking technologies
        on your computer or other device.  Among other providers, the Services
        use 
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          Google Analytics
        </a>
         to help collect and analyze certain information for the purposes
        discussed above.  You may opt out of the use of cookies by Google
        Analytics 
        <a
          href="https://tools.google.com/dlpage/gaoptout/"
          target="_blank"
          rel="noopener noreferrer">
          here
        </a>
        . 
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">
          Online interest-based advertising.
        </span>
          The Site may enable third parties to collect information through
        cookies, web beacons, and device identifiers, such as IDFA or
        Advertising ID, for use in online interest-based advertising.  For
        example, third parties may use the fact that you visited our website to
        target online ads for ShopGizmo to you on non-ShopGizmo websites.  In
        addition, we or our third-party advertising networks might use
        information about your use of the website to help target non-ShopGizmo
        advertisements to you on unaffiliated websites or mobile apps based on
        your online activity in general.  For information about behavioral
        advertising practices, including privacy and confidentiality, visit the
        Network Advertising Initiative 
        <a
          href="https://www.networkadvertising.org/understanding-online-advertising"
          target="_blank"
          rel="noopener noreferrer">
          website
        </a>
         or the Digital Advertising Alliance 
        <a
          href="https://digitaladvertisingalliance.org/"
          target="_blank"
          rel="noopener noreferrer">
          website
        </a>
        .
      </p>
      {section1 && (
        <>
          <p className="privacy-main-side__section-content">
            We and our third-party providers may use collected information to
            establish connections among related web browsers and devices (such
            as smartphones, tablets, and computers) for advertising, analytics,
            attribution, and reporting purposes. We may match your browsers or
            devices if you log into the same online service on multiple devices
            or if your devices share similar attributes that support an
            inference that they are used by the same person or household. This
            means that information about your activity on websites or apps on
            your current browser or device may be combined and used with
            information collected from your other browsers or devices. For
            example, we or our third-party providers may use this information to
            deliver the same ad on multiple devices, to limit the number of
            times you see an ad across your devices, and to help measure the
            effectiveness of advertising campaigns across devices.
          </p>
          <p className="privacy-main-side__section-content">
            You may opt out of our third-party service providers’ interest-based
            advertising practices and cross-device technologies in web browsers
            and mobile apps by following the instructions below. Please note
            that the opt-out will apply only to the specific browser or device
            from which you opt out, and therefore you will need to opt out
            separately on all of your browsers and devices. If you delete or
            reset your cookies or mobile identifiers, change browsers, or use a
            different device, any opt-out cookie or tool may no longer work and
            you will have to opt out again.
          </p>
          <p className="privacy-main-side__section-content">
            For web browsers you may remove yourself from the targeted
            advertising of companies within the Network Advertising Initiative
            by opting out{' '}
            <a
              href="https://optout.networkadvertising.org/?c=1#!%2F"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
             , or of companies participating in the Digital Advertising Alliance
            program by opting out 
            <a
              href="https://optout.aboutads.info/?c=2&lang=EN#!%2F"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
            .  For Canadian users, opt out 
            <a
              href="https://youradchoices.ca/en/tools"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
            , and for EU users, opt out 
            <a
              href="https://www.youronlinechoices.com/"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
            .
          </p>
          <p className="privacy-main-side__section-content">
            To opt out in mobile apps, please download and follow the
            instructions provided in the Digital Advertising Alliance’s
            AppChoices tool at 
            <a
              href="https://youradchoices.com/"
              target="_blank"
              rel="noopener noreferrer">
              www.aboutads.info/appchoices
            </a>
            . In addition, some mobile operating systems allow you to opt out by
            adjusting the advertising preferences on your mobile device.  For
            example:
          </p>
          <p className="privacy-main-side__section-content">
            <ul>
              <li>
                In iOS, visit Settings > Privacy > Advertising > Limit Ad
                Tracking.
              </li>
              <li>
                n Android, visit Settings > Google > Ads > Opt out of
                interest-based ads.
              </li>
            </ul>
          </p>
          <p className="privacy-main-side__section-content">
            The use of online tracking mechanisms by third parties is subject to
            those third parties’ own privacy policies, and not this Policy.  If
            you prefer to prevent third parties from setting and accessing
            cookies on your computer or other device, you also may set your
            browser to block cookies.  Our website currently does not respond to
            “Do Not Track” browser headers.  However, you can limit tracking
            through these third-party programs by taking the steps discussed
            above.
          </p>
        </>
      )}
      <LearnMore
        active={section1}
        handleChange={() => {
          setExpand('section1');

          if (section1) {
            handleScrollTo('analytics');
          }
        }}
      />
      <h3 className="privacy-main-side__h3">Security</h3>
      <p className="privacy-main-side__section-content">
        ShopGizmo uses commercially reasonable physical, electronic, and
        procedural safeguards to protect your personal information against loss
        or unauthorized access, use, modification, or deletion. However, no
        security program is foolproof, and thus we cannot guarantee the absolute
        security of your personal or other information.
      </p>
    </div>
  );
};

export default Analytics;
