import styled from 'styled-components';

import { color1, color4, color7, color25 } from '../colors';

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  width: ${({ small }) => (small ? '205px' : '250px')};
  height: ${({ small }) => (small ? '40px' : '55px')};
  background-color: ${({ tabbed }) => (tabbed ? color7 : color4)};
  border-radius: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ small }) => (small ? '15px' : '16px')};
  line-height: ${({ small }) => (small ? '18px' : '24px')};
  letter-spacing: -0.01em;
  padding: ${({ small }) =>
    small ? '11px 20px 10px 20px' : '16px 20px 15px 20px'};
  color: ${color1};
  border: none;
  cursor: pointer;
  :hover {
    background-color: ${color7};
  }
  :active {
    background-color: ${color25};
  }
  :focus {
    outline: none;
  }
`;

export const TileButton = styled.button`
  display: inline-block;
  text-align: center;
  border: 1px solid ${color4};
  box-sizing: border-box;
  border-radius: 23.1646px;
  width: 128.95px;
  height: 35.52px;
  padding: 12px 14px 11px 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10.038px;
  line-height: 12px;
  text-transform: capitalize;

  background-color: ${color1};
  color: ${color4};
  cursor: pointer;
  :hover {
    color: ${color1};
    background-color: ${color4};
  }
  :active {
    background-color: ${color25};
  }
  :focus {
    outline: none;
  }
`;
