import React, { useEffect, useRef } from 'react';
import { DeleteItemIcon } from '../../../components/icons';
import { ProfilePagePaymentWrapper } from './styled';

const Payment = ({ id, isMobile }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3 profile-page__h3_hidden_mobile">
        Payment Methods
      </h3>
      <ProfilePagePaymentWrapper>
        <div className="profile-page__payment">
          <div className="profile-page__payment__item">
            <div className="profile-page__content-main__card-item">
              <span className="profile-page__content-main__card-item__icon__wrapper">
                <img src="/img/creditcard.svg" alt="card icon" />
              </span>
              NO AVAILABLE CARDS
            </div>
            <span className="profile-page__payment__delete">
              <DeleteItemIcon />
            </span>
          </div>
        </div>
      </ProfilePagePaymentWrapper>
    </div>
  );
};

export default Payment;
