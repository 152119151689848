import React, { useEffect, useRef } from 'react';
import { ProfilePageCardsWrapper } from './styled';

const Cards = ({ isMobile }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3 profile-page__h3_hidden_mobile">
        My Cards
      </h3>
      <ProfilePageCardsWrapper>
        <div className="profile-page__cards-table">
          <div className="profile-page__cards-table__row profile-page__cards-table__row_header">
            <div className="profile-page__cards-table__cel">Orders</div>
            <div className="profile-page__cards-table__cel">Qty</div>
            <div className="profile-page__cards-table__cel">Date</div>
            <div className="profile-page__cards-table__cel">Price</div>
            <div className="profile-page__cards-table__cel profile-page__cards-table__cel_long">
              {!isMobile && 'Gift'}
              Card Number
            </div>
          </div>
          <div className="profile-page__cards-table__row">
            <div className="profile-page__cards-table__cel">none</div>
            <div className="profile-page__cards-table__cel">none</div>
            <div className="profile-page__cards-table__cel">none</div>
            <div className="profile-page__cards-table__cel">none</div>
            <div className="profile-page__cards-table__cel profile-page__cards-table__cel_bold profile-page__cards-table__cel_long">
              none
            </div>
          </div>
        </div>
      </ProfilePageCardsWrapper>
    </div>
  );
};

export default Cards;
