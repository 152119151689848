import styled from 'styled-components';
import {
  color1,
  color11,
  color18,
  color48,
  color58,
} from '../../../shareComponent/styled';

export const CreateUserPageWrapper = styled.div`
  width: 100%;
  padding-top: 39px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: top;
  * {
    box-sizing: border-box;
  }
  .create-user__form {
    display: flex;
    flex-flow: row wrap;
    width: 472px;
  }
  .create-user__form__error {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: ${color18};
  }

  .create-user__input,
  .create-user__input__label {
    display: inline-block;
    width: 100%;
  }

  .create-user__input {
    padding-top: 23px;
  }

  .create-user__input_small {
    width: 220px;
    margin-right: 32px;
  }

  .create-user__input_small_last {
    margin-right: 0px;
  }

  .create-user__input__label {
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
  }

  .create-user__input__text {
    display: inline-block;
    margin-top: 12px;
    width: 100%;
    background: ${color1};
    border: 1px solid ${color58};
    border-radius: 5px;
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
    padding: 16px 14px;
  }

  .create-user__input__text_error {
    border-color: ${color18};
  }

  .create-user__input-error {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    color: ${color18};
  }

  .create-user__button-wrapper {
    padding-top: 43px;
  }

  .create-user__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 55px;
    background: ${color48};
    border-radius: 5px;
    color: ${color1};
    font-weight: 500;
    font-size: 17px;
    line-height: 96.5%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    padding: 16px 16px 93px 16px;

    .create-user__form {
      width: 100%;
      max-width: 480px;
      margin: auto;
    }

    .create-user__input_small {
      width: 100%;
      margin: auto;
    }

    .create-user__button-wrapper {
      margin: auto;
    }
  }
`;
