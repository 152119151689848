import states from './states';

export const parseUrlQuery = query => {
  const q = new URLSearchParams(query);
  const obj = {};
  const store = q.get('store');
  const sort = q.get('sort');
  const order = q.get('order');
  const limit = q.get('limit');
  const page = q.get('page');
  const per_page = q.get('per_page');
  const is_first_time_use_only = q.get('is_first_time_use_only');

  if (store) {
    obj.store = store.split(',').filter(item => item);
  }

  if (sort && order) {
    obj.sort = {
      [sort]: order,
    };
  } else if (sort) {
    obj.sort = {
      [sort]: 'ASC',
    };
  }

  if (limit) {
    obj.limit = limit;
  }

  if (page) {
    obj.page = page;
  }

  if (per_page) {
    obj.perPage = per_page;
  }

  if (is_first_time_use_only) {
    obj.is_first_time_use_only = is_first_time_use_only === 'true';
  }

  return obj;
};

export const objectToUrlQuery = obj => {
  if (obj.sort) {
    const [sort, order] = Object.entries(obj.sort)[0];
    obj.sort = sort;
    obj.order = order;
  }

  return new URLSearchParams(obj).toString().replace('perPage', 'per_page');
};

export { states };
