import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { VideoWrapper } from './styled';

const propTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  handleChangeStep: PropTypes.func,
  sourceUrl: PropTypes.string,
  type: PropTypes.string,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number,
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  ),
};

const Video = ({
  handleChangeStep,
  currentStep,
  sourceUrl,
  breakpoints,
  type,
}) => {
  const videoRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const [pause, setPause] = useState(true);

  const onClick = () => {
    setPause(!pause);
  };

  useEffect(() => {
    if (videoRef) {
      const { current } = videoRef;

      if (currentStep || currentStep === 0) {
        setPause(true);
        current.pause();

        if (currentStep === 0) {
          current.currentTime = breakpoints[0].breakpoint;
        }

        if (currentStep === 1) {
          current.currentTime = breakpoints[1].breakpoint;
        }

        if (currentStep === 2) {
          current.currentTime = breakpoints[2].breakpoint;
        }

        setPause(false);
        current.play();
      }
    }
  }, [currentStep, breakpoints]);

  useEffect(() => {
    if (videoRef) {
      const { current } = videoRef;

      const onTimeUpdate = () => {
        console.log(current && current.currentTime);
        if (
          current.currentTime >= breakpoints[0].start &&
          current.currentTime <= breakpoints[0].end
        ) {
          if (handleChangeStep) {
            handleChangeStep(0);
          }
        }

        if (
          current.currentTime >= breakpoints[1].start &&
          current.currentTime <= breakpoints[1].end
        ) {
          if (handleChangeStep) {
            handleChangeStep(1);
          }
        }

        if (
          current.currentTime >= breakpoints[2].start &&
          current.currentTime <= breakpoints[2].end
        ) {
          if (handleChangeStep) {
            handleChangeStep(2);
          }
        }
      };

      current.addEventListener('timeupdate', onTimeUpdate);

      if (pause && current) {
        setTimeout(() => {
          current.pause();
        }, 0);
      } else if (current) {
        setTimeout(() => {
          current.play();
        }, 0);
      }

      return () => {
        current.removeEventListener('timeupdate', onTimeUpdate);
      };
    }
  }, [videoRef, videoWrapperRef, pause, handleChangeStep, breakpoints]);

  useEffect(() => {
    if (videoWrapperRef) {
      const { current: currentWrapper } = videoWrapperRef;

      const onScroll = () => {
        const { top } = currentWrapper.getBoundingClientRect();
        if (top >= 0 && top <= 350) {
          if (pause) {
            setPause(false);
          }
        } else if (!pause) {
          setPause(true);
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [pause, videoWrapperRef]);

  return (
    <VideoWrapper onClick={onClick} ref={videoWrapperRef} type={type}>
      <video
        muted
        ref={videoRef}
        type="video/mp4"
        autoPlay
        loop
        className="video"
        poster="/img/video-poster.png">
        <source src={sourceUrl || `/video/ShopGizmo_1260x900_full.mp4`} />
      </video>
    </VideoWrapper>
  );
};

Video.propTypes = propTypes;

export default Video;
