import styled from 'styled-components';

import {
  EmailWrapper,
  EmailContainer,
  EmailButton,
  EmailInput,
} from '../../../shareComponent/recipe/emailForm/styled';

export const RecipeEmailWrapper = styled.div`
  display: flex;
  width: 463px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 71px;
  .recipe-email__text {
    text-align: center;
    color: #000000;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    padding-bottom: 42px;
    max-width: 323px;
  }
  ${EmailWrapper} {
    width: 385px;
    height: 50px;
  }

  ${EmailContainer} {
    width: 100%;
    height: 50px;
    border: 1px solid #C9C9C9;
    border-radius: 8px;
  }

  ${EmailButton} {
    flex: 0 0 100px;
    border-radius: 0;
  }

  ${EmailInput} {
    border: none;
  }

  @media screen and (max-width: 1024px) {
    width: calc(100% - 16px);
    max-width: 463px;
    margin: auto;

    ${EmailWrapper} {
      width: calc(100% - 16px);
      max-width: 385px;
    }
  }
`;
