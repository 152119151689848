import styled from 'styled-components';

export const ProfilePagePaymentWrapper = styled.div`
  width: 100%;
  .profile-page__payment {
    width: 349px;
    padding-top: 34px;
  }

  .profile-page__payment__item {
    width: 100%;
    position: relative;
  }

  .profile-page__payment__delete {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    right: -40px;
    top: 18px;
    cursor: pointer;
    svg {
      display: inline-block;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    .profile-page__payment {
      width: 100%;
      padding-top: 38px;
      padding-bottom: 25px;
    }
  }
`;
