import * as header from './header';
import * as button from './button';
import * as colors from './colors';
import * as logo from './logo';
import * as menu from './menu';
import * as pageTitle from './pageTitle';

export const {
  color1,
  color2,
  color3,
  color4,
  color5,
  color6,
  color7,
  color8,
  color9,
  color10,
  color11,
  color12,
  color13,
  color14,
  color15,
  color16,
  color17,
  color18,
  color19,
  color20,
  color21,
  color22,
  color23,
  color24,
  color25,
  color26,
  color27,
  color28,
  color29,
  color30,
  color31,
  color32,
  color33,
  color34,
  color35,
  color36,
  color37,
  color38,
  color39,
  color40,
  color41,
  color42,
  color43,
  color44,
  color45,
  color46,
  color47,
  color48,
  color49,
  color50,
  color51,
  color52,
  color53,
  color54,
  color55,
  color56,
  color57,
  color58,
  color59,
  color60,
  color61,
  color62,
  color63,
  color64,
  color65,
} = colors;
export const { Header, HeaderContainer } = header;
export const { Button, TileButton } = button;
export const { LogoPanel, LogoUpText, LogoDownText, LogoImage } = logo;
export const {
  Menu,
  MenuItem,
  NavMenu,
  NavMenuItem,
  ButtonContainer,
  MenuButton,
  MenuButtonContainer,
  MenuButtonWrapper,
} = menu;

export const { PageTitleContainer } = pageTitle;
