import React from 'react';
import { ScrollUp } from '../icons';
import { ScrollUpButtonWrapper } from './styled';

const ScrollUpButton = () => {
  const handleScrollUp = () => {
    const el = document.querySelector('body');

    el.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ScrollUpButtonWrapper
      onClick={() => {
        handleScrollUp();
      }}>
      <ScrollUp />
    </ScrollUpButtonWrapper>
  );
};

export default ScrollUpButton;
