import React from 'react';

const CopyIcon = ({ color }) => {
  const currentColor = color || '#ffffff';

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="22.5"
        cy="22.5"
        r="21"
        stroke={currentColor}
        strokeWidth="3"
      />
      <path
        d="M25.2518 19.7442C24.9556 19.4463 24.6273 19.1822 24.2728 18.9568C23.3339 18.3556 22.2424 18.0361 21.1275 18.0361C19.5801 18.0333 18.0956 18.6481 17.0033 19.7442L11.7043 25.0473C10.6139 26.1399 10.001 27.6201 10 29.1637C9.99795 32.3848 12.6074 34.9976 15.8285 34.9997C17.3735 35.005 18.8567 34.3931 19.9486 33.3L24.3228 28.9258C24.4023 28.847 24.4467 28.7395 24.446 28.6276C24.4447 28.3975 24.2571 28.2121 24.027 28.2134H23.8604C22.9465 28.2166 22.0407 28.0411 21.1942 27.6969C21.0382 27.6327 20.859 27.6689 20.7402 27.7885L17.5949 30.9379C16.6182 31.9146 15.0347 31.9146 14.058 30.9379C13.0814 29.9612 13.0814 28.3777 14.058 27.4011L19.3779 22.0854C20.3538 21.1107 21.9347 21.1107 22.9106 22.0854C23.5683 22.7044 24.5942 22.7044 25.2518 22.0854C25.5348 21.8022 25.7063 21.4266 25.7351 21.0272C25.7655 20.55 25.5895 20.0827 25.2518 19.7442Z"
        fill={currentColor}
      />
      <path
        d="M33.2884 11.7083C31.0106 9.43056 27.3177 9.43056 25.0399 11.7083L20.6699 16.0742C20.5499 16.1947 20.5153 16.3761 20.5824 16.5324C20.6487 16.6892 20.8038 16.7898 20.974 16.7865H21.1281C22.0409 16.7849 22.9452 16.9617 23.7901 17.3073C23.9461 17.3715 24.1253 17.3353 24.2442 17.2156L27.3811 14.0829C28.3578 13.1062 29.9413 13.1062 30.9179 14.0829C31.8946 15.0596 31.8946 16.643 30.9179 17.6197L27.0103 21.5232L26.977 21.5606L25.6106 22.9187C24.6347 23.8934 23.0538 23.8934 22.0779 22.9187C21.4202 22.2998 20.3943 22.2998 19.7366 22.9187C19.4519 23.2041 19.2802 23.583 19.2534 23.9852C19.2229 24.4624 19.3989 24.9298 19.7366 25.2683C20.2188 25.7525 20.783 26.1474 21.403 26.4347C21.4905 26.4764 21.578 26.5097 21.6654 26.5472C21.7529 26.5847 21.8446 26.6139 21.932 26.6472C22.0195 26.6805 22.1112 26.7097 22.1986 26.7347L22.4445 26.8013C22.6111 26.843 22.7777 26.8763 22.9485 26.9055C23.1542 26.936 23.3614 26.9555 23.5692 26.9638H23.8858L24.1358 26.9347C24.2274 26.9305 24.3233 26.9097 24.4316 26.9097H24.5732L24.8607 26.868L24.994 26.843L25.2356 26.793H25.2814C26.3047 26.536 27.2391 26.0062 27.9851 25.26L33.2882 19.9568C35.5661 17.679 35.5661 13.9861 33.2884 11.7083Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default CopyIcon;
