import styled from 'styled-components';

export const ProfilePagePasswordFormWrapper = styled.div`
  .profile-page__password-form {
    padding-top: 26px;
    width: 349px;
    position: relative;
  }
  .profile-page__password-form__button-wrapper {
    padding-top: 29px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-bottom: 67px;
    .profile-page__password-form {
      padding-top: 26px;
      width: 100%;
    }

    .profile-page__password-form__button-wrapper {
      text-align: center;
    }
  }
`;
