export const retailersSizePosition = name => {
  if (name === 'amazon') {
    return `
      top: -24px;
      left: 191px;
      width: 104px;
      height: 104px;
    `;
  }

  if (name === 's' || name === 'chase') {
    return `
      width: 81px;
      height: 81px;
      top: 66px;
      left: 52px;
    `;
  }

  if (name === 'sephora' || name === 'mastercard') {
    return `
      bottom: 35px;
      left: 83px;
      width: 71px;
      height: 71px;
    `;
  }

  if (name === 'bloomingdales' || name === 'discover') {
    return `
      top: -50px;
      right: 81px;
      height: 100px;
      width: 100px;
    `;
  }

  if (name === 'jcp' || name === 'deltaskymiles') {
    return `
      top: 8px;
      right: 237px;
      width: 106px;
      height: 106px;
    `;
  }

  if (name === 'kmart' || name === 'amex') {
    return `
      width: 116px;
      height: 116px;
      left: 307px;
      bottom: 12px;
    `;
  }

  if (name === 'target' || name === 'capitalone') {
    return `
      bottom: -18px;
      right: 290px;
      width: 94px;
      height: 94px;
    `;
  }

  if (name === 'sfa' || name === 'boa') {
    return `
      top: 108px;
      right: 54px;
      width: 77px;
      height: 77px;
    `;
  }

  if (name === 'dom' || name === 'visa') {
    return `
      bottom: 135px;
      left: 164px;
      width: 78px;
      height: 78px;
    `;
  }

  if (name === 'macys') {
    return `
      top: 210px;
      right: 148px;
      width: 74px;
      height: 74px;
    `;
  }

  if (name === 'ego') {
    return `
      bottom: 44px;
      right: 57px;
      width: 79px;
      height: 79px;
    `;
  }

  return `
    top: 0;
    left: 0;
  `;
};

export const retailersSizePositionMobile = name => {
  if (name === 'amazon') {
    return `
      top: -9px;
      left: 36px;
      width: 67px;
      height: 67px;
    `;
  }

  if (name === 's' || name === 'chase') {
    return `
      width: 81px;
      height: 81px;
      top: 66px;
      left: 52px;
      display: none;
    `;
  }

  if (name === 'sephora' || name === 'mastercard') {
    return `
      bottom: -10px;
      left: auto;
      right: 22px;
      width: 71px;
      height: 71px;
    `;
  }

  if (name === 'bloomingdales' || name === 'discover') {
    return `
      top: auto;
      left: 16px;
      bottom: -18px;
      height: 78px;
      width: 79px;
    `;
  }

  if (name === 'jcp' || name === 'deltaskymiles') {
    return `
      top: -29px;
      left: auto;
      right: 24px;
      width: 86px;
      height: 86px;
    `;
  }

  if (name === 'kmart' || name === 'amex') {
    return `
      width: 47px;
      height: 47px;
      right: 0px;
      left: 60px;
      bottom: -23px;
      margin: auto;
    `;
  }

  if (name === 'target' || name === 'capitalone') {
    return `
      bottom: -18px;
      left: 1056px;
      width: 94px;
      height: 94px;
    `;
  }

  if (name === 'sfa' || name === 'boa') {
    return `
      top: 108px;
      left: 1309px;
      width: 77px;
      height: 77px;
      display: none;
    `;
  }

  if (name === 'dom' || name === 'visa') {
    return `
      bottom: 135px;
      left: 164px;
      width: 78px;
      height: 78px;
      display: none;
    `;
  }

  if (name === 'macys') {
    return `
      top: 210px;
      left: 1218px;
      width: 74px;
      height: 74px;
      display: none;
    `;
  }

  if (name === 'ego') {
    return `
      bottom: 44px;
      left: 1315px;
      width: 79px;
      height: 79px;
      display: none;
    `;
  }

  return `
    top: 0;
    left: 0;
  `;
};
