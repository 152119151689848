import styled from 'styled-components';
import { color1, color20, color21 } from '../../../shareComponent/styled';

export const MobileMenuContainer = styled.div`
  width: 100%;
  background-color: ${color20};
  display: ${({ show }) => (show ? 'block' : 'none')};
  box-sizing: border-box;
  padding: 6px 16px;

  .mobile-menu__item {
    width: 100%;
    display: flex;
    padding: 8px 21px;
    color: ${color1};
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    cursor: pointer;
    border-bottom: 1px solid ${color21};
    outline: none;
    :focus {
      outline: none;
    }

    :last-child {
      border-bottom: none;
      padding: 13px 16px;
    }
  }

  .mobile-menu__item__icon {
    margin-right: 16px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${color1};
    border-radius: 50%;
  }
`;
