import React from 'react';

import {
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';
import Footer from '../../components/footer';
import { FAQWrapper } from '../faq/styled';

import { useLink } from '../../hooks';
import Video from './video';

const FAQCardgizmo = () => {
  const linkTo = useLink();

  return (
    <>
      <RecipePageContainer>
        <Header activeMenu="faq" />
        <PageTitleContainer>FAQ</PageTitleContainer>
        <Container>
          <NavMenu>
            <NavMenuItem
              onClick={e => {
                e.preventDefault();
                linkTo('/');
              }}
              href="/">
              Home
            </NavMenuItem>{' '}
            / <NavMenuItem active>FAQ</NavMenuItem>
          </NavMenu>
          <FAQWrapper>
            <h1 className="FAQ__page-title">FAQ</h1>
            <div>
              <h4 className="FAQ__title">How do I get started? </h4>
              <div className="FAQ__text">
                <div>
                  <a
                    className="FAQ__link"
                    href="https://chrome.google.com/webstore/detail/shopgizmocard/dkgmjlofcbeaaoiaojnndcbmmfnfkfdm?hl=en"
                    target="blank">
                    Click here.
                  </a>
                </div>
                <br />
                <div>Install the extension:</div>
                <br />
                <div>
                  Click the puzzle piece on the top right of your browser and
                  click the safety pin next to the shopgizmo icon
                </div>
                <Video start={3.5} end={13.5} />
              </div>
            </div>
            <div>
              <h4 className="FAQ__title">Does it cost any money?</h4>
              <div className="FAQ__text">
                No, it’s completely free and we don’t sell your data.
              </div>
            </div>
            <div>
              <h4 className="FAQ__title">
                How do I update my profile with which cards I have?
              </h4>
              <div className="FAQ__text">
                <div>
                  <a className="FAQ__link" href="/login" target="blank">
                    Register or sign in
                  </a>{' '}
                  :
                </div>
                <div className="faq-cardgizmo__screenshot-wrapper">
                  <img
                    className="faq-cardgizmo__screenshot"
                    src="/img/screen_click_login.png"
                    alt="click login"
                  />
                </div>
                <div>Go to your profile:</div>
                <div className="faq-cardgizmo__screenshot-wrapper">
                  <img
                    className="faq-cardgizmo__screenshot"
                    src="/img/Profile_Click.png"
                    alt="click profile"
                  />
                </div>
                <div>Select and add from our list of cards.</div>
                <div className="faq-cardgizmo__screenshot-wrapper">
                  <img
                    className="faq-cardgizmo__screenshot"
                    src="/img/Select_Card.png"
                    alt="click card"
                  />
                </div>
                <div>
                  Drop us a line at:{' '}
                  <a className="FAQ__link" href="mailto:hello@shopgizmo.co">
                    hello@shopgizmo.co
                  </a>{' '}
                  if your card isn’t listed and we’ll add it!
                </div>
              </div>
            </div>
          </FAQWrapper>
        </Container>
      </RecipePageContainer>
      <Footer />
    </>
  );
};

export default FAQCardgizmo;
