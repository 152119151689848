import React, { useState, useCallback, useEffect, useRef } from 'react';

import { ProfilePagePasswordFormWrapper } from './styled';
import { useValidation, useUpdateUserPassword } from '../../../hooks';
import Toast from '../../../shareComponent/recipe/toast';

const Password = ({ isMobile }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);

  const {
    validate,
    validateAt,
    errors: validationErrors,
    // isValid,
  } = useValidation('passwordChange');

  const { isUpdated, updateUserPassword, reset } = useUpdateUserPassword();

  const [currentState, setCurrentState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { oldPassword, newPassword, confirmPassword } = currentState;

  const handleChange = useCallback(
    e => {
      const {
        target: { name, value },
      } = e;

      setCurrentState({ ...currentState, [name]: value });
    },
    [currentState, setCurrentState],
  );

  const handleBlur = useCallback(
    e => {
      const {
        target: { name },
      } = e;

      validateAt(name, currentState);
    },
    [validateAt, currentState],
  );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (await validate(currentState)) {
        await updateUserPassword({ oldPassword, newPassword });
      }
    },
    [currentState, newPassword, oldPassword, validate, updateUserPassword],
  );

  const success = isUpdated.error
    ? `Password is not changed!`
    : `Password changed successfully!`;

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3 profile-page__h3_hidden_mobile">
        Change password
      </h3>
      <ProfilePagePasswordFormWrapper>
        <form onSubmit={handleSubmit} className="profile-page__password-form">
          <div className="profile-page__input profile-page__password-form__input">
            <label htmlFor="firstName" className="profile-page__input__label">
              Your Password
              <input
                type="password"
                name="oldPassword"
                className={`profile-page__input__text ${
                  validationErrors.oldPassword
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={oldPassword}
                placeholder="***"
              />
            </label>
            {!!validationErrors.oldPassword && (
              <span className="profile-page__input__text-error">
                {validationErrors.oldPassword}
              </span>
            )}
          </div>
          <div className="profile-page__input profile-page__password-form__input">
            <label htmlFor="lastName" className="profile-page__input__label">
              New Password
              <input
                type="password"
                name="newPassword"
                className={`profile-page__input__text ${
                  validationErrors.newPassword
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={newPassword}
                placeholder="***"
              />
            </label>
            {!!validationErrors.newPassword && (
              <span className="profile-page__input__text-error">
                {validationErrors.newPassword}
              </span>
            )}
          </div>
          <div className="profile-page__input profile-page__password-form__input">
            <label htmlFor="sex" className="profile-page__input__label">
              Confirm Password
              <input
                type="password"
                name="confirmPassword"
                className={`profile-page__input__text ${
                  validationErrors.confirmPassword
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={confirmPassword}
                placeholder="***"
              />
            </label>
            {!!validationErrors.confirmPassword && (
              <span className="profile-page__input__text-error">
                {validationErrors.confirmPassword}
              </span>
            )}
          </div>
          <div className="profile-page__password-form__button-wrapper">
            <button className="profile-page__button" type="submit">
              SAVE
            </button>
          </div>
          <Toast show={!!isUpdated.id} hideToast={() => reset()}>
            {success}
          </Toast>
        </form>
      </ProfilePagePasswordFormWrapper>
    </div>
  );
};

export default Password;
