import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { H1 } from '../../styled';
import { StepPreviewPanel, Steps, Step, Header, StepText } from './styled';

const propTypes = {
  handleChange: PropTypes.func,
  current: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, content: PropTypes.string }),
  ),
};

const StepPreview = ({ current, handleChange, steps }) => {
  const [active, setActive] = useState(current);

  useEffect(() => {
    setActive(current);
  }, [current]);

  return (
    <StepPreviewPanel>
      <H1>How it works</H1>
      <Steps>
        {steps.map((item, index) => (
          <Step
            key={index}
            active={index === active}
            onClick={() => {
              handleChange(index);
            }}>
            <Header>
              {index + 1}. {item.label}
            </Header>
            <StepText>{item.content}</StepText>
          </Step>
        ))}
      </Steps>
    </StepPreviewPanel>
  );
};

StepPreview.propTypes = propTypes;

export default StepPreview;
