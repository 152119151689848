import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import ShareButton from '../shareButton';
import { SharePanelContainer } from './styled';

const propTypes = {
  show: PropTypes.bool,
  date: PropTypes.string,
  savings: PropTypes.string,
  store: PropTypes.string,
};

const SharePanel = ({ show, date, savings, store, color }) => {
  return (
    <Transition in={show} timeout={200}>
      {state => (
        <SharePanelContainer state={state}>
          <ShareButton
            type="facebook"
            date={date}
            savings={savings}
            store={store}
            color={color}
          />
          <ShareButton
            type="twitter"
            date={date}
            savings={savings}
            store={store}
            color={color}
          />
          <ShareButton type="copy" color={color} />
        </SharePanelContainer>
      )}
    </Transition>
  );
};

SharePanel.propTypes = propTypes;

export default SharePanel;
