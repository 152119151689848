import styled from 'styled-components';
import {
  color1,
  color10,
  color11,
  color58,
} from '../../../../shareComponent/styled';

export const ProfilePageCardsWrapper = styled.div`
  padding-top: 19px;
  width: 100%;

  .profile-page__cards-table {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .profile-page__cards-table__row {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid ${color58};
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: ${color11};
    background-color: ${color1};
  }

  .profile-page__cards-table__row_header {
    background-color: ${color10};
    border-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
  }

  .profile-page__cards-table__cel {
    width: calc(100% / 5);
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    align-content: center;
    justify-content: center;
  }

  .profile-page__cards-table__cel_bold {
    font-weight: bold;
    font-size: 15.7203px;
    line-height: 19px;
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 0px;
    .profile-page__cards-table {
      padding-bottom: 51px;
    }
    .profile-page__cards-table__row {
      :last-child {
        border-bottom: 0px;
      }
    }
    .profile-page__cards-table__cel {
      width: 60px;
    }

    .profile-page__cards-table__cel_long {
      width: 100px;
    }
  }
`;
