import axios from 'axios';
import { getToken } from './auth/token';

export const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getToken()}`;

  return config;
});

export default instance;
