export const isClient = () => typeof window === 'object';

export const formatDecimalNumber = val =>
  Number(val).toFixed(2).replace('.00', '');

export const formatTotalSavings = ({
  totalQuantity,
  totalQuantityUSD,
  totalQuantityPoints,
  pts,
}) =>
  (!!totalQuantity ? `${totalQuantity}%` : '') +
  (!!totalQuantityUSD
    ? ` ${totalQuantity ? ' + ' : ''}$${formatDecimalNumber(totalQuantityUSD)}`
    : '') +
  (pts
    ? Number(totalQuantityPoints)
      ? ` + ${totalQuantityPoints} pts`
      : ` + pts`
    : '');
