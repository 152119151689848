import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MobileMenuContainer } from './styled';
import { FacebookMobileMenuIcon, InstagramMobileMenuIcon } from '../icons';
import { useLink } from '../../hooks';

const propTypes = {
  menuItems: PropTypes.shape({
    label: PropTypes.string,
    click: PropTypes.func,
  }),
  show: PropTypes.bool,
  closeMenu: PropTypes.func,
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

const MobileMenu = ({ menuItems, show, closeMenu, profile }) => {
  const linkTo = useLink();

  const navMenuClick = id => {
    if (id === 'main') {
      linkTo('/');
    } else if (id === 'blog') {
      window.open('https://community.shopgizmo.co/', '_blank');
    } else if (id === 'forum') {
      window.open(
        'https://community.shopgizmo.co/forums/forum/all-things-saving-money/',
        '_blank',
      );
    } else {
      linkTo(`/${id}`);
    }
  };

  const defItems = [
    {
      label: 'About Us',
      click: () => {
        navMenuClick('about');
      },
    },
    {
      label: 'FAQ',
      click: () => {
        navMenuClick('faq');
      },
    },
    {
      label: profile ? `${profile.firstName}` : 'Login / Register',
      click: () => {
        navMenuClick(profile ? 'profile' : 'login');
      },
    },
    {
      label: 'Blog',
      click: () => {
        navMenuClick('blog');
      },
    },
    {
      label: 'Live Deals',
      click: () => {
        navMenuClick('deals');
      },
    },
    /* {
      label: 'Forum',
      click: () => {
        navMenuClick('forum');
      },
    }, */
    /* {
      label: 'Contact Us',
      click: () => {
        window.location.href = 'mailto:hello@shopgizmo.co';
      },
    }, */
    {
      label: 'Privacy Policy',
      click: () => navMenuClick('privacy'),
    },
    {
      label: 'Terms of Use',
      click: () => navMenuClick('terms'),
    },
  ];

  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      const outsideClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
          //TODO fix bug on button menu click
          //closeMenu();
        }
      };

      document.body.addEventListener('click', outsideClick);

      return () => {
        document.body.removeEventListener('click', outsideClick);
      };
    }
  }, [ref, closeMenu, show]);

  return (
    <MobileMenuContainer ref={ref} show={show}>
      {((menuItems && menuItems.length > 0) || defItems).map(
        ({ label, click }, index) => (
          <div
            key={index}
            role="link"
            className="mobile-menu__item"
            onClick={click}
            onKeyDown={() => {}}
            tabIndex="0">
            {label}
          </div>
        ),
      )}
      <div className="mobile-menu__item">
        <div
          tabIndex="-3"
          role="button"
          className="mobile-menu__item__icon"
          onKeyUp={() => {}}
          onClick={() => {
            window.open('https://facebook.com/shopgizmo', '_blank', false);
          }}>
          <FacebookMobileMenuIcon />
        </div>
        <div
          tabIndex="-2"
          role="button"
          className="mobile-menu__item__icon"
          onKeyUp={() => {}}
          onClick={() => {
            window.open('https://instagram.com/shopgizmo', '_blank', false);
          }}>
          <InstagramMobileMenuIcon />
        </div>
      </div>
    </MobileMenuContainer>
  );
};

MobileMenu.propTypes = propTypes;

export default MobileMenu;
