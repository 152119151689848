import React from 'react';
import styled from 'styled-components/macro';
import SavingsIcon from './SavingsIcon';
import FreeShippingIcon from './FreeShippingIcon';
import RewardPointsIcon from './RewardPointsIcon';
import FacebookIcon from './FacebookIcon';
import TwitterIcon from './TwitterIcon';
import CopyIcon from './CopyIcon';
import CongratulationIcon from './CongratulationIcon';
import DollarIcon from './DollarIcon';
import FirstTimeIcon from './FirstTimeIcon';
import PercentIcon from './PercentIcon';

const DropdownArrow = props => (
  <svg
    {...props}
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.5L5 5.5L8.5 1.5"
      stroke="#B4B3B3"
      strokeWidth="2.18182"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Facebook = () => (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.17432 3.23563H10V0.13722C9.68503 0.0949985 8.60178 0 7.34022 0C4.70794 0 2.90476 1.61335 2.90476 4.5786V7.30758H0V10.7714H2.90476V19.4869H6.46613V10.7722H9.2534L9.69586 7.30839H6.4653V4.92206C6.46613 3.92092 6.74277 3.23563 8.17432 3.23563Z"
      fill="#4469B0"
    />
  </svg>
);

export const Tweeter = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1427 3.34973C17.315 3.14906 17.099 2.86137 16.8456 2.93723C16.5712 3.01936 16.3752 2.59921 16.5566 2.37757C16.646 2.26826 16.7292 2.15359 16.8054 2.03404C16.98 1.76029 16.669 1.48823 16.3707 1.61646C16.0322 1.762 15.6806 1.88215 15.318 1.97621C15.0586 2.0435 14.7904 1.94889 14.5851 1.77661C13.9502 1.24365 13.1307 0.924805 12.2486 0.924805C10.2405 0.924805 8.6239 2.55454 8.6239 4.55246C8.6239 4.97103 8.29346 5.3595 7.87839 5.30552C5.8709 5.04448 4.04734 4.20422 2.57487 2.95609C1.86761 2.35659 0.735337 2.49202 0.735337 3.41917C0.735337 4.26202 1.02663 5.04064 1.50964 5.65648C1.71675 5.92055 1.54054 6.33513 1.22505 6.22073C1.05741 6.15994 0.895829 6.08757 0.742659 6.00549C0.726882 5.99703 0.707693 6.0084 0.707693 6.0263C0.707693 7.44838 1.52626 8.6761 2.70854 9.27439C2.92402 9.38343 2.90419 9.70925 2.66269 9.70925C2.35748 9.70925 2.1038 10.0127 2.25143 10.2798C2.42038 10.5856 2.63162 10.865 2.87728 11.1098C3.53583 11.7662 3.80513 13.1705 2.91232 13.4302C2.2634 13.619 1.57701 13.7206 0.86803 13.7206C0.658122 13.7206 0.564932 14.0123 0.750382 14.1107C2.1872 14.8725 3.82198 15.2983 5.56424 15.2983C12.2387 15.2983 15.8877 9.77006 15.8877 4.97814C15.8877 4.94227 15.8874 4.90668 15.8869 4.87132C15.8836 4.64574 15.9873 4.43209 16.1642 4.29204C16.521 4.00951 16.847 3.69397 17.1427 3.34973Z"
      fill="#5AADEC"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient
        x1="49.999944%"
        y1="0%"
        x2="49.999944%"
        y2="100%"
        id="linearGradient-1">
        <stop stop-color="#E60B2D" offset="0%"></stop>
        <stop stop-color="#C11591" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0001682%"
        y1="0%"
        x2="50.0001682%"
        y2="100%"
        id="linearGradient-2">
        <stop stop-color="#E60B2D" offset="0%"></stop>
        <stop stop-color="#C11591" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0000336%"
        y1="0%"
        x2="50.0000336%"
        y2="100%"
        id="linearGradient-3">
        <stop stop-color="#E60B2D" offset="0%"></stop>
        <stop stop-color="#C11591" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Vector" fill="url(#linearGradient-1)" fillRule="nonzero">
        <path
          d="M12.2719,0.207031 L5.57812,0.207031 C2.49788,0.207031 0,2.64673 0,5.65522 L0,12.193 C0,15.2015 2.49788,17.6412 5.57812,17.6412 L12.2719,17.6412 C15.3521,17.6412 17.85,15.2015 17.85,12.193 L17.85,5.65522 C17.85,2.64673 15.3521,0.207031 12.2719,0.207031 Z M16.1765,12.193 C16.1765,14.296 14.425,16.0068 12.2719,16.0068 L5.57812,16.0068 C3.42496,16.0068 1.67344,14.296 1.67344,12.193 L1.67344,5.65522 C1.67344,3.55222 3.42496,1.84149 5.57812,1.84149 L12.2719,1.84149 C14.425,1.84149 16.1765,3.55222 16.1765,5.65522 L16.1765,12.193 Z"
          id="Shape"></path>
      </g>
      <g
        id="inst1"
        transform="translate(13.000000, 3.000000)"
        fill="url(#linearGradient-2)"
        fillRule="nonzero">
        <path
          d="M0.715759,1.82178 C1.04418,1.82178 1.31042,1.56174 1.31042,1.24097 C1.31042,0.920194 1.04418,0.660156 0.715759,0.660156 C0.387335,0.660156 0.121094,0.920194 0.121094,1.24097 C0.121094,1.56174 0.387335,1.82178 0.715759,1.82178 Z"
          id="Path"></path>
      </g>
      <g
        id="Vector-1"
        transform="translate(4.000000, 4.000000)"
        fill="url(#linearGradient-3)"
        fillRule="nonzero">
        <path
          d="M4.92737,0.56543 C2.46294,0.56543 0.464844,2.51696 0.464844,4.92395 C0.464844,7.33094 2.46294,9.28247 4.92737,9.28247 C7.3918,9.28247 9.38989,7.33094 9.38989,4.92395 C9.38989,2.51696 7.3918,0.56543 4.92737,0.56543 Z M4.92737,7.64803 C3.39003,7.64803 2.13829,6.42546 2.13829,4.92395 C2.13829,3.42135 3.39003,2.19987 4.92737,2.19987 C6.46471,2.19987 7.71645,3.42135 7.71645,4.92395 C7.71645,6.42546 6.46471,7.64803 4.92737,7.64803 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
);

export const StyledDropdownArrow = styled(DropdownArrow)`
  display: inline-block;
  width: 10px;
  height: 7px;
  transform: ${({ active }) => (active ? 'rotateZ(0deg)' : 'rotateZ(-90deg)')};
`;

export const ShareIcon = () => (
  <svg
    width="38"
    height="42"
    viewBox="0 0 38 42"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M36.7037 6.90948C36.7037 10.0099 34.1906 12.5234 31.0901 12.5234C27.9897 12.5234 25.4766 10.0099 25.4766 6.90948C25.4766 3.80934 27.9897 1.2959 31.0901 1.2959C34.1906 1.2959 36.7037 3.80934 36.7037 6.90948Z" />
    <path d="M31.0907 13.8184C27.2802 13.8184 24.1816 10.7195 24.1816 6.90905C24.1816 3.09887 27.2802 0 31.0907 0C34.9012 0 37.9997 3.09887 37.9997 6.90905C37.9997 10.7195 34.9012 13.8184 31.0907 13.8184ZM31.0907 2.59093C28.7088 2.59093 26.7726 4.52907 26.7726 6.90905C26.7726 9.28934 28.7088 11.2275 31.0907 11.2275C33.4726 11.2275 35.4088 9.28934 35.4088 6.90905C35.4088 4.52907 33.4726 2.59093 31.0907 2.59093Z" />
    <path d="M36.7037 34.5455C36.7037 37.6457 34.1906 40.1591 31.0901 40.1591C27.9897 40.1591 25.4766 37.6457 25.4766 34.5455C25.4766 31.4451 27.9897 28.9316 31.0901 28.9316C34.1906 28.9316 36.7037 31.4451 36.7037 34.5455Z" />
    <path d="M31.0907 41.4542C27.2802 41.4542 24.1816 38.3553 24.1816 34.5451C24.1816 30.7346 27.2802 27.6357 31.0907 27.6357C34.9012 27.6357 37.9997 30.7346 37.9997 34.5451C37.9997 38.3553 34.9012 41.4542 31.0907 41.4542ZM31.0907 30.2267C28.7088 30.2267 26.7726 32.1648 26.7726 34.5451C26.7726 36.9251 28.7088 38.8632 31.0907 38.8632C33.4726 38.8632 35.4088 36.9251 35.4088 34.5451C35.4088 32.1648 33.4726 30.2267 31.0907 30.2267Z" />
    <path d="M12.5224 20.7278C12.5224 23.8283 10.009 26.3414 6.9085 26.3414C3.80837 26.3414 1.29492 23.8283 1.29492 20.7278C1.29492 17.6274 3.80837 15.1143 6.9085 15.1143C10.009 15.1143 12.5224 17.6274 12.5224 20.7278Z" />
    <path d="M6.90905 27.6365C3.09887 27.6365 0 24.5379 0 20.7274C0 16.9169 3.09887 13.8184 6.90905 13.8184C10.7195 13.8184 13.8184 16.9169 13.8184 20.7274C13.8184 24.5379 10.7195 27.6365 6.90905 27.6365ZM6.90905 16.4093C4.52717 16.4093 2.59093 18.3471 2.59093 20.7274C2.59093 23.1077 4.52717 25.0455 6.90905 25.0455C9.29124 25.0455 11.2275 23.1077 11.2275 20.7274C11.2275 18.3471 9.29124 16.4093 6.90905 16.4093Z" />
    <path d="M10.9888 19.8988C10.3875 19.8988 9.80369 19.586 9.48583 19.0265C9.01427 18.1991 9.30461 17.1437 10.132 16.6703L26.1593 7.53306C26.9866 7.05801 28.0421 7.34835 28.5155 8.17889C28.9871 9.00627 28.6968 10.0617 27.8694 10.5351L11.8418 19.6724C11.5723 19.8261 11.2788 19.8988 10.9888 19.8988Z" />
    <path d="M27.0127 34.1485C26.7223 34.1485 26.4288 34.0757 26.1593 33.922L10.1317 24.7848C9.30437 24.3132 9.01434 23.2578 9.48591 22.4286C9.95558 21.5996 11.0126 21.3077 11.8419 21.7827L27.8695 30.9199C28.6968 31.3915 28.9869 32.4469 28.5153 33.2762C28.1958 33.8357 27.612 34.1485 27.0127 34.1485Z" />
  </svg>
);

export const Copy = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3732 6.62632C10.1718 6.42375 9.94855 6.24419 9.70751 6.09092C9.06906 5.68209 8.32684 5.46485 7.56872 5.46485C6.51646 5.46289 5.50697 5.88098 4.76422 6.62632L1.16087 10.2325C0.419375 10.9754 0.00261789 11.982 0.00195395 13.0316C0.000559665 15.222 1.77501 16.9987 3.96536 17.0001C5.01599 17.0038 6.02455 16.5877 6.76704 15.8443L9.7415 12.8698C9.79555 12.8163 9.82572 12.7432 9.82529 12.6671C9.8244 12.5106 9.69682 12.3845 9.54036 12.3854H9.42706C8.80561 12.3876 8.1897 12.2683 7.61406 12.0342C7.508 11.9905 7.3861 12.0151 7.3053 12.0965L5.1665 14.2381C4.50236 14.9022 3.42558 14.9022 2.76143 14.2381C2.09729 13.574 2.09729 12.4972 2.76143 11.833L6.37896 8.21836C7.04257 7.55557 8.1176 7.55557 8.78121 8.21836C9.22844 8.63926 9.92605 8.63926 10.3732 8.21836C10.5657 8.02578 10.6823 7.77036 10.7019 7.49881C10.7225 7.17431 10.6029 6.85651 10.3732 6.62632Z"
      fill="#4469B0"
    />
    <path
      d="M15.8372 1.16166C14.2883 -0.38722 11.7771 -0.38722 10.2282 1.16166L7.25654 4.13045C7.17494 4.21245 7.1514 4.33577 7.19705 4.44207C7.24216 4.54867 7.3476 4.61709 7.46332 4.61486H7.56812C8.18884 4.61373 8.80376 4.73401 9.3783 4.96898C9.48436 5.01263 9.60626 4.988 9.68707 4.90666L11.8202 2.77637C12.4843 2.11223 13.5611 2.11223 14.2253 2.77637C14.8894 3.44051 14.8894 4.5173 14.2253 5.18144L11.568 7.83578L11.5454 7.86128L10.6162 8.78479C9.95261 9.44757 8.87759 9.44757 8.21398 8.78479C7.76674 8.36388 7.06914 8.36388 6.62194 8.78479C6.4283 8.97883 6.31155 9.23647 6.29332 9.50998C6.27261 9.83449 6.39228 10.1523 6.62194 10.3825C6.94983 10.7118 7.33345 10.9803 7.75506 11.1757C7.81455 11.204 7.87404 11.2267 7.93353 11.2522C7.99302 11.2777 8.05533 11.2975 8.11482 11.3202C8.1743 11.3428 8.23662 11.3626 8.2961 11.3796L8.46325 11.425C8.57655 11.4533 8.68989 11.476 8.80601 11.4958C8.94591 11.5166 9.0868 11.5298 9.22812 11.5354H9.4264H9.4434L9.61337 11.5156C9.67568 11.5128 9.74085 11.4986 9.81451 11.4986H9.91081L10.1063 11.4703L10.1969 11.4533L10.3612 11.4193H10.3924C11.0883 11.2445 11.7236 10.8842 12.2309 10.3768L15.8371 6.77065C17.3861 5.22177 17.3861 2.71054 15.8372 1.16166Z"
      fill="#4469B0"
    />
  </svg>
);

export const ScrollDownArrow = () => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="scroll-down__arrow">
    <path
      d="M5.43603 0.910833C5.42597 0.972858 5.42132 1.03563 5.42214 1.09843L5.42214 11.7924L5.31368 11.5592C5.2058 11.3313 5.05897 11.124 4.87985 10.9465L1.88098 7.94759C1.50395 7.55264 0.897436 7.48628 0.443911 7.79033C-0.0393713 8.14421 -0.144304 8.82285 0.209574 9.30617C0.23818 9.34521 0.269362 9.38233 0.302916 9.4172L5.72582 14.8401C6.14915 15.2639 6.83586 15.2643 7.25966 14.841C7.25993 14.8407 7.26023 14.8404 7.2605 14.8401L12.6834 9.4172C13.1064 8.99306 13.1054 8.30635 12.6813 7.88337C12.6479 7.85008 12.6124 7.819 12.575 7.79033C12.1214 7.48627 11.5149 7.55264 11.1379 7.94759L8.13359 10.941C7.97429 11.1002 7.84072 11.2831 7.73772 11.4833L7.5913 11.8087L7.5913 1.15812C7.61187 0.604067 7.22485 0.11797 6.68025 0.0138843C6.08899 -0.0819991 5.53195 0.319567 5.43603 0.910833Z"
      fill="white"
    />
  </svg>
);

export {
  SavingsIcon,
  FreeShippingIcon,
  RewardPointsIcon,
  FacebookIcon,
  TwitterIcon,
  CopyIcon,
  CongratulationIcon,
  DollarIcon,
  FirstTimeIcon,
  PercentIcon,
};
