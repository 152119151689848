import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import { CongratulationIcon } from '../../shareComponent/recipe/icons';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';

import Footer from '../../components/footer';
import { InstallWrapper } from './styled';
import { useLink } from '../../hooks';
import { triggerAnalyticsEvent } from '../../shareComponent/hooks';

const INSTALL = 'install';

const getStarted = () => {
  if (typeof window === 'object') {
    window.location.pathname = '/deals';
  }
};

const InstallExtension = () => {
  const linkTo = useLink();
  const { action } = useParams();

  useEffect(() => {
    triggerAnalyticsEvent(`extension-${action}`);
  }, [action])

  return (
    <>
      <RecipePageContainer>
        <Header activeMenu="terms" />
        <PageTitleContainer>
          {action === INSTALL ? 'Thank You' : 'Sorry'}
        </PageTitleContainer>
        <Container>
          <NavMenu>
            <NavMenuItem
              onClick={e => {
                e.preventDefault();
                linkTo('/');
              }}
              href="/">
              Home
            </NavMenuItem>{' '}
            /{' '}
            <NavMenuItem active>
              {action === INSTALL ? 'Install' : 'Remove'}
            </NavMenuItem>
          </NavMenu>
          <InstallWrapper>
            <div className="install__image-wrapper">
              <CongratulationIcon className="install__image" />
            </div>
            <div className="install__h1">
              {action === INSTALL
                ? 'Thanks for installing our extension!'
                : 'We are sorry that our extension did not interest you...'}
            </div>
            <Button onClick={getStarted}>View Live Deal Recipes</Button>
          </InstallWrapper>
        </Container>
      </RecipePageContainer>
      <Footer />
    </>
  );
};

export default InstallExtension;
