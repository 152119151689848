import styled from 'styled-components';

export const ScrollUpButtonWrapper = styled.div`
  position: fixed;
  width: 48px;
  cursor: pointer;
  height: 48px;
  display: flex;
  z-index: 91;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.18);
  transition: 0.2s;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: #ffffff;
  right: 24px;
  bottom: 24px;
  :hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  }
`;
