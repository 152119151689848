import styled from 'styled-components';

export const ProfilePageEmailWrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  .profile-page__email__text {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
  }
  .profile-page__email__switch-wrapper {
    padding-top: 20px;
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 48px;
    padding-bottom: 83px;
    .profile-page__email__text,
    .profile-page__email__switch-wrapper {
      padding-left: 6px;
    }
  }
`;
