import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CheckboxContainer, CheckboxWrapper, CheckboxLabel } from './styled';

const propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
};

/* const CheckIcon = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.27273L3.47059 6L8 1"
      stroke="#EF454B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
); */

const Checkbox = ({ onChange, defaultValue, name, label }) => {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setChecked(defaultValue);
    }
  }, [defaultValue, setChecked]);

  return (
    <CheckboxWrapper
      onClick={() => {
        setChecked(!checked);

        if (onChange) {
          onChange({ [name || 'checked']: !checked });
        }
      }}>
      <CheckboxContainer checked={checked}>
        {/* <CheckboxIconWrapper>{checked && <CheckIcon />}</CheckboxIconWrapper> */}
      </CheckboxContainer>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = propTypes;

export default Checkbox;
