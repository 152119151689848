import styled from 'styled-components';
import { H1 } from '../../styled';
import { color3 } from '../../../../shareComponent/styled';

export const SliderWrapper = styled.div`
  width: 100%;
  height: 493px;
  .slider__container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .slider__slide {
    height: 493px;
    width: 100%;
    :focus {
      outline: none !important;
    }
  }

  .slider__slide__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    :focus {
      outline: none !important;
    }
  }
  .slide__text {
    width: 822px;
    text-align: center;
  }

  .slide__text.slide__text_wide {
    width: 1020px;
  }

  .slider__slide__sign {
    font-size: 24px;
    line-height: 65px;
    font-weight: 500;
    color: ${color3};
  }

  .carousel-dots {
    position: absolute;
    bottom: 98px;
    z-index: 1051;
  }

  @media screen and (max-width: 1024px) {
    height: 279px;
    ${H1} {
      font-size: 24px;
      line-height: 32px;
    }
    .slider__slide {
      height: 279px;
    }
    .slide__text {
      width: 80%;
    }
    .slide__text.slide__text_wide {
      width: 90%;
    }

    .slider__slide__sign {
      font-size: 17px;
      line-height: 32px;
    }

    .carousel-dots {
      bottom: 20px;
    }
  }
`;
