import axios from '../axios';
import { setClientSubscribe } from '..';
import { removeToken } from './token';

export const facebookAuth = async (fbResponse, err) => {
  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get('redirect');

  const response = await axios.post(
    `/auth/facebook`,
    {
      access_token: fbResponse._token.accessToken,
    },
    {
      withCredentials: true,
    },
  );

  if (response.subscriptions) {
    setClientSubscribe();
  }

  if (typeof window === 'object') {
    /* if (window.history) {
      window.history.back();
    } else {
      window.location.pathname = "/deals";
    } */

    if (params && redirectTo) {
      window.location = redirectTo;
    } else {
      window.location.pathname = '/profile';
    }
  }
};

export const localLogin = async (email, password) => {
  try {
    await axios.post(
      `/auth/login`,
      {
        email,
        password,
      },
      {
        withCredentials: true,
      },
    );

    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirect');

    if (params && redirectTo) {
      window.location = redirectTo;
    } else {
      window.location.pathname = '/profile';
    }
  } catch (error) {
    let message = 'Unhandled error';

    if (error.response && error.response.data) {
      message = error.response.data;
    }

    return { error: message };
  }
};

export const register = async user => {
  try {
    await axios.post('/auth/register', user, {
      withCredentials: true,
    });

    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get('redirect');

    if (params && redirectTo) {
      window.location = redirectTo;
    } else {
      window.location.pathname = '/profile';
    }
  } catch (error) {
    return { error };
  }
};

export const logout = async () => {
  try {
    await axios.get('/auth/logout', { withCredentials: true });
    removeToken();

    window.location.pathname = '/';
  } catch (e) {
    // no localstorage access
    console.log(e);
  }
};

export const forgotPassword = async email => {
  try {
    const res = await axios.post(`/auth/forgot-password`, {
      email,
    });

    return res;
  } catch (err) {
    console.log(err);

    return false;
  }
};

export const resetPassword = async (resetPasswordToken, password) => {
  try {
    const { data } = await axios.post('/auth/reset-password', {
      resetPasswordToken,
      password,
    });

    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};
