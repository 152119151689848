import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import { StepsContainer, Step } from './styled';

const propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      user_facing_layer_name: PropTypes.string,
      exclusions: PropTypes.string,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      is_percent: PropTypes.oneOf([0, 1, 2, 3]),
    }),
  ),
  current: PropTypes.number,
  handleClick: PropTypes.func,
};

const Steps = ({ steps, handleClick, current }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    let scrollCount = 0;

    const handleScroll = e => {
      e.preventDefault();
      scrollCount += e.deltaY;

      if (scrollCount > 100 && current + 1 < steps.length) {
        handleClick(current + 1);
      } else if (scrollCount < -100 && current - 1 >= 0) {
        handleClick(current - 1);
      }
    };

    const isBrowser = typeof window === 'object';
    const currRef = containerRef.current;

    if (isBrowser) {
      currRef.addEventListener('wheel', handleScroll);
    }

    if (current > 1 && steps.length >= 4) {
      currRef.setAttribute(
        'style',
        `transform: translateY(${-current * 36}px);`,
      );
    } else {
      currRef.removeAttribute('style');
    }

    /* if (steps[current]) {
      const { user_facing_layer_name, is_percent, quantity } = steps[current];
      setTitle(
        `${user_facing_layer_name} ${renderQuantity({ is_percent, quantity })}`,
      );
    } */

    return () => {
      if (isBrowser) {
        currRef.removeEventListener('wheel', handleScroll);
      }
    };
  }, [current, containerRef, handleClick, steps.length, steps]);

  return (
    <Transition in={current > 1} timeout={200}>
      <StepsContainer ref={containerRef}>
        {steps &&
          steps.map((step, index) => (
            <Step
              onClick={() => {
                handleClick(index);
              }}
              key={index}
              active={current === index}>
              <span className="step__text">STEP {index + 1}</span>
            </Step>
          ))}
      </StepsContainer>
    </Transition>
  );
};

Steps.propTypes = propTypes;

export default Steps;
