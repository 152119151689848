import React from 'react';
import { ChevronDown, ChevronUp } from '../../../components/icons';

const LearnMore = ({ active, handleChange }) => (
  <p className="privacy-main-side__section-content">
    <span
      role="button"
      tabIndex="-1"
      className="privacy-main-side__learn-more"
      onKeyDown={() => {}}
      onClick={handleChange}>
      {active ? `Read less` : `Learn more`}
      <span className="privacy-main-side__learn-more__icon-wrapper">
        {active ? <ChevronUp /> : <ChevronDown />}
      </span>
    </span>
  </p>
);

export default LearnMore;
