import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Header from '../../shareComponent/header';
import Footer from '../../shareComponent/footer';
import { LoginPageWrapper, LoginPageContentWrapper } from '../login/styled';
import ForgotPasswordForm from '../../components/forgotPasswordForm';
import ResetPasswordForm from '../../components/resetPasswordForm';

const propTypes = {
  resetPassword: PropTypes.bool,
};

const ForgotPassword = ({ resetPassword }) => {
  const [title, setTitle] = useState(
    resetPassword ? 'SET NEW PASSWORD' : 'ENTER YOUR ACCOUNT',
  );

  const location = useLocation();
  const token = new URLSearchParams(location.search).get(
    'reset_password_token',
  );

  return (
    <>
      <LoginPageWrapper>
        <Header noLogin />
        <LoginPageContentWrapper>
          <div className="login-page__h1">{title}</div>
          <div className="login-page__content">
            <div className="login-page__col">
              <div className="login-page__form-wrapper">
                {!resetPassword ? (
                  <ForgotPasswordForm setTitle={setTitle} />
                ) : (
                  <ResetPasswordForm setTitle={setTitle} token={token} />
                )}
              </div>
            </div>
          </div>
        </LoginPageContentWrapper>
      </LoginPageWrapper>
      <Footer />
    </>
  );
};

ForgotPassword.propTypes = propTypes;

export default ForgotPassword;
