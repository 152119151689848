import styled, { css } from 'styled-components/macro';

const fontColor = css`
  ${({ type }) =>
    type === 'twitter'
      ? 'color: #5AADEC;'
      : type === 'facebook'
      ? 'color: #4469B0;'
      : type === 'instagram'
      ? 'color: #C11591;' +
        'background: -webkit-linear-gradient(#E60B2D, #C11591);' +
        '-webkit-background-clip: text;' +
        '-webkit-text-fill-color: transparent;'
      : type === 'copy'
      ? 'color: #4469B0;'
      : 'color: #000000;'}
`;

export const ShareButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 11px;
  outline: none;
  padding: 0;

  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
  :focus {
    outline: none;
  }
  svg {
    width: 43px;
    height: 43px;
  }
`;

export const IconWrapper = styled.i`
  display: inline-block;
  position: relative;
`;

export const TextWrapper = styled.span`
  display: inline-block;
  box-sizing: border-box;
  ${fontColor}
`;
