import React, { useCallback, useState } from 'react';
import { useValidation, useRegister } from '../../hooks';
import { PageTitleContainer } from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';
import Footer from '../../components/footer';

import { CreateUserPageWrapper } from './styled';

const CreateUserPage = () => {
  const {
    validate,
    validateAt,
    errors: validationErrors,
    isValid,
  } = useValidation('profile');

  const { isRegistered, doRegister } = useRegister();

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    region: '',
    password: '',
    confirmPassword: '',
  });

  const setUserProperty = useCallback(
    (name, value) => {
      setUser({
        ...user,
        [name]: value,
      });
    },
    [user, setUser],
  );

  const handleChange = ({ target: { name, value } }) => {
    setUserProperty(name, value);
  };

  const handleBlur = useCallback(
    async ({ target: { name } }) => {
      await validateAt(name, user);
    },
    [user, validateAt],
  );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isValid) {
        const { confirmPassword, ...rest } = user;
        doRegister(rest);
      }

      await validate(user);
    },
    [isValid, user, validate, doRegister],
  );

  const { firstName, lastName, email, password, confirmPassword } = user;

  return (
    <>
      <RecipePageContainer>
        <Header />
        <PageTitleContainer className="page__title__container_display_mobile">
          Create your account
        </PageTitleContainer>
        <Container>
          <CreateUserPageWrapper>
            <form className="create-user__form" onSubmit={handleSubmit}>
              {isRegistered && isRegistered.error && (
                <div className="create-user__form__error">Unhandled error</div>
              )}
              <div className="create-user__input create-user__input_small">
                <label htmlFor="firstName">
                  First Name
                  <input
                    name="firstName"
                    type="text"
                    className={`create-user__input__text ${
                      validationErrors['firstName']
                        ? 'create-user__input__text_error'
                        : ''
                    }`}
                    onChange={handleChange}
                    value={firstName}
                    onBlur={handleBlur}
                  />
                  {validationErrors['firstName'] && (
                    <span className="create-user__input-error">
                      {validationErrors['firstName']}
                    </span>
                  )}
                </label>
              </div>
              <div className="create-user__input create-user__input_small create-user__input_small_last">
                <label htmlFor="lastName">
                  Last Name
                  <input
                    name="lastName"
                    type="text"
                    className={`create-user__input__text ${
                      validationErrors['lastName']
                        ? 'create-user__input__text_error'
                        : ''
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={lastName}
                  />
                  {validationErrors['lastName'] && (
                    <span className="create-user__input-error">
                      {validationErrors['lastName']}
                    </span>
                  )}
                </label>
              </div>
              <div className="create-user__input">
                <label htmlFor="email">
                  E-mail
                  <input
                    name="email"
                    type="text"
                    className={`create-user__input__text ${
                      validationErrors['email']
                        ? 'create-user__input__text_error'
                        : ''
                    }`}
                    onChange={handleChange}
                    value={email}
                    onBlur={handleBlur}
                  />
                  {validationErrors['email'] && (
                    <span className="create-user__input-error">
                      {validationErrors['email']}
                    </span>
                  )}
                </label>
              </div>
              <div className="create-user__input">
                <label htmlFor="password">
                  Password
                  <input
                    name="password"
                    type="password"
                    className={`create-user__input__text ${
                      validationErrors['password']
                        ? 'create-user__input__text_error'
                        : ''
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={password}
                  />
                  {validationErrors['password'] && (
                    <span className="create-user__input-error">
                      {validationErrors['password']}
                    </span>
                  )}
                </label>
              </div>
              <div className="create-user__input">
                <label htmlFor="confirmPassword">
                  Confirm Password
                  <input
                    name="confirmPassword"
                    type="password"
                    className={`create-user__input__text ${
                      validationErrors['confirmPassword']
                        ? 'create-user__input__text_error'
                        : ''
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={confirmPassword}
                  />
                  {validationErrors['confirmPassword'] && (
                    <span className="create-user__input-error">
                      {validationErrors['confirmPassword']}
                    </span>
                  )}
                </label>
              </div>
              <div className="create-user__button-wrapper">
                <button className="create-user__button" type="submit">
                  CREATE
                </button>
              </div>
            </form>
          </CreateUserPageWrapper>
        </Container>
      </RecipePageContainer>
      <Footer />
    </>
  );
};

export default CreateUserPage;
