import React from 'react';

export const ViewGridIcon = () => (
  <svg
    width="13px"
    height="12px"
    viewBox="0 0 13 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Rectangle-732"
        transform="translate(0.000000, 7.000000)"
        fill="#000000"
        fillRule="nonzero">
        <rect id="Rectangle" x="0" y="0" width="5" height="5"></rect>
      </g>
      <g id="Group" fill="#000000" fillRule="nonzero">
        <g id="Rectangle-732">
          <rect id="Rectangle" x="0" y="0" width="5" height="5"></rect>
        </g>
        <g id="Rectangle-732" transform="translate(8.000000, 0.000000)">
          <rect id="Rectangle" x="0" y="0" width="5" height="5"></rect>
        </g>
        <g id="Rectangle-732" transform="translate(8.000000, 7.000000)">
          <rect id="Rectangle" x="0" y="0" width="5" height="5"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export const ViewListIcon = () => (
  <svg
    width="16px"
    height="12px"
    viewBox="0 0 16 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#98A1AF" fillRule="nonzero">
        <g id="Rectangle-727">
          <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
        </g>
        <g id="Rectangle-727" transform="translate(0.000000, 5.000000)">
          <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
        </g>
        <g id="Rectangle-727" transform="translate(0.000000, 10.000000)">
          <rect id="Rectangle" x="0" y="0" width="16" height="2"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export const StarIcon = ({ color }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.01673 5.0852C8.80013 5.0852 8.60815 4.94572 8.54121 4.73972L7.4765 1.46332C7.32682 1.00269 6.67515 1.00269 6.52546 1.46332L5.46076 4.73972C5.39382 4.94572 5.20184 5.0852 4.98524 5.0852H1.53872C1.05437 5.0852 0.852976 5.70498 1.24481 5.98969L4.03286 8.01552C4.20809 8.14285 4.28141 8.36853 4.21447 8.57454L3.1499 11.8505C3.0002 12.3112 3.52747 12.6943 3.91933 12.4096L6.707 10.384C6.88229 10.2566 7.11966 10.2567 7.29491 10.3841L10.0804 12.409C10.4723 12.6938 10.9996 12.3107 10.8499 11.85L9.78553 8.57454C9.71859 8.36853 9.79191 8.14285 9.96714 8.01552L12.7552 5.98969C13.147 5.70498 12.9456 5.0852 12.4613 5.0852H9.01673Z"
      fill="#FFDD8B"
    />
  </svg>
);

export const TwitterFooterIcon = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.245 2.44712C11.3581 2.31548 11.2164 2.12677 11.0501 2.17653C10.8701 2.2304 10.7416 1.9548 10.8606 1.80941C10.9193 1.73771 10.9738 1.66249 11.0238 1.58407C11.1383 1.4045 10.9343 1.22603 10.7387 1.31015C10.5166 1.40562 10.286 1.48443 10.0481 1.54613C9.87794 1.59027 9.70202 1.52821 9.56738 1.4152C9.15087 1.0656 8.61333 0.856445 8.03469 0.856445C6.71746 0.856445 5.657 1.9255 5.657 3.23607C5.657 3.51064 5.44024 3.76546 5.16797 3.73006C3.85112 3.55882 2.65492 3.00764 1.68903 2.1889C1.22509 1.79565 0.482357 1.88449 0.482357 2.49267C0.482357 3.04555 0.673435 3.5563 0.990273 3.96027C1.12613 4.13349 1.01055 4.40544 0.803591 4.3304C0.693626 4.29052 0.587635 4.24305 0.48716 4.18921C0.476811 4.18366 0.464224 4.19112 0.464224 4.20286C0.464224 5.1357 1.00118 5.94104 1.77672 6.3335C1.91806 6.40503 1.90506 6.61875 1.74664 6.61875C1.54643 6.61875 1.38002 6.8178 1.47686 6.99303C1.58769 7.19358 1.72626 7.37687 1.8874 7.53748C2.31939 7.96805 2.49604 8.88924 1.91039 9.05959C1.48471 9.1834 1.03447 9.25005 0.569399 9.25005C0.431707 9.25005 0.370577 9.44143 0.492226 9.50593C1.43473 10.0057 2.5071 10.285 3.64996 10.285C8.02817 10.285 10.4218 6.65864 10.4218 3.5153C10.4218 3.49178 10.4216 3.46843 10.4213 3.44523C10.4191 3.29726 10.4872 3.15711 10.6032 3.06524C10.8372 2.87991 11.0511 2.67293 11.245 2.44712Z"
      fill="#FAFAFA"
    />
  </svg>
);

export const FacebookFooterIcon = () => (
  <svg
    width="7"
    height="13"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4749 2.13508H6.57031V0.227078C6.38133 0.201078 5.73138 0.142578 4.97445 0.142578C3.39508 0.142578 2.31317 1.13608 2.31317 2.96208V4.64258H0.570312V6.77558H2.31317V12.1426H4.44999V6.77608H6.12235L6.38783 4.64308H4.44949V3.17358C4.44999 2.55708 4.61598 2.13508 5.4749 2.13508Z"
      fill="#FAFAFA"
    />
  </svg>
);

export const FacebookMobileMenuIcon = () => (
  <svg
    width="10"
    height="19"
    viewBox="0 0 10 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9614 3.09944H9.66537V0.131444C9.37139 0.091 8.36036 0 7.18291 0C4.72611 0 3.04314 1.54544 3.04314 4.38589V7H0.332031V10.318H3.04314V18.6667H6.36708V10.3188H8.96853L9.3815 7.00078H6.36631V4.71489C6.36708 3.75589 6.62528 3.09944 7.9614 3.09944Z"
      fill="#FAFAFA"
    />
  </svg>
);

export const TwitterMobileMenuIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7149 3.1404C17.8908 2.93563 17.6704 2.64208 17.4118 2.71948C17.1318 2.80328 16.9318 2.37457 17.1169 2.14841C17.2082 2.03687 17.293 1.91986 17.3708 1.79787C17.5489 1.51854 17.2316 1.24093 16.9272 1.37178C16.5818 1.52028 16.223 1.64288 15.853 1.73886C15.5883 1.80752 15.3147 1.71099 15.1053 1.53519C14.4573 0.991362 13.6212 0.666016 12.7211 0.666016C10.672 0.666016 9.02243 2.32899 9.02243 4.36766C9.02243 4.79476 8.68526 5.19115 8.26172 5.13608C6.21329 4.86971 4.35254 4.01231 2.85003 2.73873C2.12836 2.127 0.97299 2.26519 0.97299 3.21125C0.97299 4.07129 1.27022 4.86579 1.76308 5.49419C1.97442 5.76364 1.79462 6.18668 1.47269 6.06994C1.30163 6.00792 1.13676 5.93407 0.980461 5.85031C0.964362 5.84169 0.944782 5.85329 0.944782 5.87155C0.944782 7.32263 1.78004 8.57539 2.98644 9.18588C3.20631 9.29715 3.18608 9.62961 2.93965 9.62961C2.62821 9.62961 2.36936 9.93923 2.52 10.2118C2.6924 10.5238 2.90795 10.8089 3.15862 11.0587C3.8306 11.7285 4.10539 13.1615 3.19437 13.4265C2.53221 13.6191 1.83183 13.7227 1.10839 13.7227C0.8942 13.7227 0.799109 14.0204 0.988341 14.1208C2.45446 14.8982 4.12259 15.3327 5.90037 15.3327C12.7109 15.3327 16.4344 9.69166 16.4344 4.80202C16.4344 4.76542 16.4341 4.7291 16.4336 4.69302C16.4302 4.46284 16.536 4.24483 16.7165 4.10192C17.0805 3.81363 17.4132 3.49166 17.7149 3.1404Z"
      fill="#FAFAFA"
    />
  </svg>
);

export const InstagramMobileMenuIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.66 11.7C24.66 11.9485 24.4585 12.15 24.21 12.15C23.9615 12.15 23.76 11.9485 23.76 11.7C23.76 11.4515 23.9615 11.25 24.21 11.25C24.4585 11.25 24.66 11.4515 24.66 11.7Z"
      fill="white"
    />
    <path
      d="M25.3125 9H10.6875C9.75558 9 9 9.75558 9 10.6875V25.3125C9 26.2444 9.75558 27 10.6875 27H25.3125C26.2444 27 27 26.2444 27 25.3125V10.6875C27 9.75558 26.2444 9 25.3125 9ZM18 23.625C14.8933 23.625 12.375 21.1067 12.375 18C12.375 14.8933 14.8933 12.375 18 12.375C21.1067 12.375 23.625 14.8933 23.625 18C23.6217 21.1053 21.1053 23.6217 18 23.625ZM24.1875 13.5C23.2556 13.5 22.5 12.7444 22.5 11.8125C22.5 10.8806 23.2556 10.125 24.1875 10.125C25.1194 10.125 25.875 10.8806 25.875 11.8125C25.875 12.7444 25.1194 13.5 24.1875 13.5Z"
      fill="white"
    />
    <path
      d="M21.6 18C21.6 19.9883 19.9883 21.6 18 21.6C16.0117 21.6 14.4 19.9883 14.4 18C14.4 16.0117 16.0117 14.4 18 14.4C19.9883 14.4 21.6 16.0117 21.6 18Z"
      fill="white"
    />
    <path
      d="M0.5 18C0.5 8.33518 8.33493 0.500151 17.9997 0.5C27.6602 0.511142 35.489 8.34001 35.5 18.0006C35.4997 27.6652 27.6647 35.5 18 35.5C8.33508 35.5 0.5 27.6649 0.5 18Z"
      stroke="white"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.64645 8.35355C3.84171 8.54882 4.15829 8.54882 4.35355 8.35355L7.53553 5.17157C7.7308 4.97631 7.7308 4.65973 7.53553 4.46447C7.34027 4.2692 7.02369 4.2692 6.82843 4.46447L4 7.29289L1.17157 4.46447C0.976311 4.2692 0.659728 4.2692 0.464466 4.46447C0.269204 4.65973 0.269204 4.97631 0.464466 5.17157L3.64645 8.35355ZM3.5 -2.54983e-08L3.5 8L4.5 8L4.5 2.54983e-08L3.5 -2.54983e-08Z"
      fill="black"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.35355 0.646446C4.15829 0.451184 3.84171 0.451184 3.64645 0.646446L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 9L4.5 1L3.5 1L3.5 9L4.5 9Z"
      fill="black"
    />
  </svg>
);

export const ChevronDown = props => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.313 5.849l-4.526 4.525a1.2 1.2 0 01-1.697 0L2.868 6.081l.848-.849L7.94 9.525 12.464 5l.849.849z"
      fill="#505050"
    />
  </svg>
);

export const ChevronUp = props => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.326 9.877l-.849.848L7.952 6.2l-4.526 4.526-.848-.849 4.525-4.526a1.2 1.2 0 011.697 0l4.526 4.526z"
      fill="#505050"
    />
  </svg>
);

export const ScrollUp = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1h13v1H1V1zM8 4.44l4.05 4.092.71-.704-4.477-4.523a1.1 1.1 0 00-1.556-.008L2.154 7.825l.703.71L7 4.434V15h1V4.44z"
      fill="#505050"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.0336 17.3563L0.0426957 15.3654L6.35347 9.0546L0.0426957 2.78139L2.0336 0.790489L8.34437 7.0637L14.6176 0.790489L16.6085 2.78139L10.3353 9.0546L16.6085 15.3654L14.6176 17.3563L8.34437 11.0455L2.0336 17.3563Z"
      fill="#1F2133"
    />
  </svg>
);

export const DeleteItemIcon = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clipDelete)">
      <path
        d="M11 0.546021C17.0656 0.546021 22 5.48038 22 11.546C22 17.6117 17.0656 22.546 11 22.546C4.93436 22.546 0 17.6117 0 11.546C0 5.48038 4.93436 0.546021 11 0.546021Z"
        fill="#F44336"
      />
      <path
        d="M6.94895 14.3006C6.5906 14.6591 6.5906 15.2383 6.94895 15.5968C7.12771 15.7756 7.36235 15.8654 7.59717 15.8654C7.83182 15.8654 8.06647 15.7756 8.24523 15.5968L10.9998 12.8421L13.7543 15.5968C13.933 15.7756 14.1677 15.8654 14.4023 15.8654C14.6372 15.8654 14.8718 15.7756 15.0506 15.5968C15.4089 15.2383 15.4089 14.6591 15.0506 14.3006L12.2959 11.546L15.0506 8.79149C15.4089 8.43297 15.4089 7.85373 15.0506 7.49521C14.6921 7.13686 14.1128 7.13686 13.7543 7.49521L10.9998 10.2499L8.24523 7.49521C7.88671 7.13686 7.30747 7.13686 6.94895 7.49521C6.5906 7.85373 6.5906 8.43297 6.94895 8.79149L9.70365 11.546L6.94895 14.3006Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clipDelete">
        <rect
          width="22"
          height="22"
          fill="white"
          transform="matrix(-1 0 0 1 22 0.546021)"
        />
      </clipPath>
    </defs>
  </svg>
);
