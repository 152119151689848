import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  NavLink,
  useParams,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { useProfile, useMediaQuery } from '../../hooks';
import { logout } from '../../api/auth';
import { isSocial } from '../../api';
import Header from '../../shareComponent/header';
import Footer from '../../shareComponent/footer';
import { Loader } from '../../components/loader';
import { ProfilePageWrapper, ProfilePageContainer } from './styled';
import ProfileMain from './main';
import Edit from './edit';
import Password from './password';
import Cards from './cards';
import Payment from './payment';
import Email from './email';

const renderContent = (name, props) => {
  const { profile, id, refetch, isMobile } = props;

  switch (name) {
    case 'edit':
      return (
        <Edit profile={profile} refetch={refetch} id={id} isMobile={isMobile} />
      );
    case 'password':
      return <Password id={id} isMobile={isMobile} />;
    case 'cards':
      return <Cards id={id} isMobile={isMobile} />;
    case 'payment':
      return <Payment id={id} isMobile={isMobile} />;
    case 'email':
      return <Email refetch={refetch} profile={profile} isMobile={isMobile} />;
    default:
      return <ProfileMain isMobile={isMobile} profile={profile} />;
  }
};

const Content = props => {
  const { isMobile, name } = props;
  const { activePath } = useParams();

  if (isMobile) {
    return activePath === name ? renderContent(activePath, props) : null;
  }

  return renderContent(activePath, props);
};

const ProfilePage = props => {
  const [isSocialProfile, setSocialProfile] = useState(false);
  const isMobile = useMediaQuery('small');
  const { path, url, isExact } = useRouteMatch();
  const { profile, loading, error, refetch } = useProfile();
  /* const [activePath, setActivePath] = useState('main'); */
  /* const [currentProfile, setCurrentProfile] = useState(profile || {}); */

  const items = [
    { label: 'My Profile', name: 'main', profilePath: '' },
    { label: 'Edit profile', name: 'edit', profilePath: '/edit' },
    { label: 'Email preferences', name: 'email', profilePath: '/email' },
    { label: 'Change password', name: 'password', profilePath: '/password' },
    /* { label: 'My Cards', name: 'cards', profilePath: '/cards' },
    { label: 'Payment Methods', name: 'payment', profilePath: '/payment' }, */
  ];

  const handleLogout = async () => {
    await logout();

    if (typeof window === 'object') {
      /* if (window.history.state) {
        console.log(window.history.state);
        window.location.pathname = window.history.state;
      } else { */
      window.location.pathname = '/';
      /* } */
    }
  };

  useEffect(() => {
    isSocial()
      .then(res => {
        setSocialProfile(res);
      })
      .catch(err => {
        console.log(err);
        setSocialProfile(false);
      });
  }, [setSocialProfile]);

  return (
    <>
      <ProfilePageContainer>
        <Header noLogin />
        <ProfilePageWrapper>
          {loading ? (
            <div className="profile-page__loader-wrapper">
              <Loader />
            </div>
          ) : profile && !error ? (
            <>
              <div className="profile-page__sidebar-wrapper">
                {!isMobile && (
                  <div className="profile-page__breadcrumbs">
                    <a href="/" className="profile-page__breadcrumbs__link">
                      Home
                    </a>{' '}
                    /{' '}
                    <a
                      href="/profile"
                      className="profile-page__breadcrumbs__link">
                      myshopgizmo
                    </a>
                  </div>
                )}
                <h1 className="profile-page__h1 profile-page__h1_sidebar">
                  MY PROFILE
                </h1>
                <div className="profile-page__sidebar">
                  {items
                    .filter(
                      item => !(isSocialProfile && item.name === 'password'),
                    )
                    .map(({ label, name, profilePath }) => (
                      <div
                        className="profile-page__sidebar__item-wrapper"
                        key={name}>
                        <NavLink
                          key={name}
                          to={`${url}${profilePath}`}
                          exact
                          className={`profile-page__sidebar__item`}
                          activeClassName="profile-page__sidebar__item_active"
                          onClick={
                            name === 'exit'
                              ? e => {
                                  e.preventDefault();

                                  handleLogout();
                                }
                              : () => {}
                          }>
                          {label}
                        </NavLink>
                        {isMobile &&
                          (isExact && name === 'main' ? (
                            renderContent('main', { profile, refetch })
                          ) : (
                            <Switch>
                              <Route path={`${path}/:activePath`}>
                                <Content
                                  isMobile
                                  name={name}
                                  profile={profile}
                                  refetch={refetch}
                                />
                              </Route>
                            </Switch>
                          ))}
                      </div>
                    ))}
                  <a
                    href="/exit"
                    onClick={e => {
                      e.preventDefault();

                      handleLogout();
                    }}
                    className="profile-page__sidebar__item">
                    Logout
                  </a>
                </div>
              </div>
              {!isMobile && (
                <div className="profile-page__content-wrapper">
                  <Switch>
                    <Route exact path={`${path}`}>
                      {renderContent('main', { profile, refetch })}
                    </Route>
                    <Route path={`${path}/:activePath`}>
                      <Content profile={profile} refetch={refetch} />
                    </Route>
                  </Switch>
                </div>
              )}
            </>
          ) : (
            <Redirect to="/login" />
          )}
        </ProfilePageWrapper>
      </ProfilePageContainer>
      <Footer />
    </>
  );
};

export default ProfilePage;
