import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useRecipe, useLink, useTileFirstScreen } from '../../hooks';
import { sendEmail } from '../../api';
import RecipePageContainer from '../../shareComponent/recipePage';
import Recipe from '../../shareComponent/recipe';
import EmailForm from '../../shareComponent/recipe/emailForm';
import ExpiredScreen from '../../components/expiredScreen';
import { Loader } from '../../components/loader';
import Screen404 from '../../components/404';
import Footer from '../../components/footer';
import Modal from '../../components/modal';
import { RecipeEmailWrapper } from './styled';

const getSteps = recipe => {
  if (!recipe.deals) {
    return [];
  }

  return recipe.deals
    .map(
      ({
        step_instructions: content,
        other_restrictions: exclusions,
        ...rest
      }) => ({
        content,
        exclusions,
        ...rest,
      }),
    )
    .sort((curr, prev) => curr.Layer.order - prev.Layer.order);
};

const useQuery = () => new URLSearchParams(useLocation().search);

const RecipePage = ({ match }) => {
  const linkTo = useLink();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const {
    params: { id },
  } = match;
  const query = useQuery();
  const history = useHistory();

  const { loading, error, ...recipe } = useRecipe(id);
  const {
    tileFirstScreen: { enabled, data },
  } = useTileFirstScreen();

  const dev = process.env.NODE_ENV === 'development';
  const { Store } = recipe;

  /* if (loading) {
    return <Loader />;
  } */

  const step =
    query.get('step') ||
    (history.location.state &&
      history.location.state.id === id &&
      history.location.state.step);

  return (
    <>
      <RecipePageContainer
        linkTo={linkTo}
        nobutton={error || loading || recipe.status === 'EXPIRED'}>
        {loading ? (
          <div className="recipe-page__loader-wrapper">
            <Loader />
          </div>
        ) : error || recipe.status === 'EXPIRED' ? (
          error ? (
            <Screen404 />
          ) : (
            <ExpiredScreen />
          )
        ) : (
          <Recipe
            logo_url={
              dev && recipe.logo_url
                ? `http://localhost:3000${recipe.logo_url}`
                : recipe.logo_url
            }
            steps={getSteps(recipe)}
            totalQuantity={recipe.off}
            totalQuantityUSD={recipe.offUSD}
            totalQuantityPoints={recipe.offPoints}
            id={id}
            store={Store}
            shareEmail={() => {
              setShowModal(true);
            }}
            expiredAt={recipe.expiredAt}
            step={step}
            handleSend={sendEmail}
            traceStep={step => {
              history.push(window.location.pathname, { step, id });
            }}
            firstScreen={enabled && data}
          />
        )}
      </RecipePageContainer>
      <Footer />
      {showModal && (
        <Modal handleClose={closeModal}>
          <RecipeEmailWrapper>
            <div className="recipe-email__text">
              Enter the email address you'd like to share this recipe with.
            </div>
            <EmailForm
              totalSavings={recipe.off}
              storeName={(Store && Store.storeName) || ''}
              handleSend={sendEmail}
              submitLabel="Share"
              name="share-recipe-email"
            />
          </RecipeEmailWrapper>
        </Modal>
      )}
    </>
  );
};

export default RecipePage;
