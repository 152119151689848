import React from 'react';
import { Transition } from 'react-transition-group';
import {
  TopSection,
  TopSectionLeft,
  TopSectionRight,
  IllustrationMainWrapper,
  IllustrationMain,
  H1,
  TopSectionButtonPanel,
  TopSectionStarPanel,
  TopSectionStarTextPanel,
  StarWrapper,
  Recipe,
  RecipePanel,
  MainPageContainer,
  TopSectionLogoWrapper,
  Subscribe,
} from '../main/styled';
import { Button } from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import EmailForm from '../../shareComponent/recipe/emailForm';
import Footer from '../../components/footer';
import { StarIcon } from '../../components/icons';
import VideoSection from '../main/videoPanel';
import Trusted from '../main/trusted';
import Slider from '../main/slider';
import Examples from '../main/examples';
import End from '../main/end';
import HiwMobile from '../main/hiwMobile';
import { useMediaQuery } from '../../hooks';
import { sendEmail } from '../../api';

const cardgizmoReatilerItems = [
  { name: 'amazon', src: '/img/retailers/amazon.svg' },
  { name: 'chase', src: '/img/cardgizmo/retailers/chase_logo.svg' },
  {
    name: 'mastercard',
    src: '/img/cardgizmo/retailers/retailer_master_card.svg',
  },
  { name: 'capitalone', src: '/img/cardgizmo/retailers/capitalone.svg' },
  { name: 'macys', src: '/img/cardgizmo/retailers/retailer_macys.svg' },
  { name: 'amex', src: '/img/cardgizmo/retailers/retailer_amex.svg' },
  { name: 'visa', src: '/img/cardgizmo/retailers/retailer_visa.svg' },
  { name: 'sfa', src: '/img/retailers/sfa.png' },
  { name: 'jcp', src: '/img/retailers/jcp.svg' },
  { name: 'target', src: '/img/retailers/target.svg' },
  { name: 'discover', src: '/img/cardgizmo/retailers/retailer_discover.svg' },
];

const Cardgizmo = () => {
  const isSmall = useMediaQuery('small');

  const getStarted = () => {
    if (typeof window === 'object') {
      window.location.href =
        'https://chrome.google.com/webstore/detail/shopgizmocard/dkgmjlofcbeaaoiaojnndcbmmfnfkfdm';
    }
  };

  return (
    <MainPageContainer isSticky>
      <div>
        <Transition in timeout={300}>
          <Header isSticky main getStarted={getStarted} />
        </Transition>
        <TopSection>
          <TopSectionLogoWrapper />
          <TopSectionLeft>
            <div className="top-section__left-wrapper">
              <IllustrationMainWrapper>
                <IllustrationMain
                  src={
                    isSmall
                      ? '/img/illustration_cardgizmo_mobile.svg'
                      : '/img/illustration_cardgizmo.svg'
                  }
                />
              </IllustrationMainWrapper>
            </div>
          </TopSectionLeft>
          <TopSectionRight>
            <div className="top-section__right-content">
              <H1>Optimize which credit card you use at checkout</H1>
              <div className="top-section__text">
                We tell you which credit card from your wallet to use on the
                sites you shop on in order to maximize your rewards
              </div>
              <TopSectionButtonPanel>
                <Button onClick={getStarted}>Get Started</Button>
              </TopSectionButtonPanel>
              <TopSectionButtonPanel>
                <TopSectionStarPanel>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                  <StarWrapper>
                    <StarIcon />
                  </StarWrapper>
                </TopSectionStarPanel>
                <TopSectionStarTextPanel>
                  From 5 of our friends and 3 of our moms
                </TopSectionStarTextPanel>
              </TopSectionButtonPanel>
            </div>
          </TopSectionRight>
        </TopSection>
        {isSmall ? (
          <HiwMobile id="hiw" type="cardgizmo" />
        ) : (
          <VideoSection id="hiw" type="cardgizmo" />
        )}
        <Subscribe>
          <div className="subscribe__wrapper">
            <div className="h1">Hey visitor!</div>
            <div className="h2">Sign up and save more!</div>
            <EmailForm
              placeholder="Enter your email address."
              handleSend={sendEmail}
              subscribe
              source="cardgizmo_main_section"
              name="cardgizmo-subscribe-main"
            />
            <div className="subscribe__text">
              Please leave your e-mail adress to start following all your
              favorite brand sales with Shop GIZMO!
            </div>
          </div>
        </Subscribe>
        <Trusted
          id="retailers"
          items={cardgizmoReatilerItems}
          content={{
            title: 'All Major Credit Cards',
            text: 'More cards and stores supported daily',
          }}
        />
        <RecipePanel id="recipe">
          <Recipe src="/img/recipe_new 2.svg" />
          <Recipe
            className="recipe_mobile"
            src="/img/recipe_mobile_cardgizmo.svg"
          />
        </RecipePanel>
        <Slider type="cardgizmo" />
        <Examples cardgizmo id="example" />
        <End handleGetStarted={getStarted} />
        <Footer page="cardgizmo" />
      </div>
    </MainPageContainer>
  );
};

export default Cardgizmo;
