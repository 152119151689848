import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import PerfectScroll from 'react-perfect-scrollbar';

import {
  RecipeContainer,
  MainSide,
  SavingsSide,
  StepsSide,
  H1,
  MainSideContent,
  MainSideContentWrapper,
  MainSideHeader,
  StepsSideContent,
  ExclusionsContent,
  ExclusionsLink,
  RecipeWrapper,
  TotalSaving,
  TotalSavingPercent,
  Footer,
  SharePanelWrapper,
  FreeShipping,
  MobileStep,
  MobileStepsWrapper,
  EndScreenContainer,
  StepTitle,
  BackButton,
  NextButton,
  ButtonWrapper,
  SendButton,
  TileFirstScreenContainer,
} from './styled';
import { color34 } from '../styled';
import EmailForm from './emailForm';
import SharePanel from './sharePanel';
import Steps from './steps';
import {
  StyledDropdownArrow,
  ScrollDownArrow,
  SavingsIcon,
  FreeShippingIcon,
  RewardPointsIcon,
  CongratulationIcon,
  DollarIcon,
  PercentIcon,
} from './icons';
import renderFromBBCode from '../utils/renderFromBBCode';
import { formatTotalSavings, formatDecimalNumber } from '../utils';
import { useMediaQuery, RecipeContext } from '../hooks';

const sendStepToParent = (val, already) => {
  if (window.parent) {
    const url = new URL(document.location.href);
    url.search = `?step=${val}`;

    window.parent.postMessage(
      {
        type: 'CHANGE_LOCATION',
        location: `${url.href}`,
      },
      '*',
    );
  }
};

const propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      user_facing_layer_name: PropTypes.string,
      exclusions: PropTypes.string,
      quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      is_percent: PropTypes.oneOf([1, 0, 2, 3]),
      is_free_shipping: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      is_grand_rewards_points: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
      ]),
      grand_reward_points: PropTypes.string,
      grand_free_shipping: PropTypes.string,
    }),
  ),
  totalQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalQuantityUSD: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalQuantityPoints: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  logo_url: PropTypes.string,
  id: PropTypes.string,
  store: PropTypes.object,
  expiredAt: PropTypes.string,
  handleSend: PropTypes.func,
  shareEmail: PropTypes.func,
  step: PropTypes.any,
  traceStep: PropTypes.func,
  firstScreen: PropTypes.object,
};

const renderQuantity = ({ is_percent, quantity }) => {
  switch (is_percent) {
    case 1:
      return `${quantity}%`;
    case 0:
      return `$${formatDecimalNumber(quantity)}`;
    case 3:
      return Number(quantity) ? `${quantity} pts` : ' + pts';
    default:
      return '';
  }
};

const emptyClick = () => {};

const Recipe = ({
  steps,
  totalQuantity,
  totalQuantityUSD,
  totalQuantityPoints,
  id,
  store,
  handleSend,
  expiredAt,
  shareEmail,
  step,
  traceStep,
  firstScreen,
}) => {
  const { title, setTitle } = useContext(RecipeContext);
  const storeName = (store && store.storeName) || '';
  const isSmall = useMediaQuery('small');
  const [stateIndex, setStateIndex] = useState(0);
  const [showExclusions, setShowExclusions] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [showFirstScreen, setShowFirstScreen] = useState(firstScreen);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const exclusionsContentRef = useRef(null);
  const scrollRef = useRef(null);

  const setStateIndexWrapper = useCallback(
    index => {
      sendStepToParent(index);

      if (traceStep) {
        traceStep(index);
      } else {
        setStateIndex(index);
      }
    },
    [setStateIndex, traceStep],
  );

  const handleNextStep = () => {
    if (steps.length > stateIndex + 1) {
      setStateIndexWrapper(stateIndex + 1);
    } else {
      setShowCongratulations(true);
    }
  };

  const handleBackStep = useCallback(() => {
    if (stateIndex - 1 >= 0) {
      setStateIndexWrapper(stateIndex - 1);
    }
  }, [setStateIndexWrapper, stateIndex]);

  const handleStepClick = index => {
    if (steps.length < index) {
      setShowCongratulations(true);
    } else {
      setStateIndexWrapper(index);
    }
  };

  const isPts = useCallback(
    () =>
      steps.reduce(
        (acc, val) => {
          if (val.is_percent === 3) {
            acc.pts = true;
          }

          if (val.is_free_shipping) {
            acc.free_shipping = true;
          }

          if (val.is_grand_rewards_points) {
            acc.reward = true;
          }

          return acc;
        },
        { pts: false, reward: false, free_shipping: false },
      ),
    [steps],
  );

  const { pts, free_shipping, reward } = isPts();

  useEffect(() => {
    if (showExclusions) {
      const curr = isSmall
        ? scrollRef.current._ps.contentHeight -
          exclusionsContentRef.current.offsetHeight * 1.5
        : scrollRef.current._ps.contentHeight -
          (exclusionsContentRef.current.offsetHeight + 56);
      scrollRef.current._container.scrollTop = curr;
    }
  }, [showExclusions, isSmall, scrollRef, setShowScrollDown]);

  useEffect(() => {
    setShowExclusions(false);

    if (scrollRef && scrollRef.current) {
      scrollRef.current.updateScroll();

      const contentHeight = scrollRef.current._container.scrollHeight;
      const containerHeight = scrollRef.current._container.offsetHeight;

      if (containerHeight < contentHeight) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    }
  }, [stateIndex, setShowScrollDown, scrollRef]);

  useEffect(() => {
    if (steps[stateIndex]) {
      const { user_facing_layer_name, is_percent, quantity } = steps[
        stateIndex
      ];
      setTitle(
        `${user_facing_layer_name} ${renderQuantity({ is_percent, quantity })}`,
      );
    }
  }, [stateIndex, setTitle, steps]);

  useEffect(() => {
    if (traceStep && step !== stateIndex) {
      setStateIndex((step && parseInt(step)) || 0);
    }
  }, [step, setStateIndex, stateIndex, traceStep]);

  useEffect(() => {
    setShowFirstScreen(firstScreen);
  }, [firstScreen, setShowFirstScreen]);

  const formattedTotalSavings = formatTotalSavings({
    totalQuantity,
    totalQuantityUSD,
    totalQuantityPoints,
    pts,
  });

  const updateScrollDown = useCallback(
    val => {
      if (scrollRef && scrollRef.current) {
        const contentHeight = scrollRef.current._container.scrollHeight;
        const containerHeight = scrollRef.current._container.offsetHeight;
        const { scrollTop } = scrollRef.current._container;

        if (containerHeight < contentHeight) {
          setShowScrollDown(
            scrollTop + 5 <= contentHeight - containerHeight ? val : false,
          );
        }
      }
    },
    [scrollRef, setShowScrollDown],
  );

  const showDollarPrimarySavings = useCallback(() => {
    if (totalQuantityUSD) {
      if (!totalQuantity || (totalQuantityUSD > 5 && totalQuantity < 10)) {
        return true;
      }
    }

    return false;
  }, [totalQuantityUSD, totalQuantity]);

  return (
    <RecipeWrapper
      nologo
      onClick={() => {
        if (isSmall) {
          updateScrollDown(true);
        }
      }}
      showCongratulations={showCongratulations}>
      <RecipeContainer>
        {showFirstScreen ? (
          <TileFirstScreenContainer>
            <a
              href="/"
              onClick={e => {
                e.preventDefault();
                setShowFirstScreen(false);
              }}
              className="tile-first-screen-container__close">
              +
            </a>
            <div className="tile-first-screen-container__content-wrapper">
              <PerfectScroll>{renderFromBBCode(showFirstScreen)}</PerfectScroll>
            </div>
            <div className="tile-first-screen-container__buttons-wrapper">
              <NextButton
                onClick={e => {
                  e.preventDefault();
                  setShowFirstScreen(false);
                }}>
                NEXT
              </NextButton>
            </div>
          </TileFirstScreenContainer>
        ) : showCongratulations ? (
          <EndScreenContainer>
            <div className="end-screen__image-wrapper">
              <CongratulationIcon className="end-screen__image" />
            </div>
            <h1 className="end-screen__h1">CONGRATULATIONS!</h1>
            <div className="end-screen__buttons-wrapper">
              <BackButton onClick={() => setShowCongratulations(false)}>
                BACK
              </BackButton>
              <NextButton
                onClick={() => {
                  if (typeof window === 'object') {
                    window.location.href = '/deals';
                  }
                }}>
                BROWSE MORE DEALS
              </NextButton>
            </div>
            <div className="end-screen__bottom-wrapper">
              <div className="end-screen__email-form-wrapper">
                <div className="end-screen__text">SHARE WITH YOUR FRIENDS</div>
                <div className="end-screen__email-form">
                  <EmailForm
                    id={id}
                    totalSavings={totalQuantity}
                    storeName={storeName}
                    handleSend={handleSend}
                    name="share-recipe-email"
                  />
                </div>
                <div className="end-screen__shared-wrapper">
                  <SharePanel
                    show
                    savings={formattedTotalSavings}
                    store={storeName}
                    date={expiredAt}
                    color={color34}
                  />
                </div>
              </div>
            </div>
          </EndScreenContainer>
        ) : (
          <>
            <MainSide>
              <div className="main-side__container">
                <MainSideHeader>
                  <H1>
                    <b>{storeName}</b> Saving Guide
                  </H1>
                </MainSideHeader>
                <div className="main-side__steps">
                  {steps && !isSmall && (
                    <StepsSide>
                      <StepsSideContent>
                        <Steps
                          current={stateIndex}
                          handleClick={handleStepClick}
                          steps={steps}
                        />
                      </StepsSideContent>
                    </StepsSide>
                  )}
                  <MainSideContentWrapper>
                    <div className="main-side__content-wrapper__title">
                      {isSmall && (
                        <StepTitle>STEP {parseInt(stateIndex) + 1}</StepTitle>
                      )}
                      <StepTitle>{title}</StepTitle>
                    </div>
                    <div
                      onMouseEnter={
                        isSmall ? () => {} : () => updateScrollDown(false)
                      }
                      onMouseLeave={
                        isSmall ? () => {} : () => updateScrollDown(true)
                      }
                      className="main-side__content-wrapper__content">
                      <PerfectScroll
                        ref={scrollRef}
                        onScrollY={
                          isSmall
                            ? () => {
                                if (showScrollDown) {
                                  updateScrollDown(false);
                                }
                              }
                            : () => {}
                        }>
                        <MainSideContent>
                          {steps.length > 0 &&
                            steps[stateIndex] &&
                            steps[stateIndex].content &&
                            renderFromBBCode(steps[stateIndex].content)}
                          {steps.length > 0 &&
                            steps[stateIndex] &&
                            !!steps[stateIndex].is_free_shipping && (
                              <FreeShipping>
                                <div className="free-shipping__title">
                                  This deal grants FREE SHIPPING
                                </div>
                                <div className="free-shipping__text">
                                  {!!steps[stateIndex].grand_free_shipping &&
                                    renderFromBBCode(
                                      steps[stateIndex].grand_free_shipping,
                                    )}
                                </div>
                              </FreeShipping>
                            )}
                          {steps.length > 0 &&
                            steps[stateIndex] &&
                            !!steps[stateIndex].is_grand_rewards_points && (
                              <FreeShipping>
                                <div className="free-shipping__title">
                                  This deal grants REWARD POINTS
                                </div>
                                <div className="free-shipping__text">
                                  {!!steps[stateIndex].grand_reward_points &&
                                    renderFromBBCode(
                                      steps[stateIndex].grand_reward_points,
                                    )}
                                </div>
                              </FreeShipping>
                            )}
                          {steps.length > 0 &&
                            !!steps[stateIndex] &&
                            !!steps[stateIndex].exclusions && (
                              <ExclusionsLink
                                onClick={() => {
                                  setShowExclusions(!showExclusions);
                                }}>
                                {`Exclusions`}{' '}
                                <StyledDropdownArrow
                                  active={!!showExclusions}
                                />
                              </ExclusionsLink>
                            )}
                          {showExclusions && (
                            <ExclusionsContent
                              ref={exclusionsContentRef}
                              active={!!showExclusions}>
                              {steps[stateIndex].exclusions &&
                                renderFromBBCode(steps[stateIndex].exclusions)}
                            </ExclusionsContent>
                          )}
                        </MainSideContent>
                      </PerfectScroll>
                      {showScrollDown && (
                        <div className="scroll-down__arrow-wrapper">
                          <ScrollDownArrow />
                        </div>
                      )}
                    </div>
                    <ButtonWrapper>
                      <BackButton
                        inactive={stateIndex === 0}
                        onClick={handleBackStep}>
                        BACK
                      </BackButton>
                      <NextButton onClick={handleNextStep}>NEXT</NextButton>
                    </ButtonWrapper>
                  </MainSideContentWrapper>
                </div>
                <Footer>
                  {isSmall && (
                    <MobileStepsWrapper>
                      {steps &&
                        steps.map((item, index) => (
                          <MobileStep
                            key={index}
                            active={index === stateIndex}
                          />
                        ))}
                    </MobileStepsWrapper>
                  )}
                  {/* <EmailForm
                    id={id}
                    totalSavings={formattedTotalSavings}
                    storeName={storeName}
                    handleSend={handleSend}
                  /> */}
                </Footer>
              </div>
            </MainSide>
            <SavingsSide>
              <TotalSaving>
                <div className="total-savings__content">
                  {!isSmall && <SavingsIcon />}
                  <div className="total-savings__label">Total savings:</div>
                  <TotalSavingPercent>
                    {showDollarPrimarySavings()
                      ? `${totalQuantityUSD}$`
                      : totalQuantity
                      ? `${totalQuantity}%`
                      : ''}
                  </TotalSavingPercent>
                </div>
                <div className="total-savings__additional-wrapper">
                  {!!totalQuantityUSD && !showDollarPrimarySavings() && (
                    <div className="total-savings__additional total-savings__additional_second">
                      <DollarIcon />
                      <div className="total-savings__additional__label">
                        {`+ ${formatDecimalNumber(totalQuantityUSD)} ${
                          totalQuantityUSD > 1 ? 'dollars' : 'dollar'
                        }`}
                      </div>
                    </div>
                  )}
                  {!!totalQuantity && showDollarPrimarySavings() && (
                    <div className="total-savings__additional total-savings__additional_second">
                      <PercentIcon />
                      <div className="total-savings__additional__label">
                        {`+ ${totalQuantity}% Off`}
                      </div>
                    </div>
                  )}
                  {free_shipping && (
                    <div className="total-savings__additional total-savings__additional_first">
                      <FreeShippingIcon />
                      <div className="total-savings__additional__label">
                        + Free Shipping
                      </div>
                    </div>
                  )}
                  {reward && (
                    <div className="total-savings__additional total-savings__additional_last">
                      <RewardPointsIcon />
                      <div className="total-savings__additional__label">
                        + Reward Points
                      </div>
                    </div>
                  )}
                </div>
                <SharePanelWrapper>
                  <SharePanel
                    show
                    savings={formattedTotalSavings}
                    store={storeName}
                    date={expiredAt}
                  />
                  <SendButton onClick={shareEmail || emptyClick}>
                    Share via Email
                  </SendButton>
                </SharePanelWrapper>
              </TotalSaving>
            </SavingsSide>
          </>
        )}
      </RecipeContainer>
    </RecipeWrapper>
  );
};

Recipe.propTypes = propTypes;

export default Recipe;
