import React, { useEffect, useRef } from 'react';
import PaymentCardTypes from './paymentCardTypes';

const ProfileMain = ({ isMobile, profile }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3">
        {profile.firstName} {profile.lastName}
      </h3>
      <div className="profile-page__content-main__main-info">
        <div className="profile-page__content-main__col">
          <h4 className="profile-page__h4 profile-page__content-main__main-info__label">
            Contacts
          </h4>
          <div className="profile-page__content-main__email-wrapper">
            <div className="profile-page__content-main__label">E-mail</div>
            {profile.email}
          </div>
          <div className="profile-page__content-main__main-info__card-wrapper">
            <PaymentCardTypes
              paymentCardTypes={(profile && profile.paymentCardTypes) || []}
            />

            {/* <div className="profile-page__h4 profile-page__h4_payment">
              Payments Method
            </div>
            <div className="profile-page__content-main__card-item">
              <span className="profile-page__content-main__card-item__icon__wrapper">
                <img src="/img/creditcard.svg" alt="card icon" />
              </span>
              NO AVAILABLE CARDS
            </div> */}
          </div>
        </div>
        <div className="profile-page__content-main__col">
          {/* <div className="profile-page__content-main__main-info__gift-cards">
            <div className="profile-page__content-main__main-info__gift-cards__header">
              Your Gift Card{' '}
              <span className="profile-page__content-main__main-info__gift-cards__header__text">
                (no available)
              </span>
            </div>
            <div className="profile-page__content-main__main-info__gift-cards-list">
              <div className="profile-page__content-main__main-info__gift-card">
                <span className="profile-page__content-main__main-info__gift-card__value">
                  You have no cards
                </span>
                <span className="profile-page__content-main__main-info__gift-card__number">
                  none
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileMain;
