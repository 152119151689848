import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteSlider from 'infinite-react-carousel';

import {
  cardgizmoTestimonialSlides,
  defaultTestimonialSlides,
} from '../../../content';
import { SliderWrapper } from './styled';
import { H1 } from '../styled';

const propTypes = {
  type: PropTypes.string,
};

const Slider = ({ type }) => {
  const sliderContainer = useRef(null);

  return (
    <SliderWrapper>
      <div ref={sliderContainer} className="slider__container">
        <InfiniteSlider
          autoplaySpeed={5000}
          autoplay
          dots
          arrows={false}
          arrowBlock={false}>
          {(type === 'cardgizmo'
            ? cardgizmoTestimonialSlides
            : defaultTestimonialSlides
          ).map((item, index) => (
            <div
              key={index}
              className="slider__slide"
              style={{
                width:
                  '0px' &&
                  sliderContainer &&
                  sliderContainer.current &&
                  `${sliderContainer.current.offsetWidth}px`,
              }}>
              <div className="slider__slide__content">
                <div
                  className={`slide__text ${
                    index === 2 ? 'slide__text_wide' : ''
                  }`}>
                  <H1>
                    <q className="slider__slide__quote">{item.text}</q>
                  </H1>
                  <div className="slider__slide__sign">{item.sign}</div>
                </div>
              </div>
            </div>
          ))}
        </InfiniteSlider>
      </div>
    </SliderWrapper>
  );
};

Slider.propTypes = propTypes;

export default Slider;
