import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import RecipePage from './pages/recipe';
import MainPage from './pages/main';
import DealsPage from './pages/deals';
import ProfilePage from './pages/profile';
import LoginPage from './pages/login';
import PrivacyPage from './pages/privacy';
// import AboutPage from './pages/about';
// import FAQPage from './pages/faq';
import Page404 from './pages/404';
import Terms from './pages/terms';
import UnsubscribePage from './pages/unsubscribe';
import ExtensionPage from './pages/extension';
import TimeoutEmailPopup from './components/timeoutEmailPopup';
import InstallExtension from './pages/installExtension';
import CreateUserPage from './pages/createUser';
import ForgotPassword from './pages/forgotPassword';
import CardgizmoPage from './pages/cardgizmo';
import FAQCardgizmoPage from './pages/faqCardgizmo';
import AboutCardgizmo from './pages/aboutCardgizmo';

import 'react-perfect-scrollbar/dist/css/styles.css';

const renderPage = (Component, inFrame) =>
  function WrappedComponent(props) {
    useEffect(() => {
      if (window.parent !== window) {
        window.parent.postMessage(
          { type: 'CHANGE_LOCATION', location: document.location.href },
          '*',
        );
      }
    }, []);

    return (
      <>
        <Component {...props} inFrame={!!inFrame} />
        {!inFrame && <TimeoutEmailPopup />}
      </>
    );
  };

function App() {
  const inFrame = window && window.parent !== window;

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={CardgizmoPage} />
          <Route path="/deals" component={renderPage(DealsPage, inFrame)} />
          <Route path="/dealgizmo" component={renderPage(MainPage, inFrame)} />
          <Route
            path="/recipe/:id"
            component={renderPage(RecipePage, inFrame)}
          />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={() => <ForgotPassword />} />
          <Route
            path="/reset-password"
            component={() => <ForgotPassword resetPassword />}
          />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/about" component={AboutCardgizmo} />
          <Route path="/faq" component={FAQCardgizmoPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/unsubscribe" component={UnsubscribePage} />
          <Route
            exact
            path="/extension"
            component={renderPage(ExtensionPage, inFrame)}
          />
          <Route path="/extension/:action" component={InstallExtension} />
          <Route path="/user/create" component={CreateUserPage} />
          <Route path="*" exact component={Page404} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
