import React, { useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import {
  DealsContainer,
  DealsContentWrapper,
  DealsMobileLayout,
  MobileSorts,
  MobileTopPanel,
  MobileTopPanelFilter,
  MobileTopPanelFiltersWrapper,
  MobileTopPanelSort,
  SortIcon,
} from './styled';
import Header from '../../shareComponent/header';
import { ArrowDown, ArrowUp } from '../../components/icons';
import Filters from '../../components/filters';
import SortPanel from '../../components/sortPanel';

const getKeysVal = obj => Object.entries(obj)[0];

export const DealsMobile = ({
  label,
  stores,
  store,
  changeStore,
  changeFilterObject,
  sort,
  children,
  changeOneTimeUseOnly,
  isOnlyOne,
  inFrame,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showMobileSorts, setShowMobileSorts] = useState(false);
  const sortMobileButtonRef = useRef(null);
  const sortWrapperRef = useRef(null);
  const filterMobileButtonRef = useRef(null);
  const filterWrapperRef = useRef(null);

  const onChange = item => {
    if (item.name === 'store') {
      changeStore(item.value);
    } else if (item.name === 'is_first_time_use_only') {
      changeOneTimeUseOnly(item.value);
    }
  };

  useEffect(() => {
    if (
      typeof window === 'object' &&
      sortWrapperRef &&
      filterWrapperRef &&
      sortWrapperRef.current &&
      filterWrapperRef.current
    ) {
      const outsideFiltersClick = e => {
        if (filterWrapperRef && filterMobileButtonRef) {
          const { current } = filterWrapperRef;

          if (
            current &&
            !current.contains(e.target) &&
            filterMobileButtonRef.current !== e.target
          ) {
            setShowMobileFilters(false);
          }
        }
      };

      const outsideSortsClick = e => {
        if (sortWrapperRef && sortMobileButtonRef) {
          const { current } = sortWrapperRef;

          if (
            current &&
            !current.contains(e.target) &&
            e.target !== sortMobileButtonRef.current
          ) {
            setShowMobileSorts(false);
          }
        }
      };

      document.body.addEventListener('click', outsideFiltersClick);
      document.body.addEventListener('click', outsideSortsClick);

      return () => {
        document.body.removeEventListener('click', outsideFiltersClick);
        document.body.removeEventListener('click', outsideSortsClick);
      };
    }
  }, [
    showMobileSorts,
    showMobileFilters,
    filterWrapperRef,
    sortWrapperRef,
    filterMobileButtonRef,
    sortMobileButtonRef,
  ]);

  return (
    <DealsMobileLayout>
      <Header logoLink={inFrame ? '/dealgizmo' : '/'} />
      <DealsContentWrapper>
        <MobileTopPanel>
          <div className="mobile-top-panel__left">
            <MobileTopPanelSort
              ref={sortMobileButtonRef}
              active={!!showMobileSorts}
              onClick={() => {
                setShowMobileSorts(!showMobileSorts);
              }}>
              {label}
              <SortIcon
                active={getKeysVal(sort) && getKeysVal(sort)[1] === 'ASC'}>
                <ArrowDown />
              </SortIcon>
              <SortIcon
                active={getKeysVal(sort) && getKeysVal(sort)[1] === 'DESC'}>
                <ArrowUp />
              </SortIcon>
            </MobileTopPanelSort>
          </div>
          <div className="mobile-top-panel__right">
            <MobileTopPanelFilter
              ref={filterMobileButtonRef}
              active={!!showMobileFilters}
              onClick={() => {
                setShowMobileFilters(!showMobileFilters);
              }}>
              Filters
            </MobileTopPanelFilter>
          </div>
          <Transition in={!!showMobileFilters} timeout={300}>
            <MobileTopPanelFiltersWrapper
              ref={filterWrapperRef}
              show={!!showMobileFilters}>
              <Filters
                items={stores}
                currItems={store}
                isOnlyOne={isOnlyOne}
                onChange={onChange}
              />
            </MobileTopPanelFiltersWrapper>
          </Transition>
          <Transition in={!!showMobileSorts} timeout={300}>
            <MobileTopPanelFiltersWrapper
              ref={sortWrapperRef}
              show={!!showMobileSorts}>
              <MobileSorts>
                <SortPanel
                  sort={sort}
                  onChange={val => changeFilterObject('sort', val)}
                />
              </MobileSorts>
            </MobileTopPanelFiltersWrapper>
          </Transition>
        </MobileTopPanel>
        <DealsContainer>{children}</DealsContainer>
      </DealsContentWrapper>
    </DealsMobileLayout>
  );
};
