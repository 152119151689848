import React, { useState, useCallback, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useValidation, useUpdateProfile } from '../../../hooks';
import Toast from '../../../shareComponent/recipe/toast';

import Dropdown from '../../../components/dropdown';
import { ProfilePageEditForm } from './styled';

const Edit = ({ profile, refetch, isMobile }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && ref && ref.current) {
      const headerOffset = 70;
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [isMobile, ref]);

  const [currentProfile, setCurrentProfile] = useState(
    profile || {
      firstName: '',
      lastName: '',
      email: '',
      sex: '',
      birthday: '',
    },
  );
  const {
    validate,
    validateAt,
    errors: validationErrors,
    isValid,
  } = useValidation('profileUpdate', true);

  const { isUpdated, updateUserProfile, reset } = useUpdateProfile({});

  const { firstName, lastName, email, sex, birthday } = currentProfile;

  const handleChange = useCallback(
    e => {
      const {
        target: { name, value },
      } = e;

      setCurrentProfile({ ...currentProfile, [name]: value });
    },
    [currentProfile, setCurrentProfile],
  );

  const handleBlur = useCallback(
    e => {
      const {
        target: { name },
      } = e;

      validateAt(name, currentProfile);
    },
    [validateAt, currentProfile],
  );

  const handleSetBirthday = date => {
    setCurrentProfile({ ...currentProfile, birthday: date });
  };

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isValid) {
        await updateUserProfile(currentProfile);
        refetch();
        //window.location.reload();
      } else {
        await validate(currentProfile);
      }
    },
    [validate, isValid, currentProfile, updateUserProfile, refetch],
  );

  const success = isUpdated.error
    ? `Data is not changed!`
    : `Data changed successfully!`;

  return (
    <div ref={ref} className="profile-page__content-main">
      <h3 className="profile-page__h3 profile-page__h3_hidden_mobile">
        Edit profile
      </h3>
      <ProfilePageEditForm onSubmit={handleSubmit}>
        {isUpdated.error && (
          <div className="profile-page__edit-update-error">
            Data is not saved!
          </div>
        )}
        <div className="profile-page__edit-form__col">
          <div className="profile-page__input profile-page__edit-form__input">
            <label htmlFor="firstName" className="profile-page__input__label">
              First Name
              <input
                type="text"
                name="firstName"
                className={`profile-page__input__text ${
                  validationErrors['firstName']
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={handleChange}
                value={firstName}
                onBlur={handleBlur}
              />
            </label>
            {!!validationErrors['firstName'] && (
              <span className="profile-page__input__text-error">
                {validationErrors['firstName']}
              </span>
            )}
          </div>
          <div className="profile-page__input profile-page__edit-form__input">
            <label htmlFor="lastName" className="profile-page__input__label">
              Last Name
              <input
                type="text"
                name="lastName"
                className={`profile-page__input__text ${
                  validationErrors['lastName']
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={handleChange}
                value={lastName}
                onBlur={handleBlur}
              />
            </label>
            {!!validationErrors['lastName'] && (
              <span className="profile-page__input__text-error">
                {validationErrors['lastName']}
              </span>
            )}
          </div>
          <div
            className={`profile-page__input profile-page__edit-form__input ${
              validationErrors['sex'] ? 'profile-page__input_error' : ''
            }`}>
            <label htmlFor="sex" className="profile-page__input__label">
              Sex
              <Dropdown
                profile-page__input__text
                onChange={({ value }) => {
                  handleChange({ target: { name: 'sex', value } });
                }}
                onBlur={handleBlur}
                placeholder="Mr/Mrs"
                value={sex}
                name="sex"
                options={[
                  { label: 'Ms', value: 'm' },
                  { label: 'Mrs', value: 'f' },
                ]}
                controlClassName="profile-page__input__text"
              />
            </label>
            {!!validationErrors['sex'] && (
              <span className="profile-page__input__text-error">
                {validationErrors['sex']}
              </span>
            )}
          </div>
          {!isMobile && (
            <div className="profile-page__edit-form__button-wrapper">
              <button className="profile-page__button" type="submit">
                SAVE
              </button>
            </div>
          )}
        </div>
        <div className="profile-page__edit-form__col">
          <div className="profile-page__input profile-page__edit-form__input">
            <label htmlFor="email" className="profile-page__input__label">
              E-mail
              <input
                type="text"
                name="email"
                className={`profile-page__input__text ${
                  validationErrors['email']
                    ? 'profile-page__input__text_error'
                    : ''
                }`}
                onChange={() => {}}
                value={email}
                disabled
              />
            </label>
            {!!validationErrors['email'] && (
              <span className="profile-page__input__text-error">
                {validationErrors['email']}
              </span>
            )}
          </div>
          <div className="profile-page__input profile-page__edit-form__input">
            <label htmlFor="birthday" className="profile-page__input__label">
              Birthday
              <DatePicker
                selected={
                  birthday ? new Date(birthday) : new Date('01/01/1980')
                }
                onChange={handleSetBirthday}
                showYearDropdown
              />
            </label>
          </div>
          <div className="profile-page__edit-form__text">
            This information is used determine the services available for your
            account
          </div>
          {isMobile && (
            <div className="profile-page__edit-form__button-wrapper">
              <button className="profile-page__button" type="submit">
                SAVE
              </button>
            </div>
          )}
        </div>
        <Toast show={!!isUpdated.id} hideToast={() => reset()}>
          {success}
        </Toast>
      </ProfilePageEditForm>
    </div>
  );
};

export default Edit;
