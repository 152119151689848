import React from 'react';

import {
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import { Container } from '../../shareComponent/recipePage/styled';
import Footer from '../../components/footer';
import { AboutWrapper, AboutContainer } from '../about/styled';
import AboutHiImage from '../about/aboutHiImage';

import { useLink } from '../../hooks';

const AboutCardgizmo = () => {
  const linkTo = useLink();

  return (
    <>
      <AboutContainer>
        <Header activeMenu="about" />
        <PageTitleContainer>ABOUT US</PageTitleContainer>
        <Container>
          <NavMenu>
            <NavMenuItem
              onClick={e => {
                e.preventDefault();
                linkTo('/');
              }}
              href="/">
              Home
            </NavMenuItem>{' '}
            / <NavMenuItem active>About Us</NavMenuItem>
          </NavMenu>
          <AboutWrapper>
            <h1 className="about__page-title">ABOUT US</h1>
            <div className="about__content-wrapper">
              <div className="about__image-wrapper">
                <AboutHiImage />
              </div>
              <p className="about__paragraph">
                ShopGizmo’s CardGizmo is a simple tool to help optimize which
                credit cards to use at which stores in order to get the most
                rewards.
              </p>
              <p className="about__paragraph">
                It’s a browser extension that maps a credit card’s discount
                categories to the stores you’re visiting online. That’s how it
                figures out which card is best.
              </p>
              <p className="about__paragraph">
                If you update your profile on our site to include which cards
                you have, it will tell you which cards of yours to use. If you
                don’t, it will list the top cards in our database.
              </p>
              <p className="about__paragraph">Good luck and shop smart!</p>

              <p className="about__sign">
                <span className="about__bold">- ShopGizmo -</span>
              </p>
            </div>
          </AboutWrapper>
        </Container>
      </AboutContainer>
      <Footer />
    </>
  );
};

export default AboutCardgizmo;
