import React from 'react';
import LoginFacebook from '../../components/loginFacebook';
import LoginForm from '../../components/loginForm';
import Header from '../../shareComponent/header';
import Footer from '../../shareComponent/footer';
import { LoginPageWrapper, LoginPageContentWrapper } from './styled';

const LoginPage = () => {
  return (
    <>
      <LoginPageWrapper>
        <Header noLogin />
        <LoginPageContentWrapper>
          <div className="login-page__h1">PLEASE LOG IN.</div>
          <div className="login-page__content">
            <div className="login-page__col">
              <div className="login-page__form-wrapper">
                <LoginForm />
              </div>
            </div>
            <div className="login-page__col login-page__col_or">or</div>
            <div className="login-page__col login-page__col_facebook">
              <div className="login-page__facebook">
                <div className="login-page__h3">log in to shopgizmo with</div>
                <LoginFacebook />
              </div>
            </div>
          </div>
        </LoginPageContentWrapper>
      </LoginPageWrapper>
      <Footer />
    </>
  );
};

export default LoginPage;
