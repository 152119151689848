import React from 'react';

import { ProfileLinkWrapper } from './styled';

const ProfileLink = ({ profile, loading, main }) => (
  <ProfileLinkWrapper
    to={{
      pathname: profile ? `/profile` : `/login`,
      state: window.location.pathname,
    }}
    main={(!!main).toString()}>
    {loading
      ? 'Loading...'
      : profile
      ? `${profile.firstName}`
      : `Sign Up / Login`}
  </ProfileLinkWrapper>
);

export default ProfileLink;
