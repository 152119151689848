import styled from 'styled-components';
import { color8, color38 } from '../../../shareComponent/styled';

export const CheckboxContainer = styled.div`
  display: inline-block;
  align-items: center;
  width: 11px;
  height: 11px;
  line-height: 13px;
  border: 1px solid ${({ checked }) => (checked ? color38 : color8)};
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  background-color: ${({ checked }) => (checked ? color38 : 'transparent')};
`;

export const CheckboxIconWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  vertical-align: top;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  display: inline-block;
  vertical-align: top;
  padding-left: 5px;
  max-width: 16em;
`;
