import React from 'react';
import PropTypes from 'prop-types';

import { EndContainer, EndTitle, EndButtonPanel } from './styled';
import { H1 } from '../styled';
import { Button } from '../../../shareComponent/styled';

const propTypes = {
  handleGetStarted: PropTypes.func,
}

const End = ({ handleGetStarted }) => {
  return (
    <EndContainer>
      <EndTitle>
        <H1>Ready to start saving more, no strings attached?</H1>
      </EndTitle>
      <EndButtonPanel>
        <Button onClick={handleGetStarted || (() => {})}>Get Started</Button>
      </EndButtonPanel>
    </EndContainer>
  );
};

End.propTypes = propTypes;

export default End;
