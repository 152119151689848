import styled from 'styled-components';

import { color11 } from '../colors';

export const LogoPanel = styled.div`
  width: 116px;
  height: 66px;
  text-align: left;
  position: absolute;

  a.link {
    text-decoration: none !important;
  }
`;

export const LogoUpText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: ${color11};
`;

export const LogoDownText = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  text-transform: capitalize;
  color: ${color11};
`;

export const LogoImage = styled.img`
  display: block;
  outline: none;
  margin: auto;
  padding: 0;
  @media screen and (max-width: 1024px) {
    width: auto;
    height: 55px;
  }
`;
