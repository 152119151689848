import styled from 'styled-components';
import {
  color1,
  color11,
  color17,
  color18,
  color20,
  color48,
  color58,
  color60,
  color61,
  color62,
  color63,
} from '../../../shareComponent/styled';

export const ProfilePageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  * {
    box-sizing: border-box;
  }
`;

export const ProfilePageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding-top: 10px;
  background-color: ${color60};
  min-height: calc(100vh - 90px);
  box-sizing: border-box;
  align-content: flex-start;
  * {
    box-sizing: border-box;
  }

  .profile-page__loader-wrapper {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    padding-top: 40px;
    align-items: center;
  }

  .profile-page__breadcrumbs {
    color: ${color17};
    width: 238px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    padding-bottom: 68px;
  }

  .profile-page__breadcrumbs__link {
    color: ${color17};
    text-decoration: none;
    :visited {
      color: ${color17};
      text-decoration: none;
    }
  }

  .profile-page__h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 26px;
    line-height: 27px;
    text-transform: uppercase;
    color: ${color11};
  }

  .profile-page__h1_sidebar {
    margin-bottom: 36px;
    width: 238px;
  }

  .profile-page__h3 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 22px;
    line-height: 100%;
    color: ${color11};
    width: 100%;
  }

  .profile-page__h4 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
  }

  .profile-page__h4_payment {
    padding-bottom: 10px;
  }

  .profile-page__sidebar-wrapper {
    background-color: ${color1};
    width: 460px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    padding-top: 36px;
    padding-bottom: 187px;
  }

  .profile-page__sidebar {
    background-color: ${color61};
    overflow: hidden;
    border-radius: 5px;
    width: 238px;
    margin: auto;
  }

  .profile-page__sidebar__item-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid ${color58};
    :last-child {
      border-bottom: 0px solid ${color58};
    }
  }

  .profile-page__sidebar__item {
    display: inline-block;
    padding: 25px 35px;
    height: 65px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    text-decoration: none !important;
    color: ${color11};
  }

  .profile-page__sidebar__item_active {
    color: ${color1};
    background-color: ${color20};
    border-bottom-color: ${color20};
  }

  .profile-page__content-wrapper {
    display: flex;
    flex-flow: row wrap;
    flex: 1 0;
    min-height: 100%;
    padding-top: 196px;
    justify-content: center;
  }

  .profile-page__content-main {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    width: 779px;
  }

  .profile-page__content-main__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    min-height: 1em;
    color: ${color62};
    padding-top: 4px;
  }

  .profile-page__content-main__main-info {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding-top: 44px;
  }

  .profile-page__content-main__main-info__label {
    padding-bottom: 6px;
  }

  .profile-page__content-main__main-info__gift-cards {
    background: ${color1};
    border: 1px solid ${color58};
    overflow: hidden;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 214px;
  }

  .profile-page__content-main__main-info__gift-cards__header {
    background-color: ${color61};
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: ${color11};
    padding: 19px 31px;
  }

  .profile-page__content-main__main-info__gift-cards__header__text {
    font-weight: normal;
  }

  .profile-page__content-main__main-info__gift-cards-list {
    padding-top: 27px;
    width: 100%;
  }

  .profile-page__content-main__main-info__gift-card {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid ${color58};
    width: 283px;
    margin: auto;
  }

  .profile-page__content-main__main-info__gift-card__value {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
  }

  .profile-page__content-main__main-info__gift-card__number {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 15.7203px;
    line-height: 19px;
    color: ${color11};
  }

  .profile-page__content-main__col {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0;
  }

  .profile-page__content-main__email-wrapper {
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
  }

  .profile-page__content-main__main-info__card-wrapper {
    width: 100%;
    padding-top: 68px;
  }

  .profile-page__content-main__card-item {
    display: flex;
    align-items: center;
    width: 350px;
    padding: 18px 21px 18px 28px;
    font-weight: 500;
    font-size: 15.5122px;
    line-height: 19px;
    color: ${color63};
    background: ${color1};
    border: 1px solid ${color58};
    border-radius: 5px;
  }

  .profile-page__content-main__card-item__number {
    font-weight: normal;
    font-size: 15.5122px;
    line-height: 18px;
    color: ${color11};
    opacity: 0.2;
  }

  .profile-page__content-main__card-item__icon__wrapper {
    display: inline-block;
    width: 19px;
    height: 13px;
    margin-right: 15.5px;
    line-height: 19px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
      outline: none;
    }
  }

  .profile-page__input {
    width: 100%;
    padding-bottom: 17.38px;
    position: relative;
  }

  .profile-page__input__label {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${color11};
  }

  .profile-page__input__text {
    margin-top: 8.62px;
    display: inline-block;
    width: 100%;
    background: ${color1};
    border: 1px solid ${color58};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px 15px;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${color62};
    ::placeholder {
      color: ${color62};
    }
    :disabled {
      opacity: 0.4;
    }
  }

  .profile-page__input__text_error {
    border-color: ${color18};
  }

  .profile-page__input__text-error {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    color: ${color18};
    position: absolute;
    top: -2px;
    right: 0;
  }

  .profile-page__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 96.5%;
    padding: 18px 0 20px 0;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: ${color48};
    color: ${color1};
    display: inline-block;
    width: 250px;
    border: 0;
    outline: 0;
    border-radius: 5px;
    cursor: pointer;
    :active,
    :focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 2px;
    min-height: calc(100vh - 20px);

    .profile-page__h1 {
      width: 100%;
      padding-top: 45px;
      margin-bottom: 45px;
      font-size: 26px;
      line-height: 27px;
    }

    .profile-page__h3_hidden_mobile {
      display: none;
    }

    .profile-page__sidebar-wrapper {
      width: 100%;
      padding: 0 16px;
      padding-bottom: 10px;
      min-height: 100%;
    }

    .profile-page__sidebar {
      width: 100%;
      border-radius: 5px 5px 5px 5px;
      max-width: 350px;
      background-color: ${color1};
    }

    .profile-page__sidebar__item {
      background-color: ${color61};
    }

    .profile-page__sidebar__item_active {
      background-color: ${color20};
    }

    .profile-page__h3,
    .profile-page__content-main__main-info__label,
    .profile-page__h4_payment,
    .profile-page__content-main__email-wrapper {
      padding-left: 7px;
    }

    .profile-page__h3 {
      padding-top: 26px;
    }

    /* main profile page */

    .profile-page__content-main__main-info__card-wrapper {
      padding-top: 44px;
      padding-bottom: 49px;
    }

    .profile-page__content-main__col {
      flex: auto;
      width: 100%;
    }

    .profile-page__content-main {
      width: 100%;
    }

    .profile-page__content-main__card-item {
      width: 100%;
      max-width: 350px;
    }

    .profile-page__content-main__main-info__gift-cards {
      margin-bottom: 40px;
      margin-top: 0;
    }

    /* end main profile page */
  }
`;
