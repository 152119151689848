import styled from 'styled-components';
import { color37, color45 } from '../../../styled';

export const StepsContainer = styled.div`
  transform: 0;
  transition: transform 400ms ease-in;
`;

const activeStyle = `
font-weight: 800;
font-size: 30px;
line-height: 37px;
color: ${color45};
`;

const normalStyle = `
font-weight: 500;
font-size: 25.2px;
line-height: 37px;
color: ${color37};
`;

export const Step = styled.div`
  position: relative;
  font-family: Montserrat;
  ${({ active }) => (active ? activeStyle : normalStyle)}
  text-transform: uppercase;
  letter-spacing: -0.01em;
  font-style: normal;
  padding: 0;
  padding-bottom: 40px;
  cursor: pointer;
  display: flex;
  justify-content: left;
  :last-child {
    padding-bottom: 0;
  }
  .step__number {
    display: inline-block;
    margin-right: 6px;
    min-width: 1em;
    text-align: left;
  }
  .step__text {
    display: inline-block;
    & > p {
      line-height: inherit !important;
      display: inline-block;
    }
  }

  /* :before {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 3px;
    height: 100%;
    background: #f86a71;
    border-radius: 5px;
    display: ${({ active }) => (active ? 'block' : 'none')};
  } */
`;
