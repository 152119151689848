import React from 'react';
import PropTypes from 'prop-types';
import Toast from '../toast';
import {
  EmailContainer,
  EmailButton,
  EmailInput,
  EmailWrapper,
} from './styled';
import { triggerAnalyticsEvent } from '../../hooks';

const propTypes = {
  handleSend: PropTypes.func,
  submitLabel: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.input = React.createRef();
    this.state = { validEmail: true, showToast: false, error: false };
  }

  checkValid = () => {
    const valid = !!(
      this.input.current &&
      this.input.current.value &&
      this.input.current.value.match(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      )
    );

    this.setState({ validEmail: valid });
  };

  handleSubmit = async event => {
    const { handleSend, name } = this.props;
    event.preventDefault();

    if (name !== 'unsubscribe' && name !== 'share-recipe-email') {
      triggerAnalyticsEvent('subscribe');
    }

    triggerAnalyticsEvent(event.target.name);

    if (this.input.current.value && this.state.validEmail && handleSend) {
      this.setState({ showToast: false });

      const res = await handleSend({
        to: this.input.current.value,
        storeName: this.props.storeName,
        totalSavings: this.props.totalSavings || 0,
        subscribe: this.props.subscribe,
        source: this.props.source,
      });

      setTimeout(() => {
        this.input.current.value = '';

        this.setState({ showToast: true, error: res !== 'Sent' });
      }, 300);
    }
  };

  render() {
    const { validEmail, showToast, error } = this.state;
    const {
      submitLabel,
      placeholder,
      name,
      successLabel,
      unsuccessLabel,
    } = this.props;

    const success = error
      ? unsuccessLabel || `Email sent unsuccessfully!`
      : successLabel || `Email sent successfully!`;

    return (
      <EmailWrapper>
        <Toast
          show={showToast}
          error={error}
          hideToast={() => this.setState({ showToast: false })}>
          {success}
        </Toast>
        <EmailContainer
          error={!validEmail}
          onSubmit={this.handleSubmit}
          name={name || 'email-form'}>
          <EmailInput
            placeholder={placeholder || 'E-mail Recipe'}
            type="email"
            name="email"
            required
            ref={this.input}
            error={!validEmail}
            onChange={() => {
              this.checkValid();
            }}
          />
          <EmailButton
            onClick={() => {
              this.checkValid();
            }}
            type="submit">
            {submitLabel || 'SEND'}
          </EmailButton>
        </EmailContainer>
        {!validEmail && <div className="error">Invalid Email</div>}
      </EmailWrapper>
    );
  }
}

EmailForm.propTypes = propTypes;

export default EmailForm;
