import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useProfile } from '../../hooks';

import {
  Header,
  HeaderContainer,
  LogoPanel,
  LogoImage,
  Menu,
  MenuItem,
  ButtonContainer,
  Button,
  MenuButton,
  MenuButtonWrapper,
  MenuButtonContainer,
} from '../styled';

import MobileMenu from '../../components/mobileMenu';
import ProfileLink from '../../components/profileLink';

const propTypes = {
  isSticky: PropTypes.bool,
  main: PropTypes.bool,
  preview: PropTypes.bool,
  getStarted: PropTypes.func,
  small: PropTypes.bool,
  hideMd: PropTypes.bool,
  noLogin: PropTypes.any,
  activeMenu: PropTypes.string,
  logoLink: PropTypes.string,
};

const HeaderComponent = ({
  isSticky,
  main,
  preview,
  getStarted,
  small,
  hideMd,
  noLogin,
  activeMenu,
  logoLink,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const isSmallDevice = useMediaQuery('small');
  const { profile, loading } = useProfile();

  const mainNavClick = id => {
    if (!main) {
      if (id === 'main') {
        window.location.href = '/';
      } else {
        window.location.href = `/#${id}`;
      }
    } else if (id === 'main') {
      window.scrollTo(0, 0);
    } else {
      const el = document.getElementById(id);

      window.scrollTo(0, el.offsetTop - 60);
    }
  };

  const menu = [
    {
      label: 'How it works',
      click: () => {
        mainNavClick('hiw');
      },
    },
    {
      label: 'About Us',
      click: () => {
        window.location.href = '/about';
      },
      page: 'about',
    },
    {
      label: 'FAQ',
      click: () => {
        window.location.href = '/faq';
      },
      page: 'faq',
    },
    {
      label: 'Blog',
      click: () => {
        window.open('https://community.shopgizmo.co', '_blank');
      },
      page: 'community',
    },
    {
      label: 'Live Deals',
      click: () => {
        window.location.href = '/deals';
      },
      page: 'deals',
    },
    /* {
      label: 'Forum',
      click: () => {
        window.open(
          'https://community.shopgizmo.co/forums/forum/all-things-saving-money/',
          '_blank',
        );
      },
    }, */
  ];

  const switchShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  return (
    <Header
      hideMd={!!hideMd}
      isSticky={!!isSticky}
      main={!!main}
      small={!!small}
      activeMobileMenu={!!showMenu}>
      <HeaderContainer>
        <LogoPanel>
          <a className="link" href={logoLink || '/'}>
            <LogoImage src="/img/ShopGizmo_Logo_new.svg" />
          </a>
        </LogoPanel>
        {isSmallDevice && (
          <MenuButtonContainer>
            <MenuButtonWrapper active={!!showMenu} onClick={switchShowMenu}>
              <MenuButton />
            </MenuButtonWrapper>
          </MenuButtonContainer>
        )}
        {isSmallDevice ? (
          showMenu && (
            <div className="mobile-menu__wrapper">
              <MobileMenu
                show={!!showMenu}
                closeMenu={switchShowMenu}
                profile={profile}
              />
            </div>
          )
        ) : (
          <>
            <div className="header__container__content">
              <Menu main={!!main}>
                {menu.map(({ label, click, page }, index) =>
                  main && page === 'main' ? null : (
                    <MenuItem
                      key={index}
                      active={page && activeMenu && page === activeMenu}
                      onClick={() => {
                        if (click && !preview) {
                          click();
                        }
                      }}>
                      {label}
                    </MenuItem>
                  ),
                )}
                {main && (
                  <ButtonContainer>
                    <Button
                      small
                      onClick={() => {
                        if (getStarted) {
                          getStarted();
                        }
                      }}>
                      Get started
                    </Button>
                  </ButtonContainer>
                )}
                {!noLogin && (
                  <ProfileLink
                    main={main}
                    profile={profile}
                    loading={loading}
                  />
                )}
              </Menu>
            </div>
          </>
        )}
      </HeaderContainer>
    </Header>
  );
};

HeaderComponent.propTypes = propTypes;

export default HeaderComponent;
