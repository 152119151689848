import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Video from './video';
import StepPreview from './stepPreview';
import { VideoPanel, VideoPanelLeft, VideoPanelRight } from './styled';
import { defaultSteps, cardgizmoSteps, extensionSteps } from '../../../content';

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

const VideoSection = ({ id, type }) => {
  const [step, setStep] = useState(false);

  const handleTimeStep = val => {
    setStep(val);
  };

  let steps = defaultSteps;

  let breakpoints = [
    { breakpoint: 0, start: 0, end: 0.7 },
    { breakpoint: 4.2, start: 2.6, end: 3.4 },
    { breakpoint: 8.6, start: 7.6, end: 7.9 },
  ];
  let sourceUrl = '/video/ShopGizmo_1260x900_full.mp4';

  if (type === 'extension') {
    sourceUrl = '/video/ShopGizmo_1200x900_Target.mp4';
    breakpoints = [
      { breakpoint: 1, start: 0, end: 0.7 },
      { breakpoint: 7.74, start: 7.18, end: 7.68 },
      { breakpoint: 11.02, start: 10.39, end: 10.85 },
    ];
    steps = extensionSteps;
  } else if (type === 'cardgizmo') {
    sourceUrl = '/video/CardGizmo_ext_2.mp4';
    breakpoints = [
      { breakpoint: 1, start: 0.01, end: 2.7 },
      { breakpoint: 2.82, start: 2.74, end: 7.9 },
      { breakpoint: 11.55, start: 11.45, end: 19.74 },
    ];

    steps = cardgizmoSteps;
  }

  return (
    <VideoPanel id="hiw">
      <VideoPanelLeft>
        <Video
          currentStep={step}
          handleChangeStep={handleTimeStep}
          breakpoints={breakpoints}
          sourceUrl={sourceUrl}
          type={type}
        />
      </VideoPanelLeft>
      <VideoPanelRight>
        <StepPreview current={step} handleChange={setStep} steps={steps} />
      </VideoPanelRight>
    </VideoPanel>
  );
};

VideoSection.propTypes = propTypes;

export default VideoSection;
