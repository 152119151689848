import React from 'react';
import {
  NavMenu,
  NavMenuItem,
  PageTitleContainer,
} from '../../shareComponent/styled';
import Header from '../../shareComponent/header';
import EmailForm from '../../shareComponent/recipe/emailForm';
import {
  Container,
  RecipePageContainer,
} from '../../shareComponent/recipePage/styled';
import Footer from '../../components/footer';
import { UnsubscribeWrapper, UnsubscribePageWrapper } from './styled';
import { useLink } from '../../hooks';
import { unsubscribe } from '../../api';

const UnsubscribePage = () => {
  const linkTo = useLink();

  const handleSend = async ({ to }) => {
    const res = await unsubscribe(to);

    return res;
  };

  return (
    <UnsubscribePageWrapper>
      <RecipePageContainer>
        <Header activeMenu="terms" />
        <PageTitleContainer>Unsubscribe</PageTitleContainer>
        <Container>
          <NavMenu>
            <NavMenuItem
              onClick={e => {
                e.preventDefault();
                linkTo('/');
              }}
              href="/">
              Home
            </NavMenuItem>{' '}
            / <NavMenuItem active>Unsubscribe</NavMenuItem>
          </NavMenu>
          <UnsubscribeWrapper>
            <div className="unsubscribe__text">
              You need to enter your email for the unsubscribing!
            </div>
            <div className="unsubscribe__form">
              <EmailForm
                placeholder="Enter your email address"
                handleSend={handleSend}
                submitLabel="UNSUBSCRIBE"
                successLabel="You have been unsubscribed successfully"
                unsuccessLabel="You haven't been unsubscribed"
                name="unsubscribe"
              />
            </div>
          </UnsubscribeWrapper>
        </Container>
      </RecipePageContainer>
      <Footer />
    </UnsubscribePageWrapper>
  );
};

export default UnsubscribePage;
