import styled from 'styled-components';
import { color11, color58 } from '../../../shareComponent/styled';

export const LoginPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 90px);
  * {
    box-sizing: border-box;
  }
  .login-page__header-wrapper {
    width: 100%;
    position: relative;
  }
`;

export const LoginPageContentWrapper = styled.div`
  padding-top: 86px;
  padding-left: 130px;
  display: flex;
  flex-flow: row wrap;

  .login-page__h1 {
    width: 100%;
    line-height: 27px;
    font-size: 26px;
    font-weight: 700;
    color: ${color11};
    padding-bottom: 31px;
  }

  .login-page__content {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-flow: row wrap;
  }

  .login-page__facebook {
    width: 320px;
    height: 149px;
    border-radius: 5px;
    border: 1px solid ${color58};
    padding: 22px 15px;
    justify-content: center;
  }

  .login-page__col {
    display: flex;
    flex-flow: row wrap;
  }

  .login-page__col_or {
    min-width: 128px;
    justify-content: center;
    align-content: center;
    max-height: 180px;
    font-size: 25.2px;
    line-height: 100%;
  }

  .login-page__col_facebook {
    padding-top: 25px;
  }

  .login-page__form-wrapper {
    width: 472px;
  }

  .login-page__h3 {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    margin-bottom: 22px;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;

    .login-page__h1 {
      max-width: 475px;
    }

    .login-page__content {
      max-width: 475px;
    }

    .login-page__col {
      width: 100%;
      justify-content: center;
      min-height: 60px;
    }

    .login-page__col_or {
      display: none;
    }

    .login-page__facebook {
      margin-bottom: 90px;
      margin-top: 85px;
    }

    .login-page__col_facebook {
      padding-top: 0px;
    }

    .login-page__form-wrapper {
      max-width: 472px;
      width: 100%;
    }
  }
`;
