import React from 'react';

const TwitterIcon = ({ color }) => {
  const currentColor = color || '#ffffff';

  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.5 22.5C43.5 34.098 34.098 43.5 22.5 43.5C10.902 43.5 1.5 34.098 1.5 22.5C1.5 10.902 10.902 1.5 22.5 1.5C34.098 1.5 43.5 10.902 43.5 22.5Z"
        stroke={currentColor}
        strokeWidth="3"
      />
      <path
        d="M36.2824 16.1705C35.3182 16.598 34.281 16.8872 33.1933 17.0163C34.3039 16.3509 35.1562 15.2981 35.5584 14.0415C34.5192 14.658 33.3677 15.1052 32.1432 15.3462C31.1622 14.3014 29.7644 13.6484 28.2166 13.6484C25.247 13.6484 22.8385 16.0569 22.8385 19.0266C22.8385 19.4481 22.8862 19.8584 22.9784 20.2523C18.5088 20.0281 14.5457 17.8871 11.8929 14.6323C11.43 15.4264 11.1645 16.3509 11.1645 17.3364C11.1645 19.2018 12.1147 20.8487 13.557 21.8129C12.6758 21.7852 11.846 21.5434 11.1212 21.1395C11.1208 21.1623 11.1208 21.1852 11.1208 21.2077C11.1208 23.8135 12.9754 25.987 15.4353 26.4807C14.9844 26.6042 14.5084 26.6696 14.0186 26.6696C13.6713 26.6696 13.3348 26.6363 13.0067 26.5738C13.6909 28.7099 15.6767 30.2649 18.0307 30.3087C16.1897 31.7514 13.871 32.6109 11.3506 32.6109C10.9175 32.6109 10.4883 32.5856 10.0684 32.5355C12.4472 34.062 15.2748 34.952 18.3118 34.952C28.2042 34.952 33.614 26.7571 33.614 19.6495C33.614 19.4164 33.6088 19.1842 33.5983 18.954C34.65 18.1959 35.5617 17.249 36.2824 16.1705Z"
        fill={currentColor}
      />
    </svg>
  );
};

export default TwitterIcon;
