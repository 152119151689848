import styled from 'styled-components';
import { color1, color34, color43, color46, color47 } from '../../../styled';

const inputButton = `
  display: flex;
  border: none;
  outline: none;
  padding: 0 18px;
  background: none;
  align-items: center;
  justify-content: flex-start;
`;

export const EmailInput = styled.input.attrs({ type: 'text' })`
  ${inputButton}
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ error }) => (error ? '#9f3a38' : color43)};
  /* opacity: 0.5; */
  height: 100%;
  flex: 1;
  border-right: 1px solid #e7e7e7;
  border-radius: 8px 0 0 8px;
  text-align: left;
  margin: 0px;
  background-color: ${({ error }) =>
    error ? '#fff6f6' : '#ffffff'} !important;
  ::-webkit-input-placeholder {
    color: ${({ error }) => (error ? '#9f3a38' : 'initial')};
    opacity: 0.5;
  }

  :-ms-input-placeholder {
    color: ${({ error }) => (error ? '#9f3a38' : 'initial')};
    opacity: 0.5;
  }

  ::placeholder {
    color: ${({ error }) => (error ? '#9f3a38' : 'initial')};
    opacity: 0.5;
  }
`;

export const EmailButton = styled.button.attrs({ type: 'submit' })`
  ${inputButton}
  font-family: Montserrat;
  flex: 0 0 175px;
  text-align: center;
  padding-left: 18px;
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  background-color: ${color34};
  color: ${color1};
  height: 100%;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  justify-content: center;
  z-index: 1;
  margin: 0px;
`;

export const EmailContainer = styled.form`
  width: 763px;
  height: 50px;
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;

  ${EmailInput} {
    border: 0.5px solid ${({ error }) => (error ? color47 : color46)};
  }

  @media screen and (max-width: 1024px) {
    margin: auto;
  }

  @media screen and (max-width: 460px) {
    width: 320px;
  }
`;

export const EmailWrapper = styled.div`
  position: relative;
  height: 40px;
  .error {
    display: block;
    position: absolute;
    top: -22px;
    left: 18px;
    color: #e0b4b4;
    font-size: 13px;
  }

  @media screen and (max-width: 1024px) {
    margin: auto;
  }
`;
