import React, { useState, useCallback } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { ChevronDown } from '../../../../../components/icons';

import {
  PaymentCardTypesSelect as StyledPaymentCardTypesSelect,
  PaymentCardTypesItem as StyledPaymentCardTypesItem,
} from '../styled';

const PaymentCardTypesSelect = ({ options, onChange }) => {
  const [state, setState] = useState({ options: [] });

  const setObjectState = useCallback(
    props => setState({ ...state, ...props }),
    [state],
  );

  return (
    <StyledPaymentCardTypesSelect>
      <StyledPaymentCardTypesItem
        href="/#"
        onClick={e => {
          e.preventDefault();

          setObjectState({ opened: !state.opened });
        }}
        active={!!state.opened}>
        <div className="payment-card-types__item__icon-wrapper">
          <img
            src="/img/ccard.svg"
            alt="card icon"
            className="payment-card-types__item__icon"
          />
        </div>
        <div className="payment-card-types__item__text">
          {!state.opened ? (
            'Add new Payment Card'
          ) : (
            <input
              type="test"
              className="payment-card-types__item__search"
              placeholder="Please Select Card Type"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              value={state.search}
              onChange={e => {
                setObjectState({
                  search: e.target.value && e.target.value.toLocaleLowerCase(),
                });
              }}
            />
          )}
        </div>
        {state.opened && (
          <span className="payment-card-types-select__status-icon">
            <ChevronDown />
          </span>
        )}
      </StyledPaymentCardTypesItem>
      {state.opened && (
        <div className="payment-card-types-select__items-wrapper">
          <PerfectScrollbar options={{ wheelPropagation: false }}>
            {options &&
              options.length > 0 &&
              options
                .filter(item =>
                  state.search
                    ? item.text.toLowerCase().indexOf(state.search) > -1
                    : true,
                )
                .map(item => (
                  <StyledPaymentCardTypesItem
                    key={item.value}
                    href="/#"
                    onClick={() => {
                      if (onChange) {
                        onChange(item.value);
                      }

                      setObjectState({ opened: false });
                    }}>
                    <div className="payment-card-types__item__icon-wrapper">
                      <img
                        src={item.image}
                        alt="card icon"
                        className="payment-card-types__item__icon"
                      />
                    </div>
                    <div className="payment-card-types__item__text">
                      {item.text}
                    </div>
                  </StyledPaymentCardTypesItem>
                ))}
          </PerfectScrollbar>
        </div>
      )}
    </StyledPaymentCardTypesSelect>
  );
};

export default PaymentCardTypesSelect;
