import React from 'react';
import SocialButton from '../socialButton';
import { Facebook } from '../../shareComponent/recipe/icons';
import { facebookAuth } from '../../api/auth';
import { LoginButtonWrapper } from './styled';

const handleSocialLoginFailure = err => {
  console.log(err);
};

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

const LoginFacebook = () => {
  return (
    <LoginButtonWrapper>
      <SocialButton
        provider="facebook"
        appId={appId || '1598270273678225'}
        onLoginSuccess={facebookAuth}
        onLoginFailure={handleSocialLoginFailure}
        className="login__button">
        <span className="login__button-icon">
          <Facebook />
        </span>
        login with facebook
      </SocialButton>
    </LoginButtonWrapper>
  );
};

export default LoginFacebook;
