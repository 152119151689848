import React from 'react';
import PropTypes from 'prop-types';

import { defaultSteps, cardgizmoSteps } from '../../../content';
import { HiwMobileContainer, StepsMobile, StepMobile } from './styled';
import { H1 } from '../styled';

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

const HiwMobile = ({ id, type }) => (
  <HiwMobileContainer id={id}>
    <H1>How it works</H1>
    <StepsMobile>
      {(type === 'cardgizmo' ? cardgizmoSteps : defaultSteps).map(
        ({ label, content }, index) => (
          <StepMobile key={`${label}`} step={index + 1} type={type}>
            <div className="step__title">{label}</div>
            <div className="step__text">
              <div className="step__text__text">{content}</div>
            </div>
          </StepMobile>
        ),
      )}
    </StepsMobile>
  </HiwMobileContainer>
);

HiwMobile.propTypes = propTypes;

export default HiwMobile;
