import styled from 'styled-components';

export const PrivacyPageWrapper = styled.div`
  width: 100%;
  * {
    box-sizing: border-box;
  }
`;

export const PrivacyPageContainer = styled.div`
  width: 1024px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const PrivacyPageContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;

  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }
`;

export const PrivacyLinksSide = styled.div`
  max-width: 225px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const PrivacyMainSide = styled.div`
  max-width: 550px;
  margin-left: 100px;
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat;
  .privacy-main-side__section {
    margin-top: 30;
    padding: 0;
  }
  .privacy-main-side__label {
    font-weight: 700;
    font-style: italic;
  }
  .privacy-main-side__h1 {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 44px;
    padding: 0;
    margin: 0;
  }
  .privacy-main-side__h3 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    margin-top: 30px;
  }
  .privacy-main-side__learn-more {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    height: 21px;
    max-width: 170px;
    outline: none;
    :focus {
      outline: none;
    }
  }

  .privacy-main-side__learn-more__icon-wrapper {
    margin-left: 0.3em;
    display: flex;
    height: 100%;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-left: auto;
    padding: 0 15px;
    padding-top: 0px;
    .privacy-main-side__h1 {
      font-size: 28px;
      letter-spacing: -0.8px;
      line-height: 37px;
    }
  }
`;

export const Link = styled.div`
  outline: none;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #292929;
  cursor: pointer;
  padding: 15px 25px 15px 0px;
  transition: 0.2s;
  border-bottom: 1px solid #eeeeee;
`;
