import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { DealsView /* BrowseDealsButtonWrapper */ } from './styled';
import Item from '../../shareComponent/item';
/* import { BrowseDealsButton } from '../../shareComponent/recipePage/styled'; */
import { useLink } from '../../hooks';

const propTypes = {
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      h1: PropTypes.string,
      h2: PropTypes.string,
      h3: PropTypes.string,
      logo_url: PropTypes.string,
    }),
  ),
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addLimit: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSmall: PropTypes.any,
};

const Deals = ({ deals, addLimit, perPage, count, isSmall }) => {
  const dealsRef = useRef(null);
  const linkTo = useLink();

  const handleObserver = useCallback(
    entities => {
      const target = entities[0];

      if (target.isIntersecting && deals.length < count) {
        addLimit();
      }
    },
    [addLimit, deals, count],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: isSmall ? '120px' : '120px',
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);

    if (dealsRef.current) {
      const el = dealsRef.current.querySelector('.js-last-child');

      if (el) {
        observer.observe(el);

        return () => {
          observer.unobserve(el);
        };
      }
    }
  }, [handleObserver, isSmall]);

  useEffect(() => {
    if (dealsRef && deals && deals.length > perPage) {
      const el = dealsRef.current.querySelector('.js-last-per-page:last-child');

      if (el) {
        el.scrollIntoView(false);
      }
    }
  }, [dealsRef, deals, perPage]);

  return (
    <>
      <DealsView ref={dealsRef}>
        {deals &&
          deals.length > 0 &&
          deals.map((item, index) => (
            <Item
              key={item.id}
              item={item}
              className={`${
                index === deals.length - 1 ? 'js-last-child' : ''
              } ${index === perPage - 1 ? 'js-last-per-page' : ''}`}
              isSmall={isSmall}
              handleClick={linkTo}
            />
          ))}
      </DealsView>
      {/* {deals && Number(count) > deals.length && (
        <BrowseDealsButtonWrapper>
          <BrowseDealsButton
            onClick={() => {
              addLimit();
            }}>
            LOAD MORE
          </BrowseDealsButton>
        </BrowseDealsButtonWrapper>
      )} */}
    </>
  );
};

Deals.propTypes = propTypes;

export default Deals;
