import React from 'react';

import { useExpand } from '../hooks';
import LearnMore from '../learnMore';

const Policy = ({ handleScrollTo }) => {
  const [expand, setExpand] = useExpand({
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
  });

  const { section1, section2, section3, section4, section5 } = expand;

  return (
    <div id="policy" className="privacy-main-side__section">
      <h1 className="privacy-main-side__h1">{`Our Policy`}</h1>
      <p className="privacy-main-side__section-content">
        {`ShopGizmo Inc. (“Company,” “we,” “us,” or “our”) respects the privacy of our users.  
                This Website Privacy Policy (“Policy”) describes our privacy practices concerning information collected
                in connection with the company website located at`}{' '}
        <a href="https://ShopGizmo.co/">https://ShopGizmo.co/</a>{' '}
        {`(the “Site”).`}
      </p>
      <p className="privacy-main-side__section-content">
        {`This Policy is intended to explain the conditions under which ShopGizmo collects, uses, and discloses personal
                 and other information in connection with the Site, and your rights in relation to that information.  
                 By visiting our website and using the features made available to you on the Site, you are agreeing to the terms of this Policy.`}
      </p>
      <h3 className="privacy-main-side__h3">
        {`What personal and other information we collect about you`}
      </h3>
      <p className="privacy-main-side__section-content">
        {`We collect both “personal information” and “other information” about users.  
                For purposes of this Policy, “other information” is information that we cannot directly associate with a specific person without 
                the aid of additional information.  By contrast, “personal information,” is information such as a name or email address that we 
                can directly associate with a specific person or entity without additional information.  
                When we combine other information with personal information, we treat all of the combined information as personal information.`}
      </p>
      {section1 && (
        <>
          <p className="privacy-main-side__section-content">
            <span className="privacy-main-side__label"></span>
            {`You can visit the Site without submitting any personal information.  However, if you choose to use certain features on the Site, you will be required to provide personal information.  Such information could include, for example, your name, email address, user name and password, payment card information, billing address, shipping address, and telephone number.  Our website also contains a “Contact us” feature through which you may submit additional personal information.`}
          </p>
          <p className="privacy-main-side__section-content">
            {`We may combine personal information collected through the Site with information that we collect about you in other contexts—such as our communications with you via email or telephone, or information we obtain from third parties.  We will treat such combined information as personal information and protect it in accordance with this Policy.`}
          </p>
          <p className="privacy-main-side__section-content">
            <span className="privacy-main-side__label">{`Other information.`}</span>
            {` We also passively collect other information through the Site using variously technologies.  We may use such information to track, for example, your usage of the Site.  Other information may be collected in the following ways:`}
            <ul>
              <li>
                <span className="privacy-main-side__label">Log data.</span> When
                you use the Site, we automatically receive and record certain
                information from your computer (or other device) and your
                browser.  This may include such data as your IP address and
                domain name, the pages you visit on the Site, the date and time
                of your visit, the files that you download, the URLs from the
                websites you visit before and after navigating to the Site, your
                software and hardware attributes (including device IDs), your
                general geographic location (g., your city, state, or
                metropolitan region), and certain cookie information (see
                below).  To obtain such information, we may use web logs or
                applications that recognize your computer and gather information
                about its online activity.
              </li>
              <li>
                <span className="privacy-main-side__label">
                  We use cookies on the Site.
                </span>
                  Cookies are small files that are stored on your computer by
                your web browser.  A cookie allows the Site to recognize whether
                you have visited before and may store user preferences and other
                information, including for example, the contents of your
                shopping cart on our Site.  Cookies can also be used to collect
                information about your use of the Site during your current
                session and over time (including the pages you view and the
                files you download), your computer’s operating system and
                browser type, your Internet service provider, your domain name
                and IP address, your general geographic location, the website
                that you visited before the Site, and the link you used to leave
                the Site.  If you are concerned about having cookies on your
                computer, you can set your browser to refuse all cookies or to
                indicate when a cookie is being set, allowing you to decide
                whether to accept it.  You can also delete cookies from your
                computer.  However, if you choose to block or delete cookies,
                certain features of the Site may not operate correctly.
              </li>
              <li>
                <span className="privacy-main-side__label">Web beacons.</span>
                 The Site or the emails that you receive from us may use an
                application known as a “web beacon” (also known as a “pixel
                tag,” “clear gif,” or “web bug”).  A web beacon is an electronic
                file that usually consists of a single-pixel image.  It can be
                embedded in a web page or in an email to transmit information,
                which could include personal information.  For example, it
                allows an email sender to determine whether a user has opened a
                particular email.
              </li>
              <li>
                <span className="privacy-main-side__label">
                  Collaboration with Third-Party Data Collectors.
                </span>
                 We may partner with certain third parties to collect, analyze,
                and use some of the information described in this section.  For
                example, we may allow third parties to set cookies or use web
                beacons or other tracking mechanisms (such as tags or scripts)
                on the website or in email communications from us.  This
                information collected by third parties using these technologies
                may be used for a variety of purposes, including analytics and
                targeted interest-based advertising, as discussed below (see the
                section entitled “Third-party analytics and interest-based
                advertising”).
              </li>
            </ul>
          </p>
        </>
      )}
      <LearnMore
        active={section1}
        handleChange={() => {
          setExpand('section1');

          if (section1) {
            handleScrollTo('policy');
          }
        }}
      />
      <h3 className="privacy-main-side__h3">
        How we use the information that we collect
      </h3>
      <p className="privacy-main-side__section-content">
        ShopGizmo uses the information that we collect for a variety of
        purposes.  If we have personal information about you, we may use it, for
        example: to respond to your questions or requests concerning the Site or
        other products or services offered by us or our partners; to fulfill the
        terms of any agreement you have with us; to fulfill your requests for
        our products or services or otherwise complete a transaction that you
        initiate; to send you information about our products or services and
        other topics that are likely to be of interest to you, including
        newsletters, updates, or other communications; to deliver confirmations,
        account information, notifications, and similar operational
        communications; to improve your user experience and the quality of our
        services; to comply with legal and/or regulatory requirements; and to
        manage our business.{' '}
      </p>
      <p className="privacy-main-side__section-content">
        We use the other information that we collect for such purposes as:
        counting and recognizing users of the website; analyzing how the Site
        and various features of the Site are used; improving the Site and
        enhancing users’ experiences with the Site; creating new products and
        services or improving our existing products and services; enabling
        additional website analytics and research concerning the Site; and
        managing our business.  ShopGizmo may link information gathered using
        cookies and web beacons with personal information. But in that event, we
        will treat the combined information as personal information.
      </p>
      {section2 && (
        <>
          <p className="privacy-main-side__section-content">
            We also may use the personal and other information that we collect
            to send you marketing emails and promotional communications.  To
            opt-out of these communications, see “Choices and Access to
            Information” below.
          </p>
        </>
      )}
      <LearnMore
        active={section2}
        handleChange={() => {
          setExpand('section2');

          if (section2) {
            handleScrollTo('policy');
          }
        }}
      />
      <h3 className="privacy-main-side__h3">
        How we share information with third parties 
      </h3>
      <p className="privacy-main-side__section-content">
        We share your personal information and other information with other
        parties for a variety of purposes, as described below.
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">Affiliates.</span>
          We may share personal and other information with our corporate
        affiliates for the purposes described in this Policy.
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">
          Third-party service providers and business partners.
        </span>
          ShopGizmo uses third-party service providers to help us manage and
        improve the website and other services.  These service providers may
        collect and/or use your personal or other information to assist us in
        achieving the purposes discussed above in the section entitled “How we
        use the information that we collect.”  For example, we use third parties
        to help us target and implement our email communications, host our
        website, process payments for our products, and manage our information
        systems.  We may also use third-party platforms to help us manage our
        relationships with the people and companies that use our services.
      </p>
      {section3 && (
        <>
          <p className="privacy-main-side__section-content">
            We may share your personal or other information with other third
            parties when necessary to fulfill your requests for services; to
            complete a transaction that you initiate; to meet the terms of any
            agreement that you have with us or our partners; or to manage our
            business.
          </p>
        </>
      )}
      <LearnMore
        active={section3}
        handleChange={() => {
          setExpand('section3');
        }}
      />
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">
          Your direct sharing of personal information through third-party
          websites and services.
        </span>
          The Site may enable you to directly share personal information with
        websites or online services operated by third parties.  For example, the
        website may contain links to third-party websites that incorporate
        comment and social media features.  If you choose to use these features,
        you may disclose your personal information not just to those third-party
        websites and services, but also to their users and the public more
        generally.  Because these third-party websites and services are not
        operated by us, we are not responsible for the content or practices of
        those websites or services.  The collection, use, and disclosure of your
        personal and other information will be subject to the privacy policies
        of the third-party websites or services, and not this Policy.
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">Surveys or Contests.</span> 
        We may ask you to participate in surveys (processed by us or third
        parties) that help us understand your use of the Site and our products. 
        You may also have the opportunity to participate in contests on the
        Site.  Participation in surveys or contests is completely voluntary. Any
        personal or other information provided to ShopGizmo (or supplied by you
        or ShopGizmo to such third-party survey or contest providers) in
        connection with these surveys will only be used in relation to that
        survey and as stated in this Policy.
      </p>
      {section4 && (
        <p className="privacy-main-side__section-content">
          In particular, we use the information to notify contest winners and
          award prizes, monitor site traffic, personalize the Site, and send
          participants an email newsletter. We may also use this information for
          publicity or marketing purposes and may publish this information in
          our sole discretion. The rules applicable to any individual survey or
          contest will be published prior to the collection of any personally
          identifiable information and you agree to such use by submitting any
          information.
        </p>
      )}
      <LearnMore
        active={section4}
        handleChange={() => {
          setExpand('section4');
        }}
      />
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">Third-party plugins.</span>
          The Site may integrate certain third-party plug-ins (such as a
        Facebook “like” button).  Even if you do not click on these plug-ins,
        they may collect information about you, such as your IP address and the
        pages that you view.  They also may set and/or access a cookie.  These
        plugins are governed by the privacy policy of the company providing
        them.
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">
          Facebook Custom Audiences and Similar Programs.
        </span>
          We may partner with Facebook and other social media partners to
        deliver advertisements to our users via the Facebook Custom Audiences
        program or similar third-party programs.  In order to identify and reach
        our users on Facebook or another third-party service, we may share
        information such as an email address or phone number with Facebook or
        another third-party service.  For more information about custom audience
        targeting and how to adjust your social media advertising preferences,
        please review the privacy policy of your social media provider.
      </p>
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">Legal purposes.</span>  We
        also may use or share your personal or other information with third
        parties when we believe, in our sole discretion, that doing so is
        necessary:
      </p>
      {section5 && (
        <p className="privacy-main-side__section-content">
          <ul>
            <li>
              to comply with applicable law or a court order, subpoena, or other
              legal process;
            </li>
            <li>
              to investigate, prevent, or take action regarding illegal
              activities, suspected fraud, violations of our terms and
              conditions, or situations involving threats to our property or the
              property or physical safety of any person or third party;
            </li>
            <li>
              to establish, protect, or exercise our legal rights or defend
              against legal claims;
            </li>
            <li>
              to facilitate the financing, securitization, insuring, sale,
              assignment, bankruptcy, or other disposal of all or part of our
              business or assets.
            </li>
          </ul>
        </p>
      )}
      <LearnMore
        active={section5}
        handleChange={() => {
          setExpand('section5');
        }}
      />
      <p className="privacy-main-side__section-content">
        <span className="privacy-main-side__label">
          Aggregated information.
        </span>
          From time to time, we may also share anonymized and aggregated
        information about users, such as by publishing a report on trends in the
        usage of the Site or our products.
      </p>
    </div>
  );
};

export default Policy;
