import React from 'react';

const aboutHiImage = () => (
  <svg
    width="406"
    height="340"
    viewBox="0 0 406 340"
    fill="none"
    className="aboutHiImage"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M118.344 340C174.72 340 220.421 333.3 220.421 325.036C220.421 316.771 174.72 310.071 118.344 310.071C61.9682 310.071 16.2663 316.771 16.2663 325.036C16.2663 333.3 61.9682 340 118.344 340Z"
      fill="#A1988E"
      fillOpacity="0.2"
    />
    <path
      d="M89.5171 296.837C88.4126 298.033 87.7503 320.673 88.1922 321.74C88.1922 324.741 79.7791 326.158 77.8461 325.992C75.9132 325.827 73.8695 325.587 72.9122 323.655C71.955 321.722 71.1087 314.396 71.5873 308.138C72.1764 298.825 71.3295 296.138 77.9567 293.1C81.123 292.051 87.4921 296.027 89.5171 296.837Z"
      fill="#AA7845"
    />
    <path
      d="M197.118 276.168C196.713 279.646 198.812 318.668 195.424 325.809C190.877 332.435 181.562 327.65 177.255 322.496C173.702 318.981 173.351 306.335 173.867 303.059C175.781 299.083 180.734 297.169 185.391 292.715C189.294 289.567 188.907 289.899 190.858 287.267C194.374 282.96 195.645 280.364 197.118 276.168Z"
      fill="#AA7845"
    />
    <path
      d="M106.178 69.5572C129.134 49.8258 188.761 49.2551 215.03 64.6795C227.272 55.9918 230.788 42.8315 254.167 27.315C274.712 14.3202 306.062 0 310.885 0C312.192 0.920312 302.454 95.1235 276.332 103.093C263.74 110.99 241.226 104.087 240.434 109.738C244.521 139.851 235.206 202.229 203.653 217.985C202.899 237.477 208.145 275.026 187.398 291.684C169.008 303.795 172.174 314.342 163.467 329.49C160.761 334.073 154.299 334.662 149.734 331.846C141.597 328.404 140.935 310.348 141.469 303.629C140.953 305.01 88.9478 298.899 86.3705 296.101C66.9124 284.726 75.0671 318.189 73.0789 325.643C72.048 328.588 70.7228 330.963 67.3172 331.11C51.5776 333.227 46.0182 315.483 43.533 298.181C35.3227 246.846 59.1072 242.778 34.0895 252.405C34.2 252.313 36.7405 249.313 36.0041 248.705C1.28496 251.393 -11.0858 224.17 11.189 198.64C11.0417 199.376 11.3364 201.585 11.3364 201.585C37.6059 185.277 25.29 191.333 23.8909 209.408C17.6871 230.63 42.5388 238.398 56.235 238.803C60.7268 229.029 111.609 230.778 117.095 229.802C113.873 226.949 116.469 221.574 118.568 219.31L117.537 217.838C108.59 217.047 80.0934 175.798 78.8416 164.957C75.1414 158.533 73.0794 124.666 73.061 122.53C71.2937 124.776 72.3613 111.174 71.9931 112.554C47.5645 109.812 29.8733 114.229 21.5341 80.4907C15.9194 69.4653 6.6605 8.79825 16.362 10.1971C25.7689 12.2034 29.1743 19.5843 46.0553 25.4007C70.2261 35.0272 106.381 40.1441 106.178 69.5572Z"
      fill="#C68E54"
    />
    <path
      d="M150.268 113.494C149.458 111.543 148.758 89.1975 154.741 83.2154C161.092 76.7733 173.886 79.1843 176.611 88.3691C179.501 100.867 178.065 113.254 176.298 126.212C172.524 169.449 212.71 161.129 212.71 187.082C209.765 236.282 135.32 235.656 112.75 203.022C102.81 185.039 113.782 170.774 131.344 167.424C143.512 161.442 152.9 143.238 152.881 129.084C152.753 127.832 150.268 113.494 150.268 113.494Z"
      fill="white"
    />
    <path
      d="M249.697 111.855C241.284 110.824 227.072 108.984 211.241 108.984C181.418 109.978 180.332 118.334 155.296 114.505C134.807 107.143 91.3067 107.842 71.9958 112.554C71.9958 112.554 71.9958 123.064 71.9958 123.561C72.2167 125.567 76.1559 126.009 77.647 128.089C79.6352 130.151 79.1203 143.808 83.2991 155.11C90.1472 170.736 107.212 173.332 124.259 170.939C144.509 169.467 152.958 148.355 158.315 133.114C163.175 124.942 172.14 126.801 174.018 136.759C177.571 147.6 184.787 164.497 198.023 168.914C211.811 174.05 235.227 174.694 244.008 161.497C248.592 157.466 252.513 131.366 254.317 127.316C254.778 125.752 258.846 125.936 258.846 125.936V113.254C258.864 113.254 258.11 112.867 249.697 111.855ZM151.835 136.133C146.902 163.521 123.762 175.099 97.1058 165.031C82.3235 158.736 80.059 122.402 91.8959 115.904C107.636 108.468 158.113 107.677 151.835 136.133ZM246.125 138.25C245.481 153.619 241.597 165.675 225.692 168.123C211.885 170.276 196.164 167.497 187.622 155.699C178.528 144.747 172.711 118.776 191.672 115.039C194.931 112.352 220.924 111.561 225.692 112.72C242.628 114.266 247.727 122.107 246.125 138.25Z"
      fill="#1D1D1B"
    />
    <path
      d="M124.701 151.484C129.499 151.484 133.39 146.531 133.39 140.422C133.39 134.313 129.499 129.36 124.701 129.36C119.902 129.36 116.012 134.313 116.012 140.422C116.012 146.531 119.902 151.484 124.701 151.484Z"
      fill="#4E331E"
    />
    <path
      d="M206.176 151.484C210.974 151.484 214.864 146.531 214.864 140.422C214.864 134.313 210.974 129.36 206.176 129.36C201.377 129.36 197.486 134.313 197.486 140.422C197.486 146.531 201.377 151.484 206.176 151.484Z"
      fill="#4E331E"
    />
    <path
      d="M35.6009 43.0156C47.1064 54.3723 69.7498 64.017 81.642 73.6987C88.6741 77.0302 87.6241 94.8106 84.0896 99.633C80.4814 114.137 48.1927 96.9826 42.2283 88.6998C35.1593 79.7728 29.4154 59.6364 28.6239 48.0037C28.6607 40.4387 27.0037 37.9723 30.2436 39.7577"
      fill="#AA7845"
    />
    <path
      d="M302.62 20.743C303.909 27.4981 296.821 63.2982 292.329 71.5995C288.776 79.054 284.635 86.2694 280.382 90.1715C273 98.2519 255.18 101.804 247.375 101.528C246.197 101.289 245.129 97.3868 245.369 93.6135C245.608 89.8218 245.258 87.5762 250.449 79.7719C260.942 63.6113 274.676 41.4686 289.126 28.9155C297.466 22.1972 297.263 21.7369 302.62 20.743Z"
      fill="#AA7845"
    />
    <path
      d="M118.735 220.066C118.735 220.066 125.472 224.042 134.198 225.717C142.924 227.392 148.594 229.38 161.167 229.545C173.74 229.711 181.085 229.692 188.596 227.557C196.107 225.422 197.506 224.796 199.66 223.95C201.814 223.103 203.6 221.851 203.6 221.851L203.637 220.084H205.478V231.607C205.478 231.607 202.826 235.491 195.444 237.202C188.044 238.896 187.529 239.264 172.894 239.687C158.258 240.11 147.545 239.889 139.666 237.938C131.787 235.969 129.743 235.067 124.312 232.95C118.9 230.852 117.557 230.429 116.857 229.582C116.158 228.735 115.421 228.257 115.642 226.711C115.844 225.165 116.213 224.244 116.857 223.048C117.667 221.557 118.735 220.066 118.735 220.066Z"
      fill="#B98151"
    />
    <path
      d="M118.735 218.869C118.735 218.869 125.472 222.845 134.198 224.52C142.924 226.195 148.594 228.182 161.167 228.348C173.722 228.495 181.085 228.495 188.596 226.36C196.107 224.225 197.506 223.599 199.66 222.753C201.814 221.906 203.6 220.654 203.6 220.654L203.637 218.887H205.478V230.41C205.478 230.41 202.826 234.293 195.444 236.005C188.044 237.699 187.529 238.067 172.894 238.49C158.258 238.913 147.545 238.692 139.666 236.741C131.787 234.772 129.743 233.87 124.312 231.753C118.9 229.655 117.557 229.232 116.857 228.385C116.158 227.538 115.421 227.06 115.642 225.513C115.844 223.967 116.213 223.047 116.857 221.851C117.667 220.341 118.735 218.869 118.735 218.869Z"
      fill="#E79292"
    />
    <path
      d="M126.152 191.334C126.152 191.334 129.301 195.089 132.964 196.837C141.469 201.052 157.411 199.046 161.793 190.726L162.05 180.161C157.65 179.369 147.397 172.891 148.151 167.166C151.465 160.319 166.947 160.025 173.206 162.804C181.526 165.399 178.765 172.78 173.04 176.774C170.095 179.093 166.873 179.517 164.848 180.437L164.756 190.745C168.18 199.635 185.65 199.23 192.498 194.15C195.462 192.493 198.297 187.579 200.267 188.481C191.173 200.132 180.827 210.421 162.381 210.126C146.329 210.182 142.058 206.077 131.399 199.745C127.993 196.947 126.852 195.475 126.171 194.592C124.993 193.248 125.159 193.064 125.214 191.72C125.361 190.837 125.784 191.223 126.152 191.334Z"
      fill="#553A21"
    />
    <path
      d="M165.143 200.444C173.593 199.671 181.362 199.892 176.723 212.537C173.593 220.249 173.63 223.894 165.143 224.372C156.583 224.704 153.545 222.716 150.011 215.206C147.691 209.592 145.704 204.659 149.367 201.217C152.902 198.751 162.4 201.935 165.143 200.444Z"
      fill="#F0B3AC"
    />
    <path
      d="M331.624 199H368.376C389.156 199 406 181.988 406 161C406 140.012 389.156 123 368.376 123H331.624C310.844 123 294 140.012 294 161C294 181.998 310.844 199 331.624 199Z"
      fill="#CCC5C0"
    />
    <path
      d="M297 175C299.623 181.21 297 197.973 297 197.973L331 199L297 175Z"
      fill="#CCC5C0"
    />
    <path
      d="M327.64 165.9C328.39 169.06 329.13 171.47 329.85 173.12C329.99 173.5 330.16 173.89 330.34 174.29C330.52 174.69 330.65 175.09 330.72 175.49C330.81 176.23 330.5 176.79 329.8 177.17C329.1 177.56 328.27 177.82 327.31 177.96C326.35 178.1 325.56 178.13 324.94 178.04C324.32 177.95 323.8 177.81 323.39 177.61C322.64 177.3 322.03 176.78 321.58 176.02C321.13 175.27 320.77 174.53 320.5 173.81C319.94 172.43 319.45 170.65 319.02 168.48C318.59 166.31 318.24 164.05 317.97 161.7C317.7 159.36 317.53 157.09 317.47 154.91C317.41 152.73 317.47 150.95 317.67 149.56C317.83 148.37 318.1 147.37 318.48 146.54C318.73 145.98 319.12 145.55 319.64 145.25C320.21 144.95 320.69 144.74 321.05 144.64C321.42 144.54 321.93 144.49 322.6 144.49C323.57 144.51 324.34 144.68 324.9 145.02C325.47 145.35 325.72 146.11 325.67 147.3C325.49 151.25 325.78 155.38 326.53 159.7C328.88 159.39 331.18 159.1 333.41 158.81C335.65 158.52 337.4 158.32 338.68 158.19C338.59 156.27 338.57 154.43 338.61 152.68C338.66 150.93 338.79 149.44 339 148.22C339.13 147.47 339.33 146.68 339.62 145.86C339.91 145.04 340.39 144.4 341.08 143.93C341.44 143.68 342.08 143.43 343.01 143.18C343.94 142.93 344.72 142.78 345.35 142.72C345.99 142.67 346.68 142.76 347.44 143.02C348.19 143.27 348.61 143.77 348.68 144.5C348.68 144.91 348.64 145.34 348.55 145.77C348.46 146.2 348.38 146.61 348.31 147.01C347.52 150.8 347.28 155.11 347.58 159.93C347.89 164.75 348.53 169.13 349.52 173.07C349.79 174.22 349.68 175.02 349.18 175.47C348.69 175.92 347.97 176.23 347.04 176.41C346.39 176.54 345.83 176.62 345.34 176.65C344.86 176.69 344.3 176.61 343.67 176.41C343.11 176.25 342.65 175.9 342.27 175.36C341.75 174.64 341.29 173.71 340.9 172.56C340.34 170.96 339.75 168.23 339.12 164.37C337.77 164.8 335.86 165.15 333.38 165.41C330.9 165.66 328.99 165.82 327.64 165.9Z"
      fill="white"
    />
    <path
      d="M350.78 171.7C350.57 169.03 350.47 167.7 350.47 167.7C350.47 167.7 350.61 166.32 350.89 163.55C351.17 160.78 351.61 158.88 352.21 157.85C352.81 156.82 353.41 156.33 354 156.38C354.59 156.42 355.16 156.48 355.7 156.57C356.18 156.68 356.57 156.78 356.86 156.87C356.93 156.89 357.02 156.93 357.13 157C357.18 157.04 357.26 157.11 357.35 157.21L357.59 157.45C357.63 157.49 357.69 157.58 357.78 157.72C357.83 157.79 357.88 157.9 357.91 158.04C357.95 158.22 357.98 158.4 358.02 158.58C358.02 158.62 358.05 158.79 358.1 159.09C358.12 159.31 358.01 161.01 357.76 164.2C357.51 167.39 357.67 171.09 358.2 175.32C358.18 175.77 358.13 176.21 358.04 176.64C357.95 177.07 357.86 177.37 357.77 177.53C357.61 177.78 357.28 177.97 356.77 178.1C356.54 178.15 356.28 178.18 356 178.19C355.72 178.2 355.44 178.19 355.15 178.18C354.61 178.16 354.04 178.1 353.43 177.99C352.98 177.92 352.59 177.82 352.24 177.69C352.15 177.64 352.08 177.59 352.02 177.56C351.97 177.54 351.89 177.47 351.78 177.34C351.73 177.3 351.65 177.22 351.56 177.1C351.56 177.12 351.5 177.03 351.37 176.83C351.32 176.79 351.27 176.69 351.24 176.51C351.2 176.38 351.16 176.2 351.11 175.97C351.09 175.79 350.98 174.37 350.78 171.7ZM357.22 148.27C357.89 148.92 358.23 149.71 358.23 150.64C358.23 151.56 357.89 152.34 357.22 153C356.55 153.66 355.72 153.98 354.75 153.98C353.82 153.98 353.01 153.65 352.34 153C351.67 152.34 351.33 151.56 351.33 150.64C351.33 149.71 351.67 148.92 352.34 148.27C353.01 147.62 353.82 147.3 354.75 147.3C355.72 147.3 356.54 147.63 357.22 148.27Z"
      fill="white"
    />
    <path
      d="M372.72 167.78C372.81 168.01 372.88 168.26 372.94 168.51C372.99 168.74 372.98 168.94 372.91 169.1C372.84 169.28 372.69 169.42 372.48 169.53C372.26 169.69 371.96 169.81 371.56 169.88C370.77 170.06 369.87 170.14 368.87 170.12C367.86 170.1 366.94 170.02 366.09 169.88C365.17 169.75 364.39 169.28 363.75 168.45C363.34 168 362.97 167.45 362.64 166.81C362.37 166.36 362.09 165.79 361.81 165.09C361.63 164.61 361.43 164 361.22 163.29C361.13 163 361.04 162.71 360.95 162.43C360.86 162.14 360.78 161.85 360.71 161.57C360.57 161.05 360.44 160.48 360.33 159.87C360.26 159.62 360.2 159.35 360.14 159.06C360.09 158.77 360.03 158.48 359.98 158.2C359.93 157.88 359.85 157.32 359.76 156.53C359.67 155.99 359.61 155.43 359.57 154.86C359.44 153.85 359.35 152.85 359.27 151.84C359.2 150.83 359.26 149.84 359.46 148.85C359.64 147.84 360.01 147.04 360.56 146.43C360.88 146.02 361.61 145.68 362.74 145.43C363.71 145.23 364.8 145.16 366 145.21C367.19 145.26 368.18 145.42 368.99 145.67C369.89 145.96 370.37 146.33 370.45 146.77C370.45 146.9 370.45 147.11 370.45 147.42C370.41 147.55 370.35 147.8 370.26 148.17C369.88 149.7 369.8 151.44 370.02 153.38C370.24 155.33 370.43 157.12 370.61 158.76C370.75 160.27 370.99 161.78 371.31 163.29C371.65 164.79 372.11 166.29 372.72 167.78ZM374.37 175.27C374.37 176.26 373.79 177.05 372.62 177.64C371.61 178.16 370.37 178.42 368.9 178.42C367.18 178.42 365.8 178.09 364.78 177.42C363.86 176.83 363.41 176.12 363.41 175.29C363.41 174.39 363.92 173.64 364.95 173.03C365.94 172.4 367.25 172.09 368.88 172.09C370.57 172.09 371.93 172.44 372.98 173.14C373.9 173.77 374.37 174.48 374.37 175.27Z"
      fill="white"
    />
  </svg>
);

export default aboutHiImage;
