import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { ShareButton } from './styled';
import { FacebookIcon, Instagram, TwitterIcon, CopyIcon } from '../icons';
import Toast from '../toast';

const propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
};

const formatDate = date => {
  if (date) {
    try {
      const dateObject = new Date(date);

      return `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject
        .getFullYear()
        .toString()
        .substr(2)}`;
    } catch (e) {
      console.log(e);
      return '';
    }
  }
};

const getUrls = (url, date, savings, store) => {
  let param = url || null;

  if (!param && typeof window === 'object') {
    param = window.location.href;
  }

  const text = encodeURIComponent(
    `Wow, you can save ${savings} at ${store} until ${formatDate(
      date,
    )} using this stack of discounts: `,
  );

  const urls = {
    facebook: `https://www.facebook.com/sharer.php?u=${param}&quote=${text}&dispaly=popup`,
    instagram: `http://www.instapaper.com/hello2?url=${param}`,
    twitter: `https://twitter.com/share?url=${param}&text=${text}`,
    copy: param,
  };

  return urls;
};

const fallbackCopyTextToClipboard = text => {
  if (typeof document === 'object') {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }
};

const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log('Async: Copying to clipboard was successful!');
    },
    err => {
      console.error('Async: Could not copy text: ', err);
    },
  );
};

const Share = ({ type, url, date, savings, store, color }) => {
  const urls = getUrls(url, date, savings, store);
  const [showToast, setShowToast] = useState(false);
  const ref = useRef(null);

  const renderIcon = () => {
    switch (type) {
      case 'facebook':
        return <FacebookIcon color={color} />;
      case 'twitter':
        return <TwitterIcon color={color} />;
      case 'instagram':
        return <Instagram color={color} />;
      case 'copy':
        return <CopyIcon color={color} />;
      default:
        return null;
    }
  };

  const handleShare = () => {
    if (typeof window === 'object') {
      if (type === 'copy') {
        copyTextToClipboard(urls[type]);

        setShowToast(true);
      } else {
        //window.location.href = urls[type];
        window.open(urls[type], 'blank');
      }
    }
  };

  return (
    <>
      {type === 'copy' && (
        <Toast
          show={showToast}
          hideToast={() => {
            setShowToast(false);
          }}
          containerRef={ref}
          fixed>
          The link copied successfully
        </Toast>
      )}
      <ShareButton ref={ref} onClick={handleShare}>
        {renderIcon()}
      </ShareButton>
    </>
  );
};

Share.propTypes = propTypes;

export default Share;
