import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';

import { useTimeoutEmailPopup, usePortal } from '../../hooks';
import { TimeoutEmailPopupWrapper } from './styled';
import EmailForm from '../../shareComponent/recipe/emailForm';
import { sendEmail } from '../../api';
import { CloseIcon } from '../icons';

const TimeoutEmailPopup = () => {
  const el = usePortal('sg-popup');
  const [cookies, setCookie] = useCookies(['visited']);
  const [showPopup, setShowPopup] = useTimeoutEmailPopup();

  useEffect(() => {
    const handleOnload = () => {
      if (cookies && cookies['visited']) {
        console.log('have already shown!');
      } else if (cookies) {
        setTimeout(() => {
          setShowPopup(true);
        }, 30000);
      }
    };

    handleOnload();
  }, [setShowPopup, cookies]);

  return (
    el &&
    showPopup &&
    ReactDOM.createPortal(
      <TimeoutEmailPopupWrapper>
        <div className="timeout-email-popup__container">
          <i
            onKeyUp={() => {}}
            tabIndex={-1}
            role="button"
            onClick={() => {
              setCookie('visited', true, { path: '/' });
              setShowPopup(false);
            }}
            className="timeout-email-popup__close-button">
            <CloseIcon />
          </i>
          <div className="timeout-email-popup__title">Don't miss a deal!</div>
          <div className="timeout-email-popup__title timeout-email-popup__title_small">
            Subscribe now
          </div>
          <div className="timeout-email-popup__email-form-wrapper">
            <EmailForm
              handleSend={sendEmail}
              subscribe
              source="popup"
              submitLabel="Send"
              placeholder="Enter your email address"
              name="subscribe-popup"
            />
          </div>
        </div>
      </TimeoutEmailPopupWrapper>,
      el,
    )
  );
};

export default TimeoutEmailPopup;
